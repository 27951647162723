import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { APIContext } from './services/api'
import { GlobalContext } from './services/globalState'
import { industryOptions } from './services/industryOptions'
import { themes } from './themes/themes'
import AnalysisList from './components/AnalysisList'
import AnalysisScore from './components/AnalysisScore'
import ReportSummary from './components/ReportSummary'
import ReportBarGraph from './components/ReportBarGraph'
import ReportVideo from './components/ReportVideo'

// styles
const containerStyle = {
	width: '100%', // for IE compatibility
	maxWidth: '1160px',
	margin: '0 auto',
	padding: '84px 40px 90px',
	textAlign: 'center',
	boxSizing: 'border-box'
}

const headerStyle = {
	...containerStyle,
	padding: '93px 40px 70px'
}

const loaderStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: 'calc(100vh - 199px)'
}

const summaryStyle = {
	...containerStyle,
	padding: '84px 0 90px'
}

const iconStyle = {
	margin: '0 16px -8px -26px'
}

// info divider
const divider = <span style={{padding: '0 16px', opacity:'.5', display:'inline-block'}}>|</span>

// component function
export default function Report() {

	const context = useContext(APIContext)
	const [video, setVideo] = useState()
	const [norms, setNorms] = useState()
	const [shareLink, setShareLink] = useState()
	const [currentFrame, setCurrentFrame] = useState()
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const history = useHistory()
	const { guid, code } = useParams()
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const showTeamAnalyses = globalState.userData && globalState.userData.settings && globalState.userData.settings.showTeamAnalyses ? globalState.userData.settings.showTeamAnalyses : false
	const userId = globalState.userData && globalState.userData.id
	const ownVideo = video && video.user.id === userId

	// analysis creation date
	const dateOptions = { year:'numeric', month:'short', day:'numeric', hour:'numeric', minute:'numeric' }
	const date = video && video.createdAt ? new Date(video.createdAt).toLocaleDateString("en-GB", dateOptions) : null

	// dynamic styles
	const wrapperStyle = {
		minHeight: 'calc(100vh - 199px)',
		backgroundColor: theme.backgroundColorReportWrapper,
		minWidth: '976px'
	}

	const backgroundStyle = {
		backgroundColor: theme.backgroundColor2,
		textAlign: 'center'
	}

	const subHeadingStyle = {
		margin: '30px 0 33px',
		color: theme.textColor
	}

	const shareLabelStyle = {
		margin: '0 auto',
		height: '30px',
		marginTop: '-30px',
		color: theme.textColor,
		opacity: '.5'
	}

	const shareLinkStyle = {
		display: 'block',
		margin: '0 auto',
		padding: '0 10px',
		width: '940px',
		height: '48px',
		borderRadius: '24px',
		backgroundColor: theme.backgroundColor1,
		color: theme.textColor,
		textAlign: 'center',
		fontSize: '18px',
		WebkitAppearance: 'none',
		outline: 'none',
		border: 'none'
	}

	const tagButtonStyle = {
		padding: !ownVideo || code ? '14px 40px' : '6px 20px 5px',
		marginTop: !ownVideo || code ? '0' : '30px',
		fontSize: !ownVideo || code ? '17px' : '14px',
		letterSpacing: !ownVideo || code ? '0' :  '.2px'
	}

	// get video data & norms
	useEffect(() => {
		// get video data (and norms if shared report)
		const path = code ? '/api/v1/video/shared/'+guid+'/'+code : '/api/v1/organization/video/'+guid
		context.io.socket.get(path, (videodata, res) => {
			if (res.statusCode === 200) {
				setVideo(videodata)
				code ? setNorms(videodata.norms) :
				context.io.socket.get('/api/v1/video/norms', (normdata, res) => { // get user’s own default norms (analysisType 1) separately if not shared report
					if (res.statusCode === 200) {
						const industryNormsFiltered = normdata.industry.filter(norm => norm.industryCode === videodata.industryCode) // only norms w the video industryCode
						const normsFiltered = { user:normdata.user, industry:industryNormsFiltered }
						setNorms(normsFiltered)
					} else {
						// TODO: error handling
					}
				})
			} else if (res.statusCode === 404) { // shared report not found
				history.push('/notfound')
			} else {
				// TODO: error handling
			}
			// update organization in globalState with company avatar if not logged in
			!globalState.loggedIn && videodata.organizationAvatar && setGlobalState({loggedIn:false, userData:{organization:{settings:{avatar:videodata.organizationAvatar}}}})
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.io.socket, guid, code, history])

	// share report
	function shareReport() {
		const host = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.REACT_APP_PUBLIC_URL
		context.io.socket.get('/api/v1/videoshare/'+guid, (data, res) => {
			if (res.statusCode === 200) {
				setShareLink(host+'/shared/'+data.guid+'/'+data.code)
			} else {
				// TODO: error handling
			}
		})
	}

	// edit cues
	function editCues() {
		history.push('/' + guid)
	}

	// share button or share link
	const shareButton = (shareLink ?
		<>
			<div style={shareLabelStyle}>Flowsam report public link:</div>
			<input style={shareLinkStyle} value={shareLink} readOnly />
		</>
		:
		<>
			<button className="fs-button" tabIndex="-1" onClick={shareReport}>SHARE REPORT</button><br/>
		</>
	)

	// video download button
	const videoDownloadButton = (video && video.bucketPathMp4Attention &&
		<a href={process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.bucketPathMp4Attention} download={'attention-'+video.filename}><button className="fs-button" style={tagButtonStyle} tabIndex="-1">DOWNLOAD ATTENTION VIDEO</button></a>
	)

	// bottom section - hide share and edit functions if not logged in
	const shareAndEdit = (globalState.loggedIn && !code && ownVideo ?
		<div style={backgroundStyle}>
			<div style={containerStyle}>
				{shareButton}
				<button className="fs-button" style={tagButtonStyle} tabIndex="-1" onClick={editCues}>EDIT BRAND CUES</button><br/>
				{videoDownloadButton}
			</div>
		</div>
		:
		<div style={backgroundStyle}>
			<div style={containerStyle}>
				{videoDownloadButton}
			</div>
		</div>
	)

	// find label for video industry code
	const industry = video && video.industryCode !== undefined && industryOptions.find(ind => ind.value === video.industryCode)
	const industryLabel = industry && industry.label

	const analysisTypeHeading = (video && video.analysisType === 2 ?
		<h3 style={subHeadingStyle}>Flowsam<sup>®</sup> report - Animatic</h3> :
		<h3 style={subHeadingStyle}>Flowsam<sup>®</sup> report</h3>
	)

	// report content
	const content = (
		// not logged in and not shared report
		!globalState.loggedIn && !code ?
			null
		:
		// no video data received yet
		!video ?
			<div style={loaderStyle}>
				<BeatLoader color={'#39C481'} />
			</div>
		:
		// no video in received data
		!video.guid ?
			<>
				<div style={backgroundStyle}>
					<div style={headerStyle}>
						<h2 style={{color:theme.textColor}}>Report not found!</h2>
						<h4 style={{color:theme.textColor, padding:'20px 0 20px'}}>Maybe you can find what you’re looking for in the list below</h4>
					</div>
				</div>
				<AnalysisList />
			</>
		:
		// video is being edited - show message
		video.frontendState && video.frontendState.status === 'work-in-progress' ?
			<>
				<div>
					<div style={headerStyle}>
						<h2 style={{color:theme.textColor}}><img style={iconStyle} src={theme.iconAnalysis} alt="Analysis icon" />{video.title}</h2>
						{analysisTypeHeading}
						<h4 style={{color:theme.textColor}}><nobr>{video.brand && video.brand.name}</nobr>{video.brand && video.brand.name && divider}<nobr>{video.project && video.project.name}</nobr>{video.project && divider}<nobr>{industryLabel}</nobr>{industryLabel && divider}<nobr>{video.filename}</nobr>{showTeamAnalyses && video.user && video.user.name && divider}<nobr>{showTeamAnalyses && video.user && video.user.name}</nobr>{date && divider}<nobr>{date}</nobr></h4>
					</div>
					<div style={headerStyle}>
						<h3 style={{color:theme.textColor}}>The report is currently being edited...</h3>
						<h4 style={{color:theme.textColor, padding:'20px 0 100px'}}>Please try again later.</h4>
					</div>
				</div>
			</>
		:
		// video received - show report content
		<>
			<div style={backgroundStyle}>
				<div style={headerStyle}>
					<h2 style={{color:theme.textColor}}><img style={iconStyle} src={theme.iconAnalysis} alt="Analysis icon" />{video.title}</h2>
					{analysisTypeHeading}
					<h4 style={{color:theme.textColor}}><nobr>{video.brand && video.brand.name}</nobr>{video.brand && video.brand.name && divider}<nobr>{video.project && video.project.name}</nobr>{video.project && divider}<nobr>{industryLabel}</nobr>{industryLabel && divider}<nobr>{video.filename}</nobr>{showTeamAnalyses && video.user && video.user.name && divider}<nobr>{showTeamAnalyses && video.user && video.user.name}</nobr>{date && divider}<nobr>{date}</nobr></h4>
				</div>
			</div>

			<div style={summaryStyle}>
				{video && norms && <ReportSummary video={video} norms={norms} code={code} />}
			</div>

			<div id="exposure" style={backgroundStyle}>
				<div style={containerStyle}>
					<h2 style={{color:theme.textColor}}>Brand exposure</h2>
					<AnalysisScore marg="17px 0 23px" score={video.scoreExposure} />
					<h4 style={{color:theme.textColor, maxWidth:'700px', margin:'0 auto'}}>Drag the slider and see how much exposure your brand gets throughout the video. This will help you identify scenes and objects with low or missing brand exposure.</h4>
					<ReportVideo video={video} type="exposure" currentFrame={currentFrame} />
					<ReportBarGraph video={video} type="exposure" setCurrentFrame={setCurrentFrame} />
				</div>
			</div>

			<div id="attention" style={containerStyle}>
				<h2 style={{color:theme.textColor}}>Brand attention</h2>
				<AnalysisScore marg="17px 0 23px" score={video.scoreAttention} />
				<h4 style={{color:theme.textColor, maxWidth:'700px', margin:'0 auto'}}>Drag the slider and see how much attention your brand gets throughout the video. This will help you identify scenes and objects with low or misplaced attention.</h4>
				<ReportVideo video={video} type="attention" currentFrame={currentFrame} />
				<ReportBarGraph video={video} type="attention" setCurrentFrame={setCurrentFrame} />
			</div>

			<div id="power" style={backgroundStyle}>
				<div style={containerStyle}>
					<h2 style={{color:theme.textColor}}>Brand performance</h2>
					<AnalysisScore marg="17px 0 23px" score={video.scorePower} />
					<h4 style={{color:theme.textColor, maxWidth:'710px', margin:'0 auto'}}>Combined exposure and attention, weighed by cue type, for each of your brand cues. This will help you identify cues with low brand impact.</h4>
					<ReportVideo video={video} type="power" currentFrame={currentFrame} />
					<ReportBarGraph video={video} type="power" setCurrentFrame={setCurrentFrame} />
				</div>
			</div>

			<div style={containerStyle}>
				<h2 style={{color:theme.textColor}}>Retention</h2>
				<h4 style={{color:theme.textColor, maxWidth:'890px', margin:'20px auto 14px'}}>Many people stop watching before the video has finished. So brand cues at the end will have less impact.</h4>
				<h4 style={{color:theme.textColor, maxWidth:'830px', margin:'0 auto 0'}}>Drag the slider and see in which scenes retention drops. This will help you optimize your storytelling and identify important brand elements in areas with low retention.</h4>
				<ReportVideo video={video} type="retention" />
			</div>

			{shareAndEdit}

		</>
	)

	return (
		<div style={wrapperStyle}>
			{content}
		</div>
	)
}
