import React, { useState, useEffect, useRef } from 'react'
import AnalysisCueVideoTag from './AnalysisCueVideoTag'
import Alert from './Alert'
import iconTweenBlue from '../assets/images/icon-tween-blue.svg'
import iconTweenWhite from '../assets/images/icon-tween-white.svg'
import iconTweenSelectedBlue from '../assets/images/icon-tween-selected-blue.svg'
import iconTweenSelectedWhite from '../assets/images/icon-tween-selected-white.svg'
import iconTweenDisabledBlue from '../assets/images/icon-tween-disabled-blue.svg'
import iconTweenDisabledWhite from '../assets/images/icon-tween-disabled-white.svg'
import iconDeleteLeftBlue from '../assets/images/icon-delete-left-blue.svg'
import iconDeleteLeftWhite from '../assets/images/icon-delete-left-white.svg'
import iconDeleteRightBlue from '../assets/images/icon-delete-right-blue.svg'
import iconDeleteRightWhite from '../assets/images/icon-delete-right-white.svg'
import iconDeleteBlue from '../assets/images/icon-delete-blue.svg'
import iconDeleteWhite from '../assets/images/icon-delete-white.svg'
import iconMoveBlue from '../assets/images/icon-move-blue.svg'
import iconMoveWhite from '../assets/images/icon-move-white.svg'
import iconResizeBlue from '../assets/images/icon-resize-blue.svg'
import iconResizeWhite from '../assets/images/icon-resize-white.svg'

const barContainerStyle = {
	display: 'flex',
	marginTop: '-17px',
	transition: 'padding-left .3s'
}

// component function
export default function AnalysisCueVideoObject(props) {

	const { selectedObject, objects, segment, origSegment, width, height, forceUpdate, tweenObjectsInSegment, deleteSegment, objectsToUpdate, optionsVisibleRef, tweenObjectsRef } = props

	// select first object in segment if selected object is not in current frame
	segment.previousFrameObject = selectedObject
	let activeObject = objects.find(o => o.id === selectedObject.id)
	activeObject = activeObject === undefined ? closestObject() : activeObject
	segment.previousFrameObject = activeObject // save to be accessed via the segment object

	const nameMaxChars = 28
	const [segmentName, setSegmentName] = useState(segment.name.slice(0,nameMaxChars))
	const [optionsVisible, setOptionsVisible] = useState(optionsVisibleRef.current)
	const [tweenSelected, setTweenSelected] = useState()
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const rafActiveRef = useRef(false)
	const startPosRef = useRef()
	const lastMousePosRef = useRef()
	const reqAnimIdRef = useRef()

	// dynamic styles
	const objectContainerStyle = {
		position: 'relative',
		width: width,
		height: height,
		margin: -height + 'px auto 0',
		pointerEvents: 'none'
	}

	const objectStyle = {
		position: 'absolute',
		border: '2px solid #fff',
		boxSizing: 'border-box',
		marginTop: '-2px',
		borderColor: segment.cueType !== null ? '#e5a01b' : '#fff'
	}

	const objectTitleStyle = {
		width: '0',
		height: '17px',
 		color: segment.cueType !== null ? '#fff' : '#2D3544',
 		backgroundColor: segment.cueType !== null ? '#e5a01b' : '#fff',
		pointerEvents: 'auto'
	}

	const measureTitleStyle = {
		...objectTitleStyle,
		width: 'auto',
		lineHeight: '18px',
		padding: '0 7px',
		whiteSpace: 'pre'
	}

	const optionsStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '25px',
		height: '17px',
		marginLeft: '2px',
		cursor: 'pointer',
 		backgroundColor: segment.cueType !== null ? '#e5a01b' : '#fff',
		pointerEvents: 'auto'
	}

	const dotStyle = {
		width: '3px',
		height: '3px',
		borderRadius: '3px',
		margin: '0 1px',
		backgroundColor: segment.cueType !== null ? '#fff' : '#2D3544'
	}

	const moreOptionsWrapperStyle = {
		display: 'flex',
		justifyContent: 'flex-end',
		width: optionsVisible ?  '133px' : '0',
		height: '17px',
		marginLeft: '2px',
		marginRight: '-96px',
		overflow: 'hidden',
		transition: 'width .3s'
	}

	let tweenIcon = ''
	if (segment.cueType !== null) { // cue selected = orange/white icon
		if (tweenSelected && tweenObjectsRef.current.length > 0) { // tween frame selected
			if (Math.abs(tweenObjectsRef.current[0].frameNo - activeObject.frameNo) > 1) { // ok to select second frame
				tweenIcon = iconTweenSelectedWhite
			} else { // too close to tween
				tweenIcon = iconTweenDisabledWhite
			}
		} else { // tween frame not selected
			tweenIcon = iconTweenWhite
		}
	} else { // cue not selected = white/blue icon
		if (tweenSelected && tweenObjectsRef.current.length > 0) { // tween frame selected
			if (Math.abs(tweenObjectsRef.current[0].frameNo - activeObject.frameNo) > 1) { // ok to select second frame
				tweenIcon = iconTweenSelectedBlue
			} else { // too close to tween
				tweenIcon = iconTweenDisabledBlue
			}
		} else { // tween frame not selected
			tweenIcon = iconTweenBlue
		}
	}

	const tweenStyle = {
		width: '39px',
		height: '17px',
		marginLeft: '2px',
		cursor: 'pointer',
		backgroundColor: segment.cueType !== null ? '#e5a01b' : '#fff',
		backgroundImage:'url(' + tweenIcon + ')',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '50% 50%',
		pointerEvents: 'auto'
	}

	const deleteLeftStyle = {
		...tweenStyle,
		width: '32px',
		backgroundImage:'url(' + (segment.cueType !== null ? iconDeleteLeftWhite : iconDeleteLeftBlue) + ')'
	}

	const deleteRightStyle = {
		...tweenStyle,
		width: '32px',
		backgroundImage:'url(' + (segment.cueType !== null ? iconDeleteRightWhite : iconDeleteRightBlue) + ')'
	}

	const deleteStyle = {
		...tweenStyle,
		width: '24px',
		backgroundImage:'url(' + (segment.cueType !== null ? iconDeleteWhite : iconDeleteBlue) + ')',
	}

	// space bar/arrow key video control override listeners
	useEffect(() => {
		document.getElementById('label') && document.getElementById('label').addEventListener("keydown", keyOverride)
		document.getElementById('label') && document.getElementById('label').addEventListener("keyup", keyOverride)
		return () => {
			document.getElementById('label') && document.getElementById('label').removeEventListener("keydown", keyOverride) // cleanup on unmount
			document.getElementById('label') && document.getElementById('label').removeEventListener("keyup", keyOverride)
		}
 	},[objects])

	// adjust label input width based on content of hidden span
	useEffect(() => {
		if (document.getElementById('inputMeasure')) {
			const w = document.getElementById('inputMeasure').offsetWidth
			document.getElementById('label').style.width = w + 'px'
			document.getElementById('label').style.marginLeft = -w + 'px'
		}
 	})

	// update object title and cue type on prop change
	useEffect(() => {
		setSegmentName(segment.name.slice(0,nameMaxChars))
	}, [segment.name])

	// re-render if tweenObjects array changes
	useEffect(() => {
		setTweenSelected(tweenObjectsRef.current.length > 0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tweenObjectsRef.current])

	// override video spacebar, arrows and backspace keys when editing segment name
	function keyOverride(e) {
		e.keyCode === 8|32|37|39 && e.stopPropagation()
	}

	// change segment title in received segment reference
	function saveSegmentName(e) {
		segment.name = e.target.value
		segment.dirty = segment.id < 0 ? false : segment.cueType === origSegment.cueType && segment.name.slice(0,nameMaxChars) === origSegment.name.slice(0,nameMaxChars) ? false : true // set 'dirty' on segment depending on diff from original value
		setSegmentName(e.target.value)
		forceUpdate() // force update parent
	}

	// find frameobject closest to the originally selected object // TODO: improve when grouped segments are implemented
	function closestObject() {
		const closest = objects.reduce((prev, curr) => Math.abs(curr.x - segment.previousFrameObject.x) + Math.abs(curr.y - segment.previousFrameObject.y) < Math.abs(prev.x - segment.previousFrameObject.x) + Math.abs(prev.y - segment.previousFrameObject.y) ? curr : prev)
		return objects.find(o => o.id === closest.id)
	}

	// toggle options
	function toggleOptions() {
		optionsVisibleRef.current = !optionsVisibleRef.current
		setOptionsVisible(!optionsVisible)
	}

	// send tween selection to parent
	function tween() {
		if (tweenObjectsRef.current.length === 0) { // mark tween start
			tweenObjectsRef.current.push(activeObject)
			setTweenSelected(true)
		} else {
			const obj0 = tweenObjectsRef.current[0]
			if (obj0.frameNo === activeObject.frameNo) { // same frame - cancel tween
				tweenObjectsRef.current = []
				setTweenSelected(false)
			} else if (Math.abs(obj0.frameNo - activeObject.frameNo) <= 1 ) {
				alertObjectRef.current = { title:'Can’t tween because there are no frames between your selected frames (' + Math.min(obj0.frameNo+1, activeObject.frameNo+1) + '-' + Math.max(obj0.frameNo+1, activeObject.frameNo+1) + ')' }
				setAlertTask(()=>(action)=>{setAlertTask()}) // close alert on response
			} else if (tweenObjectsRef.current.length !== 1) {
				alertObjectRef.current = { title:'Wrong number of tween points: ' + tweenObjectsRef.current.length }
				setAlertTask(()=>(action)=>{setAlertTask()}) // close alert on response
			} else { // tween
				alertObjectRef.current = { type:'confirm', title:'Tween object between frame ' + Math.min(obj0.frameNo+1, activeObject.frameNo+1) + '-' + Math.max(obj0.frameNo+1, activeObject.frameNo+1) + '?' }
				setAlertTask(()=>(action)=>{ // define alert action and display alert
					if (action) {
						tweenObjectsRef.current.push(activeObject)
						tweenObjectsInSegment(tweenObjectsRef)
						setTweenSelected(false)
					} else {
						tweenObjectsRef.current = []
						setTweenSelected(false)
					}
					setAlertTask() // remove alert
				})
			}
		}
	}

	/*** move object ***/

	// start moving object on mouse down
	function startMoveObject(e) {
		document.getElementById('label').style.display = 'none'
		startPosRef.current = { x:e.clientX, y:e.clientY }
		lastMousePosRef.current = { x:e.clientX, y:e.clientY }
		window.addEventListener('mouseup', stopMoveObject)
		window.addEventListener('mousemove', moveObject)
	}

	// stop moving object on mouse up
	function stopMoveObject(e) {
		cancelAnimationFrame(reqAnimIdRef.current)
		rafActiveRef.current = false
		window.removeEventListener('mouseup', stopMoveObject)
		window.removeEventListener('mousemove', moveObject)
		document.getElementById('label').style.display = 'block'
		activeObject.x += (e.clientX - startPosRef.current.x)/width
		activeObject.x = activeObject.x < 0 ? 0 : activeObject.x + activeObject.w > 1 ? 1 - activeObject.w : activeObject.x // limit xpos inside video area
		activeObject.y += (e.clientY - startPosRef.current.y)/height
		activeObject.y = activeObject.y < 0 ? 0 : activeObject.y + activeObject.h > 1 ? 1 - activeObject.h : activeObject.y // limit ypos inside video area
		activeObject.id > -1 && !objectsToUpdate.find(o => o.id === activeObject.id) && objectsToUpdate.push(activeObject) // add object to object update-array if not already there and it’s not a new created object
		forceUpdate() // force update parent
	}

	// save mouse position on mouse move
	function moveObject(e) {
		lastMousePosRef.current = { x:e.clientX, y:e.clientY }
		// request animation frame if not already requested (only ask for update if mouse moved)
		if (!rafActiveRef.current) {
			rafActiveRef.current = true
			reqAnimIdRef.current = requestAnimationFrame(updateObjectPosition)
		}
	}

	// update object position
	function updateObjectPosition() {
		rafActiveRef.current = false
		let newx = activeObject.x * width + (lastMousePosRef.current.x - startPosRef.current.x)
		newx = newx < 0 ? 0 : newx + activeObject.w * width > width ? width - activeObject.w * width : newx // limit xpos inside video area
		let newy = activeObject.y * height + (lastMousePosRef.current.y - startPosRef.current.y)
		newy = newy < 0 ? 0 : newy + activeObject.h * height > height ? height - activeObject.h * height : newy // limit ypos inside video area
		document.getElementById(activeObject.id).style.left = newx + 'px'
		document.getElementById(activeObject.id).style.top = (newy + 2) + 'px'
	}

	/*** move object end ***/

	/*** resize object ***/

	// start resizing object on mouse down
	function startResizeObject(e) {
		document.getElementById('label').style.display = 'none'
		startPosRef.current = { x:e.clientX, y:e.clientY }
		lastMousePosRef.current = { x:e.clientX, y:e.clientY }
		window.addEventListener('mouseup', stopResizeObject)
		window.addEventListener('mousemove', resizeObject)
	}

	// stop resizing object on mouse up
	function stopResizeObject(e) {
		cancelAnimationFrame(reqAnimIdRef.current)
		rafActiveRef.current = false
		window.removeEventListener('mouseup', stopResizeObject)
		window.removeEventListener('mousemove', resizeObject)
		document.getElementById('label').style.display = 'block'
		activeObject.w += (e.clientX - startPosRef.current.x)/width
		activeObject.w = activeObject.w < 8/width ? 8/width : activeObject.x + activeObject.w > 1 ? 1 - activeObject.x : activeObject.w // limit width inside video area
		activeObject.h += (e.clientY - startPosRef.current.y)/height
		activeObject.h = activeObject.h < 8/height ? 8/height : activeObject.y + activeObject.h > 1 ? 1 - activeObject.y : activeObject.h // limit height inside video area
		activeObject.id > -1 && !objectsToUpdate.find(o => o.id === activeObject.id) && objectsToUpdate.push(activeObject) // add object to object update-array if not already there
		forceUpdate() // force update parent
	}

	// save mouse position on mouse move
	function resizeObject(e) {
		lastMousePosRef.current = { x:e.clientX, y:e.clientY }
		// request animation frame if not already requested (only ask for update if mouse moved)
		if (!rafActiveRef.current) {
			rafActiveRef.current = true
			reqAnimIdRef.current = requestAnimationFrame(updateObjectSize)
		}
	}

	// update object position
	function updateObjectSize() {
		rafActiveRef.current = false
		let neww = activeObject.w * width + (lastMousePosRef.current.x - startPosRef.current.x)
		neww = neww < 8 ? 8 : neww + activeObject.x * width > width ? width - activeObject.x * width : neww // limit width inside video area
		let newh = activeObject.h * height + (lastMousePosRef.current.y - startPosRef.current.y)
		newh = newh < 8 ? 8 : newh + activeObject.y * height > height ? height - activeObject.y * height : newh // limit width inside video area
		document.getElementById('obj'+activeObject.id).style.width = neww + 'px'
		document.getElementById('obj'+activeObject.id).style.height = newh + 'px'
	}

	/*** resize object end ***/

	// object border(s) and edit bar/tag menu
	const objectsToDraw = objects.map(o => {

		// object position
		const objWrapperStyle = { position: 'absolute', top: o.y*height+2, left: o.x*width, zIndex: (o.id === activeObject.id ? '700' : 'auto') }
		const objStyle = { ...objectStyle, width: o.w*width, height: o.h*height }
		const miniTool = o.w*width < 16 || o.h*height < 16
		const objBarPadding = o.id !== activeObject.id ? '0' : miniTool ? '5px' : '12px'

		// dragger general style
		const draggerStyle = {
			position: 'absolute',
			width: miniTool ? '6px' : '18px',
			height: miniTool ? '6px' : '18px',
			borderRadius: miniTool ? '3px' : '9px',
			backgroundColor: segment.cueType !== null ? '#e5a01b' : '#fff',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '50% 50%',
			pointerEvents: 'auto'
		}

		// move tool
		const moveToolStyle = {
			...draggerStyle,
			top: miniTool ? '-4px' : '-10px',
			left: miniTool ? '-4px' : '-10px',
			backgroundImage: miniTool ? 'none' : 'url(' + (segment.cueType !== null ? iconMoveWhite : iconMoveBlue) + ')',
			cursor: 'move'
		}

		// resize tool
		const resizeToolStyle = {
			...draggerStyle,
			bottom: miniTool ? '-4px' : '-10px',
			right: miniTool ? '-4px' : '-10px',
			backgroundImage: miniTool ? 'none' : 'url(' + (segment.cueType !== null ? iconResizeWhite : iconResizeBlue) + ')',
			cursor: 'nwse-resize'
		}

		// object edit bar
		const objectBar = (o.id === activeObject.id &&
			<div style={{...barContainerStyle, paddingLeft:objBarPadding}}>
				<div id="inputMeasure" className="fs-object-title-input" style={measureTitleStyle}>{segmentName}</div>
				<input
					id="label"
					className="fs-object-title-input"
					style={objectTitleStyle}
					tabIndex="-1"
					spellCheck="false"
					value={segmentName}
					onChange={saveSegmentName}
					maxLength={nameMaxChars}
				/>
				<AnalysisCueVideoTag
					segment={segment}
					origSegment={origSegment}
					forceUpdate={forceUpdate}
					maxLength={nameMaxChars}
					ypos={o.y*height}
				/>
				<div title={optionsVisible ? 'Less options' : 'Advanced options'} style={optionsStyle} onClick={toggleOptions}>
					<div style={dotStyle} />
					<div style={dotStyle} />
					<div style={dotStyle} />
				</div>
				<div style={moreOptionsWrapperStyle}>
					<div style={{display:'flex'}}>
						<div title={tweenSelected && tweenObjectsRef.current.length > 0 ? tweenObjectsRef.current[0].frameNo === activeObject.frameNo ? 'Deselect this frame as tween start frame' : tweenSelected && tweenObjectsRef.current.length > 0 && Math.abs(tweenObjectsRef.current[0].frameNo - activeObject.frameNo) > 1 ? 'Tween object size and position between selected frame (' + (tweenObjectsRef.current[0].frameNo+1) + ') and this frame (' + (activeObject.frameNo+1) + ')' : 'Can’t tween object on adjacent frames' : 'Tween object size and position from this frame'} style={tweenStyle} onClick={tween} />
		 				<div title={'Delete “' + segment.name + '” from current frame and all preceding frames'} style={deleteLeftStyle} onClick={e=>deleteSegment('left')} />
						<div title={'Delete “' + segment.name + '” from current frame and all subsequent frames'} style={deleteRightStyle} onClick={e=>deleteSegment('right')} />
						<div title={'Delete “' + segment.name + '” from all frames (shortcut: Backspace)'} style={deleteStyle} onClick={deleteSegment} />
					</div>
				</div>
			</div>
		)

		const moveTool = o.id === activeObject.id && <div style={moveToolStyle} onMouseDown={startMoveObject} />
		const resizeTool = o.id === activeObject.id && <div style={resizeToolStyle} onMouseDown={startResizeObject} />

		return (
			<div key={o.id} id={o.id} style={objWrapperStyle}>
				{objectBar}
				<div id={'obj'+o.id} style={objStyle}>
					{moveTool}
					{resizeTool}
				</div>
			</div>
		)}
	)

	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	return (
		<>
			<div style={objectContainerStyle}>
				{objectsToDraw}
			</div>
			{alert}
		</>
	)
}
