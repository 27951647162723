import logo from '../assets/images/flowsam-logo.svg'
import logoLight from '../assets/images/flowsam-logo-light.svg'
import iconAnalysis from '../assets/images/icon-analysis.svg'
import iconAnalysisLight from '../assets/images/icon-analysis-light.svg'
import iconEdit from '../assets/images/icon-edit.svg'
import iconEditLight from '../assets/images/icon-edit-light.svg'
import iconDetect from '../assets/images/icon-detect.svg'
import iconDetectLight from '../assets/images/icon-detect-light.svg'
import iconDraw from '../assets/images/icon-draw.svg'
import iconDrawLight from '../assets/images/icon-draw-light.svg'
import iconJoin from '../assets/images/icon-join.svg'
import iconJoinLight from '../assets/images/icon-join-light.svg'
import iconReuse from '../assets/images/icon-reuse.svg'
import iconReuseLight from '../assets/images/icon-reuse-light.svg'
import iconUserAdd from '../assets/images/icon-user-add.svg'
import iconUserAddLight from '../assets/images/icon-user-add-light.svg'
import iconUserDelete from '../assets/images/icon-user-delete.svg'
import iconUserDeleteLight from '../assets/images/icon-user-delete-light.svg'
import selectMenuArrow from '../assets/images/select-menu-arrow.svg'
import selectMenuArrowLight from '../assets/images/select-menu-arrow-light.svg'
import checkMark from '../assets/images/checkmark.svg'
import checkMarkLight from '../assets/images/checkmark-light.svg'
import btnClose from '../assets/images/btn-close.svg'
import btnCloseLight from '../assets/images/btn-close-light.svg'

// user guide images
import iconIndexIntro from '../assets/images/guide/icon-index-intro.svg'
import iconIndexAnalyses from '../assets/images/guide/icon-index-analyses.svg'
import iconIndexBrandCues from '../assets/images/guide/icon-index-brand-cues.svg'
import iconIndexReport from '../assets/images/guide/icon-index-report.svg'
import iconIndexSettings from '../assets/images/guide/icon-index-settings.svg'
import iconIndexHelp from '../assets/images/guide/icon-index-help.svg'

import imgWelcome from '../assets/images/guide/img-welcome.jpg'

import iconAnalysesPage from '../assets/images/guide/icon-analyses-page.svg'
import iconAnalysesPageLight from '../assets/images/guide/icon-analyses-page-light.svg'
import imgAnalysesList from '../assets/images/guide/img-analyses-list.png'
import imgAnalysesListLight from '../assets/images/guide/img-analyses-list-light.png'
import imgAnalysesList2 from '../assets/images/guide/img-analyses-list-2.png'
import imgAnalysesList2Light from '../assets/images/guide/img-analyses-list-2-light.png'

import iconNewAnalysis from '../assets/images/guide/icon-new-analysis.svg'
import imgNewAnalysis from '../assets/images/guide/img-new-analysis.png'
import imgNewAnalysisLight from '../assets/images/guide/img-new-analysis-light.png'
import iconAnalysisProgress from '../assets/images/guide/icon-analysis-progress.svg'
import imgAnalysisProgress from '../assets/images/guide/img-analysis-progress.png'
import imgAnalysisProgressLight from '../assets/images/guide/img-analysis-progress-light.png'

import iconObjectList from '../assets/images/guide/icon-object-list.svg'
import imgObjectList from '../assets/images/guide/img-object-list.png'
import imgObjectListLight from '../assets/images/guide/img-object-list-light.png'
import imgObjectListTag from '../assets/images/guide/img-object-list-tag.png'
import imgObjectListTagLight from '../assets/images/guide/img-object-list-tag-light.png'

import iconTheVideo from '../assets/images/guide/icon-the-video.svg'
import iconTheVideoLight from '../assets/images/guide/icon-the-video-light.svg'
import imgVideoOptions from '../assets/images/guide/img-video-options.png'
import imgVideoOptionsLight from '../assets/images/guide/img-video-options-light.png'
import imgVideoSelected from '../assets/images/guide/img-video-selected.png'
import imgVideoSelectedLight from '../assets/images/guide/img-video-selected-light.png'

import iconSpaceBar from '../assets/images/guide/icon-spacebar.svg'
import iconSpaceBarLight from '../assets/images/guide/icon-spacebar-light.svg'
import iconArrowKeys from '../assets/images/guide/icon-arrowkeys.svg'
import iconArrowKeysLight from '../assets/images/guide/icon-arrowkeys-light.svg'
import iconShiftKey from '../assets/images/guide/icon-shiftkey.svg'
import iconShiftKeyLight from '../assets/images/guide/icon-shiftkey-light.svg'
import iconPlusKey from '../assets/images/guide/icon-pluskey.svg'
import iconPlusKeyLight from '../assets/images/guide/icon-pluskey-light.svg'
import iconBackSpace from '../assets/images/guide/icon-backspace.svg'
import iconBackSpaceLight from '../assets/images/guide/icon-backspace-light.svg'
import iconEscapeKey from '../assets/images/guide/icon-escapekey.svg'
import iconEscapeKeyLight from '../assets/images/guide/icon-escapekey-light.svg'

import imgVideoTag from '../assets/images/guide/img-video-tag.png'
import imgVideoTagLight from '../assets/images/guide/img-video-tag-light.png'
import imgVideoTagAdvanced from '../assets/images/guide/img-video-tag-advanced.png'
import imgVideoTagAdvancedLight from '../assets/images/guide/img-video-tag-advanced-light.png'
import imgVideoDraw from '../assets/images/guide/img-video-draw.png'
import imgVideoDrawLight from '../assets/images/guide/img-video-draw-light.png'

import iconRenameJoin from '../assets/images/guide/icon-rename-join.svg'
import iconRenameJoinLight from '../assets/images/guide/icon-rename-join-light.svg'
import imgGroupByCue from '../assets/images/guide/img-group-by-cue.png'
import imgGroupByCueLight from '../assets/images/guide/img-group-by-cue-light.png'
import imgJoinDialogue from '../assets/images/guide/img-join-dialogue.png'
import imgJoinDialogueLight from '../assets/images/guide/img-join-dialogue-light.png'

import iconFinishAnalysis from '../assets/images/guide/icon-finish-analysis.svg'
import iconFinishAnalysisLight from '../assets/images/guide/icon-finish-analysis-light.svg'
import imgFinishAnalysis from '../assets/images/guide/img-finish-analysis.png'
import imgFinishAnalysisLight from '../assets/images/guide/img-finish-analysis-light.png'

import iconSummary from '../assets/images/guide/icon-summary.svg'
import iconSummaryLight from '../assets/images/guide/icon-summary-light.svg'
import imgSummary from '../assets/images/guide/img-summary.png'
import imgSummaryLight from '../assets/images/guide/img-summary-light.png'
import iconExposure from '../assets/images/guide/icon-exposure.svg'
import imgExposure from '../assets/images/guide/img-exposure.png'
import imgExposureLight from '../assets/images/guide/img-exposure-light.png'
import iconAttention from '../assets/images/guide/icon-attention.svg'
import iconAttentionLight from '../assets/images/guide/icon-attention-light.svg'
import imgAttention from '../assets/images/guide/img-attention.png'
import imgAttentionLight from '../assets/images/guide/img-attention-light.png'
import iconPerformance from '../assets/images/guide/icon-performance.svg'
import imgPerformance from '../assets/images/guide/img-performance.png'
import imgPerformanceLight from '../assets/images/guide/img-performance-light.png'
import iconRetention from '../assets/images/guide/icon-retention.svg'
import iconRetentionLight from '../assets/images/guide/icon-retention-light.svg'
import imgRetention from '../assets/images/guide/img-retention.png'
import imgRetentionLight from '../assets/images/guide/img-retention-light.png'
import iconShare from '../assets/images/guide/icon-share.svg'
import imgShare from '../assets/images/guide/img-share.png'
import imgShareLight from '../assets/images/guide/img-share-light.png'

import iconAccount from '../assets/images/guide/icon-account.svg'
import iconAccountLight from '../assets/images/guide/icon-account-light.svg'
import imgAccount from '../assets/images/guide/img-account.png'
import imgAccountLight from '../assets/images/guide/img-account-light.png'
import iconSettings from '../assets/images/guide/icon-settings.svg'
import iconSettingsLight from '../assets/images/guide/icon-settings-light.svg'
import imgSettings from '../assets/images/guide/img-settings.png'
import imgSettingsLight from '../assets/images/guide/img-settings-light.png'
import iconProfile from '../assets/images/guide/icon-profile.svg'
import iconProfileLight from '../assets/images/guide/icon-profile-light.svg'
import imgProfile from '../assets/images/guide/img-profile.png'
import imgProfileLight from '../assets/images/guide/img-profile-light.png'

import iconHelp from '../assets/images/guide/icon-help.svg'
import iconHelpLight from '../assets/images/guide/icon-help-light.svg'
import imgHelp from '../assets/images/guide/img-help.jpg'

export const themes = [

	// light
	{
		logo: logoLight,
		iconAnalysis: iconAnalysisLight,
		iconEdit: iconEditLight,
		iconDetect: iconDetectLight,
		iconDraw: iconDrawLight,
		iconJoin: iconJoinLight,
		iconReuse: iconReuseLight,
		iconUserAdd: iconUserAddLight,
		iconUserDelete: iconUserDeleteLight,
		selectArrow: selectMenuArrowLight,
		checkMark: checkMarkLight,
		btnClose: btnCloseLight,

		// user guide images
		iconIndexIntro: iconIndexIntro,
		iconIndexAnalyses: iconIndexAnalyses,
		iconIndexBrandCues: iconIndexBrandCues,
		iconIndexReport: iconIndexReport,
		iconIndexSettings: iconIndexSettings,
		iconIndexHelp: iconIndexHelp,
		imgWelcome: imgWelcome,
		iconAnalysesPage: iconAnalysesPageLight,
		imgAnalysesList: imgAnalysesListLight,
		imgAnalysesList2: imgAnalysesList2Light,
		iconNewAnalysis: iconNewAnalysis,
		imgNewAnalysis: imgNewAnalysisLight,
		imgAnalysisProgress: imgAnalysisProgressLight,
		iconAnalysisProgress: iconAnalysisProgress,
		iconObjectList: iconObjectList,
		imgObjectList: imgObjectListLight,
		imgObjectListTag: imgObjectListTagLight,
		iconTheVideo: iconTheVideoLight,
		imgVideoOptions: imgVideoOptionsLight,
		imgVideoSelected: imgVideoSelectedLight,
		iconSpaceBar: iconSpaceBarLight,
		iconArrowKeys: iconArrowKeysLight,
		iconShiftKey: iconShiftKeyLight,
		iconPlusKey: iconPlusKeyLight,
		iconBackSpace: iconBackSpaceLight,
		iconEscapeKey: iconEscapeKeyLight,
		imgVideoTag: imgVideoTagLight,
		imgVideoTagAdvanced: imgVideoTagAdvancedLight,
		imgVideoDraw: imgVideoDrawLight,
		iconRenameJoin: iconRenameJoinLight,
		imgGroupByCue: imgGroupByCueLight,
		imgJoinDialogue: imgJoinDialogueLight,
		iconFinishAnalysis: iconFinishAnalysisLight,
		imgFinishAnalysis: imgFinishAnalysisLight,
		iconSummary: iconSummaryLight,
		imgSummary: imgSummaryLight,
		iconExposure: iconExposure,
		imgExposure: imgExposureLight,
		iconAttention: iconAttentionLight,
		imgAttention: imgAttentionLight,
		iconPerformance: iconPerformance,
		imgPerformance: imgPerformanceLight,
		iconRetention: iconRetentionLight,
		imgRetention: imgRetentionLight,
		iconShare: iconShare,
		imgShare: imgShareLight,
		iconAccount: iconAccountLight,
		imgAccount: imgAccountLight,
		iconSettings: iconSettingsLight,
		imgSettings: imgSettingsLight,
		iconProfile: iconProfileLight,
		imgProfile: imgProfileLight,
		iconHelp: iconHelpLight,
		imgHelp: imgHelp,

		backgroundColor0: '#f5f6f7', // header/footer
		backgroundColor1: '#eff1f2',
		backgroundColor2: '#fff',
		backgroundColor3: '#f5f6f7', // backgroundColor1 60%
		backgroundColorPage: '#fff',
		backgroundColorPage2: '#f5f6f7', // backgroundColor1 60%
		backgroundColorListItem: '#fff',
		backgroundColorReportWrapper: '#f5f6f7', // backgroundColor1 60%
		backgroundColorVideoSettings: '#e4e8eb', // grey2
		backgroundColorVideoSettingsReuse: '#e4e8eb', // grey2
		backgroundColorSceneBarReuse: '#c0c3c7',
		backgroundColorSceneButtonReuseOver: '#f5f6f7',
		backgroundColorSceneButtonReuseOut: 'rgba(245,246,247,.9)', // #f5f6f7 90%
		backgroundColorVideoLine: 'rgba(45,53,68,.18)', // textColor 18%
		backgroundColorObjectList: '#eff1f2',
		backgroundColorObjectListSortButton: '#fff',
		backgroundColorInput: '#fff',
		backgroundColorLoaderBar: '#eff1f2',

		backgroundColorAccountBox: '#eff1f2',
		borderColorAccount: '#E5E6E8',
		switchKnobColorAccount: '#fff',

		backgroundColorSettingsElements: '#e4e8eb', // grey2
		textColorSettingsElements: 'rgba(45,53,68,.5)', // textColor 50%
		textColorSettingsElementsActive: 'rgba(45,53,68,.8)', // textColor 80%

		textColor: '#2d3544',

		cueMarkerColor: '#acb1ba',

		guideBorderColor: '#D9DADD',

		headerBorderColor: 'rgba(228,232,235,.6)', // grey2 60%
		headerBorderColor2: 'rgba(45,53,68,.12)', // textColor 12%

		switchOffColor: '#d0d2d6',
		switchOffHandleColor: '#fafbfb',
		switchOnColor: '#39C481',
		switchOnHandleColor: '#f4fcf8',

		scrollbarColor: '#cdcfd3',
		scrollbarThumbColor: '#adb1b5',
		scrollbarThumbHoverColor: '#9da1a3',

		dropZoneBorderColor: 'rgba(45,53,68,.4)', // textColor 40%
		dropZoneHighlightColor: '#f5f6f7',
		dropZoneAvatarHighlightColor: '#fff',
		dropzoneErrorColor: '#cc0000',

		inputBorderColor: 'rgba(45,53,68,.4)', // textColor 40%
		inputPlaceholderColor: 'rgba(45,53,68,.6)', // textColor 60%
		inputHighlightColor: '#fff',

		radioButtonColor: '#e4e8eb',
		infoBoxColor: '#eff1f2',

		listItemMenuDotsColor: '#2d3544',
		listItemMenuColor: '#f5f6f7',
		listItemMenuBorderColor: '#fff',
		listItemMenuHighlightColor: '#fff',

		tagMenuColor: '#E4E8EB',
		tagMenuBorderColor: '#fff',
		tagMenuHighlightColor: '#fff',
		tagMenuTextColor: '#3f4755', // 90% textColor

		checkboxBackgroundColor: '#fff',
		checkboxCheckedColor: '#384255',

		progressBarColor: '#5f7c9a',
		progressBarBackColor: 'rgba(148,170,193,.17)',

		analysisScoreLowColor: '#fe4e00',
		analysisScoreMediumColor: '#e2b317',
		analysisScoreHighColor: '#39c481',
		analysisScoreTickColor: 'rgba(0,0,0,.09)'
	},

	// dark
	{
		logo: logo,
		iconAnalysis: iconAnalysis,
		iconEdit: iconEdit,
		iconDetect: iconDetect,
		iconDraw: iconDraw,
		iconJoin: iconJoin,
		iconReuse: iconReuse,
		iconUserAdd: iconUserAdd,
		iconUserDelete: iconUserDelete,
		selectArrow: selectMenuArrow,
		checkMark: checkMark,
		btnClose: btnClose,

		// user guide images
		iconIndexIntro: iconIndexIntro,
		iconIndexAnalyses: iconIndexAnalyses,
		iconIndexBrandCues: iconIndexBrandCues,
		iconIndexReport: iconIndexReport,
		iconIndexSettings: iconIndexSettings,
		iconIndexHelp: iconIndexHelp,
		imgWelcome: imgWelcome,
		iconAnalysesPage: iconAnalysesPage,
		imgAnalysesList: imgAnalysesList,
		imgAnalysesList2: imgAnalysesList2,
		iconNewAnalysis: iconNewAnalysis,
		imgNewAnalysis: imgNewAnalysis,
		imgAnalysisProgress: imgAnalysisProgress,
		iconAnalysisProgress: iconAnalysisProgress,
		iconObjectList: iconObjectList,
		imgObjectList: imgObjectList,
		imgObjectListTag: imgObjectListTag,
		iconTheVideo: iconTheVideo,
		imgVideoOptions: imgVideoOptions,
		imgVideoSelected: imgVideoSelected,
		iconSpaceBar: iconSpaceBar,
		iconArrowKeys: iconArrowKeys,
		iconShiftKey: iconShiftKey,
		iconPlusKey: iconPlusKey,
		iconBackSpace: iconBackSpace,
		iconEscapeKey: iconEscapeKey,
		imgVideoTag: imgVideoTag,
		imgVideoTagAdvanced: imgVideoTagAdvanced,
		imgVideoDraw: imgVideoDraw,
		iconRenameJoin: iconRenameJoin,
		imgGroupByCue: imgGroupByCue,
		imgJoinDialogue: imgJoinDialogue,
		iconFinishAnalysis: iconFinishAnalysis,
		imgFinishAnalysis: imgFinishAnalysis,
		iconSummary: iconSummary,
		imgSummary: imgSummary,
		iconExposure: iconExposure,
		imgExposure: imgExposure,
		iconAttention: iconAttention,
		imgAttention: imgAttention,
		iconPerformance: iconPerformance,
		imgPerformance: imgPerformance,
		iconRetention: iconRetention,
		imgRetention: imgRetention,
		iconShare: iconShare,
		imgShare: imgShare,
		iconAccount: iconAccount,
		imgAccount: imgAccount,
		iconSettings: iconSettings,
		imgSettings: imgSettings,
		iconProfile: iconProfile,
		imgProfile: imgProfile,
		iconHelp: iconHelp,
		imgHelp: imgHelp,

		backgroundColor0: '#1a1b22', // header/footer
		backgroundColor1: '#262b37',
		backgroundColor2: '#2d3544',
		backgroundColor3: '#384255',
		backgroundColorPage: '#262b37',
		backgroundColorPage2: '#2D3544',
		backgroundColorListItem: '#384255',
		backgroundColorReportWrapper: '#262b37',
		backgroundColorVideoSettings: '#384255',
		backgroundColorVideoSettingsReuse: '#262b37',
		backgroundColorSceneBarReuse: '#5f7c9a',
		backgroundColorSceneButtonReuseOver: '#5f7c9a',
		backgroundColorSceneButtonReuseOut: 'rgba(81,104,130,.9)', //#5f7c9a 90%
		backgroundColorVideoLine: 'rgba(255,255,255,.18)', // textColor 18%
		backgroundColorObjectList: '#384255',
		backgroundColorObjectListSortButton: 'rgba(255, 255, 255, 0.2)',
		backgroundColorInput: '#4B576B',
		backgroundColorLoaderBar: '#384255',

		backgroundColorAccountBox: '#384255',
		borderColorAccount: '#384050',
		switchKnobColorAccount: '#1a1b22',

		backgroundColorSettingsElements: '#384255',
		textColorSettingsElements: 'rgba(255,255,255,.5)', // textColor 50%
		textColorSettingsElementsActive: 'rgba(255,255,255,.8)', // textColor 80%

		textColor: '#fff',

		cueMarkerColor: '#fff',

		guideBorderColor: '#4b576b',

		headerBorderColor: 'transparent',
		headerBorderColor2: '#2d3544',

		switchOffColor: '#3d4b5f',
		switchOffHandleColor: '#222731',
		switchOnColor: '#39C481',
		switchOnHandleColor: '#2d554c',

		scrollbarColor: '#565e6d',
		scrollbarThumbColor: '#6f7683',
		scrollbarThumbHoverColor: '#7b818d',

		dropZoneBorderColor: 'rgba(255,255,255,.5)',
		dropZoneHighlightColor: '#384255',
		dropZoneAvatarHighlightColor: '#4b576b',
		dropzoneErrorColor: '#fe4e00',

		inputBorderColor: 'rgba(255, 255, 255, 0.7)',
		inputPlaceholderColor: 'rgba(255, 255, 255, 0.6)',
		inputHighlightColor: '#262b37',

		radioButtonColor: '#424C60',
		infoBoxColor: '#384255',

		listItemMenuDotsColor: '#fff',
		listItemMenuColor: '#2d3544',
		listItemMenuBorderColor: '#384255',
		listItemMenuHighlightColor: '#384255',

		tagMenuColor: '#384255',
		tagMenuBorderColor: '#2d3544',
		tagMenuHighlightColor: '#1A1B22',
		tagMenuTextColor: 'rgba(255,255,255,.9)', // 90% textColor

		checkboxBackgroundColor: '#616978',
		checkboxCheckedColor: 'rgba(255,255,255,.85)',

		progressBarColor: '#5f7c9a',
		progressBarBackColor: 'rgba(148,170,193,.17)',

		analysisScoreLowColor: '#fe4e00',
		analysisScoreMediumColor: '#e2b317',
		analysisScoreHighColor: '#39c481',
		analysisScoreTickColor: 'rgba(255,255,255,.12)'
	}
]
