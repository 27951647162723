import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { APIContext } from '../services/api'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import ReactCardFlip from 'react-card-flip'
import AnalysisScore from './AnalysisScore'
import AnalysisListItemMenu from './AnalysisListItemMenu'
import AnalysisListItemDetails from './AnalysisListItemDetails'
import AnalysisProgressBar from './AnalysisProgressBar'
import Alert from './Alert'
import labelAnimatics from '../assets/images/label-animatic.svg'

// styles
const titleStyle = {
	fontFamily: 'Greycliff demibold',
	marginTop: '10px',
	width: '300px',
	textShadow: '0 0 40px rgba(0,0,0,.8)',
	color: '#fff'
}

const projectStyle = {
	lineHeight: '32px',
	width: '300px',
	textShadow: '0 0 40px rgba(0,0,0,.8)',
	color: '#fff'
}

const brandStyle = {
	...projectStyle,
	lineHeight: '8px'
}

const dateStyle = {
	fontSize:'12px',
	marginBottom:'-20px',
	opacity: '0',
	transition: 'opacity .2s',
	color: '#fff'
}

const infoAreaStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '160px'
}

const tagButtonStyle = {
	padding: '6px 20px 5px',
	margin: '-6px 0 8px',
	fontSize: '14px',
	letterSpacing: '.2px'
}

// component function
export default function AnalysisListItem(props) {

	const { item, idx, forceUpdate, projects, brands, updateProjects, updateBrands } = props
	const { guid, title, project, brand, currentState, frontendState, scoreExposure:exposure, scoreAttention:attention, scorePower:power, createdAt } = item
	const context = useContext(APIContext)
	const history = useHistory()
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [editDetails, setEditDetails] = useState(false)
	const [pctDone, setPctDone] = useState(0)
	const [analysisCompleted, setAnalysisCompleted] = useState(false)
	const [bgImage, setBgImage] = useState()
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const imageLoading = useRef(false)

	const status = frontendState && frontendState.status ? frontendState.status : currentState === 'complete' || analysisCompleted ? 'tagging' : 'analyzing'
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const showTeamAnalyses = globalState.userData && globalState.userData.settings && globalState.userData.settings.showTeamAnalyses ? globalState.userData.settings.showTeamAnalyses : false
	const userId = globalState.userData && globalState.userData.id

	// analysis creation date
	const dateOptions = { year:'numeric', month:'short', day:'numeric', hour:'numeric', minute:'numeric' }
	const date = createdAt ? new Date(createdAt).toLocaleDateString("en-GB", dateOptions) : null

	// dynamic styles
	const titleContainerStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		backgroundImage: item.analysisType === 2 && 'url(' + labelAnimatics + ')',
		backgroundRepeat: 'no-repeat',
		padding: '20px',
		boxSizing: 'border-box',
		backgroundColor: 'rgba(0,0,0,.25)',
		overflow: 'hidden'
	}

	const imageStyle = {
		width: '100%',
		height: '194px',
		backgroundImage: 'url(' + (bgImage && bgImage.src) + ')',
		backgroundRepeat: 'no-repeat',
		backgroundSize: (item.height/item.width) <= .75 ? '155%' : 'cover', // overscale to hide letterbox black bars
		backgroundPosition: 'center center'
	}

	const itemStyle = {
		position: 'relative',
		backgroundColor: theme.backgroundColorListItem,
		width: '340px',
		height: '354px',
		margin: '0 15px 30px',
		cursor: status === 'analyzing' ? 'auto' : 'pointer'
	}

	// set up progress listeners, if needed
	useEffect(() => {
		if (status === 'analyzing') {
			// subscribe to progress events for this analysis
			context.io.socket.post('/api/v1/user/subscribe', { roomName: 'video-' + guid }, (data, res) => {
				if (res.statusCode !== 200) {
					// TODO: handle error
				}
			})
			// listen for analysis progress
			context.io.socket.on('video-progress-' + guid, msg => {
				const progress = Object.values(msg.done).reduce((a, b) => a + b) / Object.values(msg.todo).reduce((a, b) => a + b) * 100 // calculate progress (divide property value sums)
				progress === 100 && setTimeout(() => setAnalysisCompleted(true), 2000) // delay so user can see finished progress
				setPctDone(progress)
			})
		}
 	},[context.io.socket, guid, status])

	// load bg image - and retry if not found (yet)
	if (guid && !imageLoading.current) {
		imageLoading.current = true
		let retries = 0
		const img = new Image()
		const imgSrc = process.env.REACT_APP_GCS_BUCKET_URL + '/' + guid + '/thumb.jpg'
		img.onload = () => setBgImage(img)
		img.onerror = () => setTimeout(() => {
			if (++ retries < 50) {
				img.src = imgSrc
			}
		},5000)
		img.src = imgSrc
	}

	// show analysis date
	function onEnter(e) {
		document.getElementById('date'+guid).style.opacity = '1'
	}

	// hide analysis date
	function onLeave(e) {
		document.getElementById('date'+guid).style.opacity = '0'
	}

	// click handler
	function handleClick(e) {
		if (status === 'tagging' || status === 'work-in-progress') {
			history.push('/' + guid) // go to tagging
		} else if (status === 'finished') {
			history.push('/report/' + guid) // go to report
		}
	}

	const infoContent = (
		status === 'analyzing' ?
		// video is analyzing
		<div style={{width:'260px'}}>
			<p style={{margin:'-2px 0 8px', color:theme.textColor}}>&nbsp;Analyzing...</p>
			<AnalysisProgressBar pct={pctDone} />
			<div style={{height:'20px'}} />
			<button className="fs-button" style={tagButtonStyle} tabIndex="-1" disabled={true}>ADD BRAND CUES</button>
		</div>
		:
		status === 'tagging' || status === 'work-in-progress' ?
		// video is ready for tagging
		<div>
			<p style={{margin:'-2px 0 26px', color:theme.textColor}}>Add brand cues to the video<br/>to finish the analysis</p>
			<button className="fs-button" style={tagButtonStyle} tabIndex="-1">{status === 'work-in-progress' ? 'CONTINUE TAGGING' : 'ADD BRAND CUES'}</button>
		</div>
		:
		status === 'finished' &&
		// analysis complete
		<>
			<AnalysisScore marg='5px 9px 1px' type="Exposure" score={exposure} />
			<AnalysisScore marg='5px 9px 1px' type="Attention" score={attention} />
			<AnalysisScore marg='5px 9px 1px' type="Performance" score={power} />
		</>
	)

	const itemMenu = ((showTeamAnalyses && userId === item.user.id) || !showTeamAnalyses) && <AnalysisListItemMenu guid={guid} status={status} forceUpdate={forceUpdate} editDetails={setEditDetails} setAlertTask={setAlertTask} alertObjectRef={alertObjectRef} />

	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	return (
		<>
			<ReactCardFlip isFlipped={editDetails} cardZIndex={900-idx}>
				<div style={itemStyle} onClick={handleClick} onMouseEnter={onEnter} onMouseLeave={onLeave}>
					<div style={imageStyle}>
		  				<div style={titleContainerStyle}>
							<h4 style={brandStyle}>{brand && brand.name}</h4>
		  					<h3 style={titleStyle}>{title}</h3>
		  					<h4 style={projectStyle}>{project && project.name}</h4>
							<p id={'date'+guid} style={dateStyle}>{showTeamAnalyses && <>{item.user.name}&nbsp;&nbsp;|&nbsp;&nbsp;</>}{date}</p>
		  				</div>
		  				<div style={infoAreaStyle}>
		  					{infoContent}
		  				</div>
		  				{itemMenu}
		  			</div>
				</div>
				<AnalysisListItemDetails item={item} closeDetails={setEditDetails} projects={projects} updateProjects={updateProjects} brands={brands} updateBrands={updateBrands} setAlertTask={setAlertTask} alertObjectRef={alertObjectRef} />
			</ReactCardFlip>
			{alert}
		</>
	)
}
