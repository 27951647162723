import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../services/globalState'
import Menu from './Menu'
import { themes } from '../themes/themes'

// styles
const topContainerStyle = {
	display: 'flex',
	alignItems: 'center',
	minWidth: '688px',
	//maxWidth: '1080px',
	height: '79px',
	margin: '0 auto',
	padding: '0 50px'
}

const topPadStyle = {
	height: '79px'
}

const logoContainerStyle = {
	flexGrow: '1',
	height: '34px'
}

const logoStyle = {
	width: '176px',
	cursor: 'pointer'
}

// component function
export default function Header() {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const history = useHistory()

	// dynamic styles
	const topBarStyle = {
		position: 'fixed',
		width: '100%',
		backgroundColor: theme.backgroundColor0,
		borderBottom: '1px solid ' + theme.headerBorderColor,
		zIndex: '9999'
	}

	// go to root page or login
	function goHome() {
		globalState.loggedIn ? history.push('/') :
		!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? history.push('/login') :
		window.location.href = process.env.REACT_APP_PUBLIC_URL
	}

	return (
		<>
			<div style={topBarStyle}>
				<div style={topContainerStyle}>
					<div style={logoContainerStyle}>
						<img src={theme.logo} style={logoStyle} onClick={goHome} alt="Flowsam logo"/>
					</div>
					<Menu />
				</div>
			</div>
			<div style={topPadStyle}></div>
		</>
	)
}
