import { useContext } from 'react'
import { GlobalContext } from './globalState'

// hook function
export default function useCueTypes(props) {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const cueTypes = globalState.userData &&
					 globalState.userData.organization &&
					 globalState.userData.organization.settings &&
					 globalState.userData.organization.settings.cueTypes

	if (cueTypes && Array.isArray(cueTypes) && cueTypes.length > 0) {
		return cueTypes
	} else {
		return [
			{ type: 1, label: 'Category', shortLabel: 'Cat.' },
			{ type: 2, label: 'Product', shortLabel: 'Prod.' },
			{ type: 4, label: 'Comms', shortLabel: 'Comms' },
			{ type: 3, label: 'Logo', shortLabel: 'Logo' },
			{ type: 6, label: 'Brand ref (hi)', shortLabel: 'Brand ref (hi)' },
			{ type: 7, label: 'Brand ref (lo)', shortLabel: 'Brand ref (lo)' },
			{ type: null, label: 'Not tagged', shortLabel: 'Not tagged' }
		]
	}
}
