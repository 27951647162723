import React, { useContext, useState, useEffect, useRef } from 'react'
import Alert from './Alert'
import { APIContext } from '../services/api'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'

// styles
const tdStyle = {
	padding: '7px 20px 0 0',
	whiteSpace: 'nowrap',
	lineHeight: '28px',
	textTransform: 'capitalize'
}

// component function
export default function AccountInvoices(props) {

	const {org} = props
	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const [invoices, setInvoices] = useState()
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const dateOptions = { year:'numeric', month:'short', day:'numeric' }

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// dynamic styles
	const accountElementBack = {
		backgroundColor: theme.backgroundColorPage,
		padding: '53px 50px 70px',
		borderTop: '1px solid ' + theme.borderColorAccount,
		minWidth: '980px'
	}

	const invoiceBoxStyle = {
		display: 'flex',
		flexWrap: 'wrap',
		backgroundColor: theme.backgroundColorAccountBox,
		margin: '27px 0 10px',
		padding: '24px 30px 26px',
		color: theme.textColor
	}

	const labelStyle = {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: 'Greycliff demibold',
		color: theme.textColor,
		opacity: '.5',
		whiteSpace: 'nowrap'
	}

	// get invoice list
	useEffect(() => {
		context.io.socket.get('/api/v1/subscription/invoice', (data, res) => {
			if (res.statusCode === 200) {
				data.data && data.data.length > 0 && setInvoices(data.data)
			} else {
				showAlert('Error getting invoice list: ' + res.error.message)
			}
		})
	}, [context.io.socket, org])

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	// add thousand separators to number
	function numberWithCommas(val) {
	    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}

	const invoiceList = invoices && invoices.map((inv, i) =>
		<tr key={i}>
			<td style={tdStyle}>{new Date(inv.created*1000).toLocaleDateString("en-GB", dateOptions)}</td>
			<td style={tdStyle}>{inv.lines.data[0].price.nickname}</td>
			<td style={tdStyle}>{inv.charge && inv.charge.payment_method_details.card.brand + ' •••• ' + inv.charge.payment_method_details.card.last4}</td>
			<td style={tdStyle}>{numberWithCommas(inv.amount_paid/100)} €</td>
			<td style={tdStyle}>{inv.number}</td>
			<td style={tdStyle}>{inv.status}</td>
			<td style={{textAlign:'right'}}>{inv.invoice_pdf && <a style={{fontSize:'14px', color:'#39c481', textDecoration:'none', display:'inline-block', paddingTop:'9px'}} rel="noopener noreferrer" href={inv.invoice_pdf}>Download</a>}</td>
		</tr>
	)

	return (invoices ?
		<div style={accountElementBack}>
			<div className="accountElementContainer">
				<h2 style={{color:theme.textColor}}>Invoices</h2>
				<div style={invoiceBoxStyle}>
					<table cellPadding="0" cellSpacing="0" style={{width:'100%', border:'0', margin:'0', padding:'0', textAlign:'left'}}>
						<thead>
							<tr style={labelStyle}>
								<td>Date</td>
								<td>Subscription</td>
								<td>Credit card</td>
								<td>Amount</td>
								<td>Invoice No.</td>
								<td>Status</td>
								<td>&nbsp;</td>
							</tr>
						</thead>
						<tbody>
							{invoiceList}
						</tbody>
					</table>
				</div>
			</div>
			{alert}
		</div> : ''
	)
}
