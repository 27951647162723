import React, { useState, useEffect, useContext, useRef } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import arrow from '../assets/images/user-guide-arrow.svg'

// styles
const buttonStyle = {
	minWidth: '120px',
	padding: '6px 20px 5px',
	fontSize: '14px',
	letterSpacing: '.2px'
}

// component function
export default function AlertWelcome(props) {

	const { action } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [opacity, setOpacity] = useState(0)
	const userName = globalState.userData && globalState.userData.name
	const themeId = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? globalState.userData.settings.theme : 0
	const theme = themes[themeId]
	const timeoutID = useRef()

	// dynamic styles
	const overlayStyle = {
		position: 'fixed',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '100%',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		opacity: opacity,
		transition: 'opacity .4s',
		backgroundColor: themeId === 0 ? 'rgba(64,64,64,.4)' : 'rgba(0,0,0,.4)',
		zIndex: '9999'
	}

	const messageContainerStyle = {
		position: 'relative',
		width: '720px',
		minWidth: '720px',
		backgroundColor: theme.backgroundColor3,
		borderRadius: '15px',
		boxSizing: 'border-box',
		padding: '42px 107px 55px',
		textAlign: 'center',
		boxShadow: '0px 0px 70px 0px rgba(0,0,0,.1)'
	}

	const messageHeadingStyle = {
		marginBottom: '16px',
		fontFamily: 'Greycliff demibold',
		color: theme.textColor
	}

	const messageBodyStyle = {
		//marginBottom: '12px',
		fontSize: '16px',
		lineHeight: '23px',
		textAlign: 'left',
		color: theme.textColor
	}

	const arrowContainerStyle = {
		position: 'absolute',
		top: '20px',
		left: '625px',
		width: '50px',
		height: userName ? '95px' : '60px',
		backgroundImage: 'url(' + arrow + ')',
		backgroundSize: '100% 100%'
	}

	// fade in & set keypress listener
	useEffect(() => {
		timeoutID.current = setTimeout(function() { // delay - otherwise no opacity transition
			setOpacity(1)
		}, 300)
		window.addEventListener('keydown', keyPressed)
		// cleanup on unmount
		return () => { window.removeEventListener('keydown', keyPressed) }
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// handle keypress events
	function keyPressed(e) {
		if (e.keyCode === 27) { // escape
			clearTimeout(timeoutID.current)
			action(false)
		} else if (e.keyCode === 13) { // enter
			clearTimeout(timeoutID.current)
			action(true)
		}
	}

	// handle button click
	function performAction(e,b) {
		e.nativeEvent.stopImmediatePropagation()
		action(b)
	}

	const userHiName = userName && <div>Hi {userName}!</div>

	return (
		<div id="overlay" style={overlayStyle}>
			<div style={messageContainerStyle}>
				<h3 style={messageHeadingStyle}>{userHiName}Welcome to flowsam<sup>®</sup></h3>
				<p style={messageBodyStyle}>To get started, we recommend that you take a look at our <span style={{fontFamily:'Greycliff demibold'}}>user guide</span>. And when you’re ready, create your first analysis in three easy steps:</p>
				<ol style={{...messageBodyStyle, paddingLeft:'17px'}}>
					<li style={{marginBottom:'12px', paddingLeft:'10px'}}>Upload your video and let flowsam analyze it</li>
					<li style={{marginBottom:'12px', paddingLeft:'10px'}}>Tag your brand cues</li>
					<li style={{marginBottom:'12px', paddingLeft:'10px'}}>See (and share) the report</li>
				</ol>
				<p style={messageBodyStyle}>If you have any questions you’re always welcome to get in touch with our support team at <a href="mailto:support@flowsam.ai" target="_blank" rel="noopener noreferrer" style={{color:'#39C481', textDecoration:'none'}}>support@flowsam.ai</a>.</p>
				<div style={{marginTop:'32px'}}><button style={buttonStyle} tabIndex="-1" className="fs-button" onClick={e=>performAction(e,true)}>OK, GOT IT</button></div>
				<div style={arrowContainerStyle} />
			</div>
		</div>
	)
}
