import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import useCueTypes from '../services/useCueTypes'

// component function
export default function AnalysisCueVideoTag(props) {

	const { segment, origSegment, forceUpdate, maxLength, ypos } = props
	const [selectedCueType, setSelectedCueType] = useState(segment.cueType)
	const [menuVisible, setMenuVisible] = useState(segment.cueType === null)
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const cueTypes = useCueTypes()

	// dynamic styles
	const tagBoxStyle = {
		marginLeft: '2px',
		pointerEvents: 'auto'
	}

	const tagButtonStyle = {
		display: 'flex',
		alignItems: 'center',
		width: '95px',
		height: '22px',
		color:  theme.tagMenuTextColor,
		backgroundColor:  theme.tagMenuColor,
		borderBottom: '1px solid ' + theme.tagMenuBorderColor,
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		padding: '0 7px',
		boxSizing: 'border-box',
		cursor: 'pointer',
	}

	const tagMenuStyle = {
		position: 'absolute',
		top: menuVisible ? ypos > 100 ? -(cueTypes.length-1) * 22 - 16 + 'px' : '0' : ypos > 100 ? '-17px' : '0',
		height: menuVisible ? (cueTypes.length-1) * 22 - 1 + 'px' : '0',
		transition: 'height .3s, top .3s',
		overflow: 'hidden',
		zIndex: '800'
	}

	const selectedLabelStyle = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '95px',
		height: '17px',
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		padding: '1px 6px 0 7px',
		boxSizing: 'border-box',
		cursor: 'pointer',
		color: segment.cueType !== null ? '#fff' : '#2D3544',
		backgroundColor: segment.cueType !== null ? '#e5a01b' : '#fff'
	}

	const menuArrowStyle = {
		width: '0',
		height: '0',
		marginBottom: '1px',
		borderStyle: 'solid',
		borderWidth: '0 3px 4px 3px',
		borderColor: 'transparent transparent ' + (segment.cueType !== null ? '#fff' : '#2D3544') + ' transparent',
		transform: ypos > 100 ? 'none' : 'rotate(180deg)',
		transition: 'transform .3s'
	}

	// update selected cue type on prop change
	useEffect(() => {
		setSelectedCueType(segment.cueType)
		setMenuVisible(segment.cueType === null)
	}, [segment.cueType])

	// highlight menu item on mouseover
	function onTagOver(e) {
		e.currentTarget.style.backgroundColor = theme.tagMenuHighlightColor
		e.currentTarget.style.color = theme.textColor
	}

	// remove menu item highlight on mouseout
	function onTagOut(e) {
		e.currentTarget.style.backgroundColor = theme.tagMenuColor
		e.currentTarget.style.color = theme.tagMenuTextColor
	}

	// set selected cueType on segment and force parent update
	function handleClick(e) {
		const sel = parseInt(e.currentTarget.getAttribute('type'))
		const selVal = isNaN(sel) ? null : sel
		segment.cueType = selVal
		segment.dirty = segment.id < 0 ? false : segment.cueType === origSegment.cueType && segment.name.slice(0,maxLength) === origSegment.name.slice(0,maxLength) ? false : true // set 'dirty' on segment depending on diff from original value
		setMenuVisible(false)
		forceUpdate()
	}

	// toggle menu
	function toggleMenu() {
		setMenuVisible(!menuVisible)
	}

	// tag button menu
	const menu = cueTypes.map((btn,i) => {
		return (btn.type !== selectedCueType &&
			<div key={i} type={btn.type} style={tagButtonStyle} onMouseOver={onTagOver} onMouseOut={onTagOut} onClick={handleClick}>{btn.label}</div>
		)
	})

	// selected item label
	const selectedLabel = cueTypes.find(btn => btn.type === selectedCueType).label
	const arrowStyle = menuVisible ? { ...menuArrowStyle, transform: ypos > 100 ? 'rotate(180deg)' : 'none' } : menuArrowStyle

	return (
		<div style={tagBoxStyle}>
			<div style={selectedLabelStyle} onClick={toggleMenu}>{selectedLabel}<div style={arrowStyle} /></div>
			<div style={tagMenuStyle}>{menu}</div>
		</div>
	)
}
