import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import useCueTypes from '../services/useCueTypes'

// styles
const objectContainerStyle = {
	margin: '0 0 15px 20px',
	width: '170px',
	minHeight: '120px',
	cursor: 'pointer'
}

const objectStyle = {
	position: 'absolute',
	border: '1px solid #fff',
	boxSizing: 'border-box'
}

const objectTitleStyle = {
	position: 'relative',
	width: '100%',
	height: '20px',
	backgroundColor: 'rgba(26,27,34,.5)',
	opacity: '0',
	transition: 'opacity .4s'
}

const tagContainerStyle = {
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'center',
	margin: '5px -3px 0 -3px'
}

const tagButtonStyle = {
	display: 'flex',
	alignItems: 'center',
	height: '19px',
	borderRadius: '19px',
	backgroundColor: '#dc9a20',
	fontSize: '12px',
	fontFamily: 'Greycliff demibold',
	color: '#fff',
	padding: '0 6px',
	cursor: 'pointer',
	marginTop: '1px'
}

// component function
export default function AnalysisCueObject(props) {

	const { obj, chunkImg, segment, origSegment, focused, goFrame, forceUpdate } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const nameMaxChars = 28
	const [segmentName, setSegmentName] = useState(segment.name.slice(0,nameMaxChars))
	const [selected, setSelected] = useState(segment.cueType)
	const objHeight = Math.floor((chunkImg.height/6)/(chunkImg.width/5)*170)
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const cueTypes = useCueTypes()

	const detectedObjectStyle = {
		width: '170px',
		height: objHeight,
		position: 'relative'
	}

	// dynamic styles
	const tagTextStyle = {
		display: 'flex',
		alignItems: 'center',
		height: '19px',
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		color: theme.textColor,
		opacity: '.5',
		padding: '0 6px',
		cursor: 'pointer',
		transition: 'opacity .2s',
		marginTop: '1px'
	}

	// space bar video control override listener
	useEffect(() => {
		document.getElementById('segmentNameInput'+obj.id).addEventListener("keydown", keyOverride)
		document.getElementById('segmentNameInput'+obj.id).addEventListener("keyup", keyOverride)
		return () => {
			document.getElementById('segmentNameInput'+obj.id).removeEventListener("keydown", keyOverride) // cleanup on unmount
			document.getElementById('segmentNameInput'+obj.id).removeEventListener("keyup", keyOverride)
		}
 	},[obj])

	// update object title and cue type on prop change
	useEffect(() => {
		setSegmentName(segment.name.slice(0,nameMaxChars))
		setSelected(segment.cueType)
	}, [segment.name, segment.cueType])

	// override video spacebar, arrows and backspace keys when editing segment name
	function keyOverride(e) {
		e.keyCode === 8|32|37|39 && e.stopPropagation()
	}

	// show object title
	function onEnter(e) {
		e.currentTarget.children[1].style.opacity = '1'
	}

	// hide object title
	function onLeave(e) {
		e.currentTarget.children[1].style.opacity = '0'
		document.getElementById('segmentNameInput'+obj.id).blur() // prevent input when hidden
	}

	// highlight tag on mouseover (if not selected as tag)
	function onTagOver(e) {
		if (parseInt(e.currentTarget.getAttribute('type')) !== selected) {
			e.currentTarget.style.opacity = '1'
		}
	}

	// dim tag on mouseover (if not selected as tag)
	function onTagOut(e) {
		if (parseInt(e.currentTarget.getAttribute('type')) !== selected) {
			e.currentTarget.style.opacity = '.5'
		}
	}

	// set selected cueType on segment and force parent update
	function handleAction(e) {
		const sel = parseInt(e.currentTarget.getAttribute('type'))
		const selVal = sel === selected ? null : sel
		segment.cueType = selVal
		segment.dirty = segment.id < 0 ? false : segment.cueType === origSegment.cueType && segment.name.slice(0,nameMaxChars) === origSegment.name.slice(0,nameMaxChars) ? false : true // set 'dirty' on segment depending on diff from original value
		setSelected(selVal)
		forceUpdate() // force update parent
	}

	// don’t go to frame on segment name edit
	function dontGoFrame(e) {
		e.stopPropagation()
	}

	// change segment title in received segment reference
	function saveSegmentName(e) {
		segment.name = e.target.value
		segment.dirty = segment.id < 0 ? false : segment.cueType === origSegment.cueType && segment.name.slice(0,nameMaxChars) === origSegment.name.slice(0,nameMaxChars) ? false : true // set 'dirty' on segment depending on diff from original value
		setSegmentName(e.target.value)
		forceUpdate() // force update parent
	}

	// object style logic
	const fx = -obj.frameNo % 5 * 170
    const fy = -Math.floor(obj.frameNo % 30 / 5) * objHeight
	const bgStyle = { ...detectedObjectStyle, backgroundImage:'url('+(chunkImg && chunkImg.src)+')', backgroundSize:170*5+'px '+objHeight*6+'px', backgroundPosition:fx+'px '+fy+'px', boxShadow: focused ? 'inset 0 0 0px 5px rgba(255,255,255,.55)' : 'none' }
	const objStyle = { ...objectStyle, width:obj.w*170, height:obj.h*objHeight, top:obj.y*objHeight, left:obj.x*170, border: selected !== null ? '1.5px solid #e5a01b' : '1px solid #fff' }

	// tag buttons
	const btnList = cueTypes.filter(cType => cType.type !== null).map((btn,i) => {
		const btnStyle = selected === (btn.type) ? tagButtonStyle : tagTextStyle
		return (
			<div key={i} type={(btn.type)} style={btnStyle} onMouseOver={onTagOver} onMouseOut={onTagOut} onClick={handleAction}>{btn.shortLabel}</div>
		)}
	)

	return (
		<div style={objectContainerStyle}>
			<div style={bgStyle} onMouseEnter={onEnter} onMouseLeave={onLeave} onClick={(e)=>goFrame(e,obj)}>
				<div style={objStyle} />
				<div style={objectTitleStyle}>
					<input
						id={'segmentNameInput'+obj.id}
						className="fs-object-title-input"
						tabIndex="-1"
						spellCheck="false"
						value={segmentName}
						onClick={dontGoFrame}
						onChange={saveSegmentName}
						maxLength={nameMaxChars}
					/>
				</div>
			</div>
			<div style={tagContainerStyle}>
				{btnList}
			</div>
		</div>
	)
}
