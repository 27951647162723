import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { APIProvider } from './services/api'
import { GlobalProvider } from './services/globalState'
import { BrowserRouter as Router } from 'react-router-dom'
import './assets/css/index.css'

const rootContent = (
	<APIProvider>
		<GlobalProvider>
			<Router>
				<App />
			</Router>
		</GlobalProvider>
	</APIProvider>
)

ReactDOM.render(rootContent, document.getElementById('root'))
