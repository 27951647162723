import React, { useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'

// styles
const progressContainerStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	marginBottom: '7px'
}

// component function
export default function AnalysisProgressBar(props) {

	const { label, pct } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]

	// dynamic styles
	const progressLabelStyle = {
		width: '280px',
		textAlign: 'right',
		margin: '0 40px 3px 0',
		color: theme.textColor,
		opacity: pct === 0 ? '.6' : '1'
	}

	const backBarStyle = {
		width: '280px',
		height: '11px',
		backgroundColor: theme.progressBarBackColor,
		borderRadius: '6px'
	}

	const frontBarStyle = {
		height: '11px',
		backgroundColor: theme.progressBarColor,
		borderRadius: '6px',
		opacity: pct === 100 ? '1' : '.75',
		width: pct + '%',
		transition: 'width 1s'
	}

	const barLabel = label && <h4 style={progressLabelStyle}>{label}</h4>

	return (
		<div style={progressContainerStyle}>
			{barLabel}
			<div style={backBarStyle}>
				<div style={frontBarStyle} />
			</div>
		</div>
	)
}
