import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { GlobalContext } from './services/globalState'
import { themes } from './themes/themes'
import AnalysisForm from './components/AnalysisForm'
import AnalysisProgress from './components/AnalysisProgress'
import AnalysisCues from './components/AnalysisCues'
import AnalysisList from './components/AnalysisList'

// styles
const containerStyle = {
	textAlign: 'center',
	minWidth: '728px',
	maxWidth: '1120px',
	margin: '0 auto',
	padding: '93px 20px 110px'
}

// component function
export default function Analyses() {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [stepInfo, setStepInfo] = useState({ step: 1 })
	const history = useHistory()
	const {guid} = useParams()
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]

	// dynamic styles
	const wrapperStyle = {
		minHeight: 'calc(100vh - 199px)',
		backgroundColor: theme.backgroundColor1,
		minWidth: stepInfo.step === 3 ? '1200px' : '768px'
	}

	const backgroundStyle = {
		width: '100%',
		backgroundColor: theme.backgroundColor2
	}

	// if we got guid go to step 3 else step 1
	useEffect(() => {
		if (guid) {
			setStepInfo({ step: 3, data: { title: '', guid: guid } })
		} else {
			setStepInfo({ step: 1 })
		}
	}, [guid])

	// go to step 2 - analysis progress
	function goProgress(formData) {
		setStepInfo({ step: 2, data: formData })
	}

	// go to step 3 - cue tagging
	function goCues(guid) {
		history.push('/' + guid)
	}

	// step content logic
	const stepContent = (
		stepInfo.step === 1 ? <AnalysisForm action={goProgress} /> :
		stepInfo.step === 2 ? <AnalysisProgress action={goCues} new={(e)=>setStepInfo({step:1,update:true})} data={stepInfo.data || {}} /> :
		stepInfo.step === 3 && <AnalysisCues data={stepInfo.data || {}} />
	)

	const list = stepInfo.step !== 3 ? stepInfo.update ? <AnalysisList update={Math.random()} /> : <AnalysisList /> : null

	// step content
	const content = (
		// not logged in
		!globalState.loggedIn ?
			null
		:
		// logged in - show step content
		<>
			<div style={backgroundStyle} onDragOver={e=>e.preventDefault()} onDrop={e=>e.preventDefault()}>
				<div style={containerStyle}>
					{stepContent}
				</div>
			</div>
			{list}
		</>
	)

	return (
		<div style={wrapperStyle}>
			{content}
		</div>
	)
}
