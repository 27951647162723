import React, { useState, useEffect, useContext, useRef } from 'react'
import { APIContext } from '../services/api'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import AlertReuseVideo from './AlertReuseVideo'

// styles
const listContainerStyle = {
	maxHeight: (83+10)*6-10+'px',
	width: '670px',
	overflowY: 'auto'
}

const listImageStyle = {
	width: '145px',
	minWidth: '145px',
	height: '83px',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center',
	marginRight: '30px'
}

const listImageOverlayStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
	backgroundColor: 'rgba(0,0,0,.2)',
	color: '#fff'
}

const listTitleContainerStyle = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-start',
	width: '100%',
	marginRight: '20px'
}

const listTitleStyle = {
	margin: '-2px 0 2px',
	width: '307px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left'
}

const listButtonContainerStyle = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	marginRight: '16px'
}

const listButtonStyle = {
	minWidth: '112px',
	padding: '1px 12px 0',
	fontSize: '12px',
	fontFamily: 'Greycliff demibold',
	letterSpacing: '.1px'
}

// component function
export default function AlertReuse(props) {

	const { video, reuseVideos, match, action, updateVideo } = props
	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [opacity, setOpacity] = useState(0)
	const [selecting, setSelecting] = useState()
	const [submitting, setSubmitting] = useState(false)
	const [matchedVideo, setMatchedVideo] = useState({guid:0})
	const [selectedFrames, setSelectedFrames] = useState([])
	const themeId = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? globalState.userData.settings.theme : 0
	const theme = themes[themeId]
	const timeoutIdRef = useRef()
	const scrollRef = useRef()
	const dateOptions = { year:'numeric', month:'short', day:'numeric', hour:'numeric', minute:'numeric' }

	// dynamic styles
	const overlayStyle = {
		position: 'fixed',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '100%',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		opacity: opacity,
		transition: 'opacity .4s',
		backgroundColor: themeId === 0 ? 'rgba(64,64,64,.5)' : 'rgba(0,0,0,.6)',
		zIndex: '9999'
	}

	const messageContainerStyle = {
		position: 'relative',
		width: '740px',
		minWidth: '740px',
		backgroundColor: theme.backgroundColor3,
		borderRadius: '15px',
		boxSizing: 'border-box',
		padding: '37px 50px 48px',
		textAlign: 'center',
		boxShadow: '0px 0px 70px 0px rgba(0,0,0,.1)'
	}

	const btnCloseStyle = {
		position: 'absolute',
		top: '18px',
		right: '19px',
		width: '28px',
		height: '28px',
		backgroundImage:'url(' + theme.btnClose + ')',
		cursor: 'pointer'
	}

	const messageHeadingStyle = {
		marginBottom: '11px',
		fontFamily: 'Greycliff demibold',
		color: theme.textColor
	}

	const messageBodyStyle = {
		marginBottom: '30px',
		fontSize: '18px',
		lineHeight: '26px',
		textAlign: 'center',
		color: theme.textColor
	}

	const listElementStyle = {
		display: 'flex',
		width: '640px',
		height: '83px',
		backgroundColor: theme.backgroundColorPage,
		color: theme.textColor
	}

	const videoContainerStyle = {
		height: '0',
		//backgroundColor: theme.backgroundColorPage,
		transition: 'height .4s',
		transitionTimingFunction: 'ease-out',
		overflow: 'hidden'
	}

	// fade in & set keypress listener
	useEffect(() => {
		timeoutIdRef.current = setTimeout(function() { // delay - otherwise no opacity transition
			setOpacity(1)
		}, 300)
		window.addEventListener('keydown', keyPressed)
		// cleanup on unmount
		return () => { window.removeEventListener('keydown', keyPressed) }
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// handle keypress events
	function keyPressed(e) {
		if (e.keyCode === 27) { // escape
			clearTimeout(timeoutIdRef.current)
			action(false)
		}
	}

	// handle close button click
	function performAction(e) {
		e.nativeEvent.stopImmediatePropagation()
		action(false)
	}

	// scroll list element to top and open scene selector/video
	function selectScenes(i,guid) {
		scrollRef.current = parseInt(document.getElementById('listContainer').scrollTop)
		requestAnimationFrame(() => { updateScrollPos(document.getElementById('listContainer'), i*(83+10)) })
		matchedVideo.guid !== guid && setSelectedFrames([])
		matchedVideo.guid !== guid && getFullMatchedVideo(guid)
		setSelecting(guid)
	}

	// update scroll position
	function updateScrollPos(elem,pos) {
		scrollRef.current = scrollRef.current + (pos-scrollRef.current)/7
		elem.scrollTop = Math.round(scrollRef.current)
		Math.abs(scrollRef.current-pos) >= .5 && requestAnimationFrame(() => { updateScrollPos(elem, pos) })
	}

	// toggle scene selector/video on bar click
	function toggleScenes(e,i,guid) {
		if (e.target.className !== 'fs-button') {
			if (selecting === guid) {
				setSelecting(null)
			} else {
				selectScenes(i,guid)
			}
		}
	}

	// get full matched video object
	function getFullMatchedVideo(guid) {
		context.io.socket.get('/api/v1/organization/video/' + guid, (data, res) => {
			if (res.statusCode === 200) {
				data.objects = data.objects.filter(obj => obj.frameNo < data.frameCount-2) // remove custom objects with frameCount exceeding reduced video length (due to Google not detecting objects in last 3 frames)
				setMatchedVideo(data)
			} else {
				// TODO: error handling
				console.log(res)
			}
		})
	}

	// create array of matching frames
	function matchedFrames(videoId) {
		let arr = []
		for (let i=0; i<Object.keys(match.matchData).length; i++) {
			const matchFound = match.matchData[i][match.matchData[i].findIndex(v => v.indexOf(videoId+':') > -1)]
			matchFound && arr.push(parseInt(matchFound.split(':')[1]))
		}
		return arr
	}

	// submit selected frames to backend
	function submitFramesToCopy(sourceVideo) {
		if (submitting) return
		setSubmitting(true)
		const requestObject = {
			sourceVideoGuid: sourceVideo.guid,
			sourceVideoFrameFilter: selectedFrames.length === 0 ? matchedFrames(sourceVideo.id) : selectedFrames
		}
		// send cue selection data
		context.io.socket.post('/api/v1/video/copy-cues/' + video.guid, requestObject, (data, res) => {
			if (res.statusCode === 200) {
				data.objects = data.objects.filter(obj => obj.frameNo < data.frameCount-2) // remove custom objects with frameCount exceeding reduced video length (due to Google not detecting objects in last 3 frames)
				updateVideo(data)
				setSelectedFrames([])
				updateAnalysisStatus('work-in-progress')
			} else {
				// TODO: error handling
				console.log(res)
				setSubmitting(false)
			}
		})
	}

	// update analysis status
	function updateAnalysisStatus(newStatus) {
		video.frontendState = { ...video.frontendState, status:newStatus }
		// send analysis status
		context.io.socket.post('/api/v1/video/' + video.guid + '?dont_calculate', {frontendState: video.frontendState}, (data, res) => {
			if (res.statusCode === 200) {
				action() // close dialogue
			} else {
				// TODO: error handling
			}
		})
	}

	const messageBody = (reuseVideos.length > 1 ?
		'Flowsam detected scenes in previous videos that match your current video. Do you want to copy the brand cues from these scenes?' :
		'Flowsam detected scenes in a previous video that match your current video. Do you want to copy the brand cues from these scenes?'
	)

 	const list = reuseVideos.map((item,i) =>
		<div key={i}>
			<div style={listElementStyle} onClick={e=>toggleScenes(e,i,item.guid)}>
				<div style={{...listImageStyle, backgroundImage:'url(' + process.env.REACT_APP_GCS_BUCKET_URL + '/' + item.guid + '/thumb.jpg)', backgroundSize:(item.height/item.width) <= .75 ? '155%' : 'cover'}}>
					<div style={listImageOverlayStyle}>
						<h3 style={{fontFamily:'Greycliff demibold', marginBottom:'-7px'}}>{Math.floor(video.perceptualHashMatchData.matches[item.guid].toFixed(2)*100)}%</h3>
						<p>match</p>
					</div>
				</div>
				<div style={listTitleContainerStyle}>
					<h4 style={listTitleStyle}>{item.title}</h4>
					<p>{item.user.name}&nbsp;&nbsp;|&nbsp;&nbsp;{new Date(item.createdAt).toLocaleDateString("en-GB", dateOptions)}</p>
				</div>
				<div style={listButtonContainerStyle}>
					<button className="fs-button" style={{...listButtonStyle, marginBottom:'9px'}} tabIndex="-1" onClick={e=>submitFramesToCopy(item)}>Copy all scenes</button>
					<button className="fs-button" style={listButtonStyle} tabIndex="-1" disabled={selecting===item.guid && selectedFrames.length===0} onClick={selecting===item.guid && selectedFrames.length>0 ? e=>submitFramesToCopy(item) : e=>selectScenes(i,item.guid)}>{selecting===item.guid && selectedFrames.length>0 ? 'Copy selected' : 'Select scenes'}</button>
				</div>
			</div>
			<div style={{...videoContainerStyle, height:selecting===item.guid ? '459px' : '0', marginTop:selecting!==item.guid && i===reuseVideos.length-1 ? '0' : '6px', marginBottom:i===reuseVideos.length-1 ? '0' : '4px'}}>
				{matchedVideo.guid === item.guid ?
					<AlertReuseVideo video={matchedVideo} currentFrame={0} matchedFrames={matchedFrames(item.id)} setSelectedFrames={setSelectedFrames} /> :
					<>
						<div style={{backgroundColor:theme.backgroundColorPage, width:'640px', height:'360px'}} />
						<div style={{backgroundColor:theme.backgroundColorPage, width:'640px', height:'20px', marginTop:'6px'}} />
					</>
				}
			</div>
		</div>
	)

	return (
		<div style={overlayStyle}>
			<div style={messageContainerStyle}>
				<div style={btnCloseStyle} onClick={e=>performAction(e)} />
				<h3 style={messageHeadingStyle}>Reuse brand cues?</h3>
				<p style={messageBodyStyle}>{messageBody}</p>
				<div id="listContainer" style={listContainerStyle}>
					{list}
				</div>
			</div>
		</div>
	)
}
