import React, { useContext }  from 'react'
import { GlobalContext } from './services/globalState'
import UserGuideSection from './components/UserGuideSection'
import { easingFunctions } from './services/easingFunctions'
import { themes } from './themes/themes'

// styles
const containerStyle = {
	width: '100%', // for IE compatibility
	minWidth: '688px',
	maxWidth: '1080px',
	margin: '0 auto',
	padding: '84px 0 20px',
	boxSizing: 'border-box',
	textAlign: 'left',
	fontSize: '18px',
	lineHeight: '26px'
}

const h2Style = {
	marginBottom: '16px'
}

const h3Style = {
	fontFamily: 'Greycliff demibold',
	marginBottom: '15px'
}

const pStyle = {
	marginBottom: '18px'
}

const pStyleDemibold = {
	...pStyle,
	fontFamily: 'Greycliff demibold'
}

const listStyle = {
	paddingLeft: '18px'
}

const indexContainerStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	margin: '0 -15px'
}

const indexBoxStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	boxSizing: 'border-box',
	width: '100%',
	maxWidth: '340px',
	minHeight: '340px',
	backgroundColor: '#39c481',
	borderRadius: '10px',
	margin: '30px 15px 0',
	padding: '28px 25px 30px',
	textAlign: 'center'
}

const indexHeadingStyle = {
	cursor: 'pointer',
	color: '#fff'
}

const indexTxtStyle = {
	fontFamily: 'Greycliff demibold',
	marginTop: '8px',
	lineHeight: '22px',
	cursor: 'pointer',
	color: '#fff'
}

// component function
export default function UserGuide() {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]

	// dynamic styles
	const backgroundStyle1 = {
		padding: '0 50px',
		color: theme.textColor,
		backgroundColor: theme.backgroundColorPage,
		minWidth: '924px'
	}

	const backgroundStyle2 = {
		...backgroundStyle1,
		backgroundColor: theme.backgroundColorPage2
	}

	const infoBoxStyle = {
		...indexBoxStyle,
		alignItems: 'left',
		justifyContent: 'flex-end',
		minHeight: '230px',
		backgroundColor: theme.backgroundColorPage,
		borderRadius: '20px',
		padding: '25px 30px 26px',
		textAlign: 'left'
	}

	// scroll to section
	function goSection(gotoId, ms, offset=79) {
		const start = window.pageYOffset
		const to = start + document.getElementById(gotoId).getBoundingClientRect().top - offset
		const time = Date.now()
		const duration = ms; // animation milliseconds
		(function step() {
			var dx = Math.min(1, (Date.now() - time) / duration)
			var pos = start + (to - start) * easingFunctions.easeInOutQuad(dx)
			window.scrollTo(0, pos)
			dx < 1 && requestAnimationFrame(step)
		})()
	}

	return (
		<>
		<div style={{...backgroundStyle1, paddingBottom:'26px'}}>
			<div style={containerStyle}>
				<h2 style={h2Style}>Flowsam<sup>®</sup> user guide</h2>
				<p style={{...pStyle, maxWidth:'700px', marginBottom:'12px'}}>Here you can find everything you need to know to get started pre-testing and analyzing the brand performance of your video ads. You can read through the whole guide or just jump directly to a section of interest by using the index below.</p>
				<div style={indexContainerStyle}>
					<div style={{...indexBoxStyle, cursor:'pointer'}} onClick={e=>goSection('welcome',650)}>
					<img src={theme.iconIndexIntro} style={{marginBottom:'12px'}} alt="" />
						<h2 style={indexHeadingStyle}>Intro</h2>
					</div>
					<div style={indexBoxStyle}>
						<img src={theme.iconIndexAnalyses} style={{margin:'0 0 8px -1px'}} alt="" />
						<h2 style={indexHeadingStyle} onClick={e=>goSection('analyses',700)}>Analyses</h2>
						<h4 style={indexTxtStyle} onClick={e=>goSection('analyses-list',700,185)}>Video analyses list</h4>
						<h4 style={indexTxtStyle} onClick={e=>goSection('new-analysis',700,59)}>Create new analysis</h4>
						<h4 style={indexTxtStyle} onClick={e=>goSection('analysis-progress',700,59)}>See analysis progress</h4>
					</div>
					<div style={indexBoxStyle}>
					<img src={theme.iconIndexBrandCues} style={{margin:'0 0 4px 16px'}} alt="" />
						<h2 style={indexHeadingStyle} onClick={e=>goSection('brand-cues',750)}>Brand cues</h2>
						<h4 style={indexTxtStyle} onClick={e=>goSection('object-list',750,205)}>The object list</h4>
						<h4 style={indexTxtStyle} onClick={e=>goSection('the-video',750,205)}>The video</h4>
						<h4 style={indexTxtStyle} onClick={e=>goSection('rename-join',800,205)}>Rename &amp; join objects</h4>
						<h4 style={indexTxtStyle} onClick={e=>goSection('finish-analysis',800,205)}>Finish the analysis</h4>
					</div>
				</div>
				<div style={indexContainerStyle}>
					<div style={indexBoxStyle}>
					<img src={theme.iconIndexReport} style={{marginBottom:'10px'}} alt="" />
						<h2 style={indexHeadingStyle} onClick={e=>goSection('report',850)}>Report</h2>
						<h4 style={indexTxtStyle} onClick={e=>goSection('summary',850,197)}>Summary</h4>
						<h4 style={indexTxtStyle} onClick={e=>goSection('exposure',850,205)}>Brand exposure</h4>
						<h4 style={indexTxtStyle} onClick={e=>goSection('attention',850,205)}>Brand attention</h4>
						<h4 style={indexTxtStyle} onClick={e=>goSection('performance',850,205)}>Brand performance</h4>
						<h4 style={indexTxtStyle} onClick={e=>goSection('retention',850,205)}>Retention</h4>
						<h4 style={indexTxtStyle} onClick={e=>goSection('share',850,195)}>Share, edit, download</h4>
					</div>
					<div style={indexBoxStyle}>
					<img src={theme.iconIndexSettings} style={{marginBottom:'12px'}} alt="" />
						<h2 style={indexHeadingStyle} onClick={e=>goSection('settings',900)}>Settings</h2>
						<h4 style={indexTxtStyle} onClick={e=>goSection('account',900,208)}>Account</h4>
						<h4 style={indexTxtStyle} onClick={e=>goSection('user-settings',900,210)}>User settings</h4>
						<h4 style={indexTxtStyle} onClick={e=>goSection('profile',900,210)}>Profile</h4>
					</div>
					<div style={{...indexBoxStyle, cursor:'pointer'}} onClick={e=>goSection('help',950)}>
					<img src={theme.iconIndexHelp} style={{marginBottom:'8px'}} alt="" />
						<h2 style={indexHeadingStyle}>Help</h2>
					</div>
				</div>
				<p style={pStyle}>&nbsp;</p>
			</div>
		</div>
		<div id="welcome" style={backgroundStyle2}>
			<div style={containerStyle}>
				<UserGuideSection image={theme.imgWelcome}>
					<h2 style={h2Style}>Welcome to flowsam<sup>®</sup><br/>Your digital video brand performance pre-testing platform</h2>
					<p style={pStyle}>Flowsam enables you to test and optimize the branded content of your video ads to ensure that your media budget is spent on advertising that actually adds to the bottom line.</p>
					<p style={pStyle}>The basic idea behind flowsam is: Predict which brand cues people will look at and calculate a score based on importance, exposure, time and attention density. That’s exactly what our AI is doing.</p>
					<p style={pStyle}>The process is quite simple (even though a lot of advanced stuff is going on under the hood):</p>
					<ol>
						<li style={pStyle}>Upload your video and let flowsam analyze it</li>
						<li style={pStyle}>Tag your brand cues</li>
						<li style={pStyle}>See (and share) the report</li>
					</ol>
					<p>Below is a detailed walkthrough of the different interfaces and functions in flowsam. Feel free to contact us if you have any comments or questions.</p>
				</UserGuideSection>
			</div>
		</div>
		<div id="analyses" style={backgroundStyle1}>
			<div style={containerStyle}>
				<h2 style={h2Style}>Analyses page</h2>
				<p>&nbsp;</p>
				<UserGuideSection icon={theme.iconAnalysesPage} image={theme.imgAnalysesList} imgTop={0} dir='left' border='darkmode'>
					<h3 id="analyses-list" style={h3Style}>My/team video analyses list</h3>
					<p style={pStyle}>After logging in you are on the Analyses page. If you have previously created video analyses you can see the “My video analyses” (or “Team video analyses”) list at the bottom.</p>
					<p style={pStyle}>Completed (tagged) analyses will display the scores of the analysis in the three categories: Brand exposure, Brand attention, and Brand performance. Clicking a completed analysis will take you to the detailed report page for that analysis.</p>
					<p style={pStyle}>Analyses that still need tagging will display an “Add brand cues” button that will take you to the cue tagging section.</p>
					<p>Analyses that are currently being processed by flowsam will display a progress bar.</p>
				</UserGuideSection>
				<UserGuideSection image={theme.imgAnalysesList2} border='darkmode'>
					<p style={pStyle}>If you have a lot of analyses in the list you can sort the list by date, title, brand or analysis type (if your plan includes animatics), or filter the list to only show analyses where the title, project, brand or user contains what you type in the “Search” field.</p>
					<p>You can delete an analysis or edit its name, project, brand and industry by clicking the three small dots below the analysis thumbnail image. If the analysis has been completed (tagged) you can also go to the cue tagging section from here to edit the brand cue tags.</p>
				</UserGuideSection>
			</div>
		</div>
		<div id="new-analysis" style={backgroundStyle2}>
			<div style={containerStyle}>
				<UserGuideSection icon={theme.iconNewAnalysis} image={theme.imgNewAnalysis} imgTop={117} dir='left' border='darkmode'>
					<h3 style={h3Style}>Create new analysis</h3>
					<p style={pStyle}>At the top of the Analyses page you find the “New video analysis” section. To create a new video analysis:</p>
					<ol style={{...listStyle, marginBottom:'0'}}>
						<li style={pStyle}>Drag the video to the dropzone (or click the dropzone to get a file selection dialogue). The video must be in mp4 format and at least 640px in width or height, any fps. Higher resolutions will increase the chance of succesful object detection. The video may contain audio but this will currently not be used.</li>
						<li style={pStyle}>Select analysis type (Normal video or Animatic). Some plans may not have this option.</li>
						<li style={pStyle}>Fill in a name for the analysis.</li>
						<li style={pStyle}>Select a project from the list of previous projects (or create a new project by typing the project name and pressing return). While typing the list below will only show project names that match what you typed. A project is just a way to group your analyses (by client, campaign etc.).</li>
						<li style={pStyle}>Select a brand from the brand list (or add a brand to the list by typing the brand name and pressing return). While typing the list below will only show brand names that match what you typed.</li>
						<li style={pStyle}>Select an industry suitable for the video’s content. Industry will be used for your own and global benchmarking.</li>
						<li>Click the “Start analysis” button.</li>
					</ol>
				</UserGuideSection>
			</div>
		</div>
		<div id="analysis-progress" style={backgroundStyle1}>
			<div style={containerStyle}>
				<UserGuideSection icon={theme.iconAnalysisProgress} image={theme.imgAnalysisProgress} imgTop={77} dir='left' border='lightmode'>
					<h3 style={h3Style}>See analysis progress</h3>
					<p style={pStyle}>When the analysis har started you can watch the progress of the processes in the analysis. After uploading to flowsam, the video will be processed in different areas of eye tracking prediction and object detection. If the uploaded video exceeds your subscription limitations (analysis count, size, video max length) you will get a warning and the analysis will be terminated. Please contact your team manager if this occurs.</p>
					<p style={pStyle}>This part of the analysis will typically take 2-5 minutes depending on video length and complexity. If you have more videos to analyze you don’t have to wait for the current analysis to finish. Just click the “New analysis” link below to get back to the “New video analysis” section. You can still follow the progress of ongoing analyses in the “My/Team video analyses” list below.</p>
					<p>When the analysis has completed you can continue to the next part of the process by clicking the “Add brand cues” button.</p>
				</UserGuideSection>
			</div>
		</div>
		<div id="brand-cues" style={backgroundStyle2}>
			<div style={containerStyle}>
				<h2 style={h2Style}>Tag your brand cues</h2>
				<p style={{...pStyle, maxWidth:'710px'}}>This is the most important step in the analysis and the part where your brand insights come into play. <span style={pStyleDemibold}>Be prepared to invest some time to do this as thoroughly as possible</span>.</p>
				<p style={{...pStyle, maxWidth:'710px', marginBottom:'12px'}}>Flowsam has recognized some objects in the video. It is now your task to tell flowsam which of these are important brand elements (cues). Each brand related object must be tagged as one of six brand cue types (these may be named differently in your organization’s flowsam account):</p>
				<div style={indexContainerStyle}>
					<div style={infoBoxStyle}>
						<p style={{...pStyleDemibold, marginBottom:'4px'}}>Category</p>
						<p>Indirect brand cues, objects that give association to the brand and/or product.</p>
					</div>
					<div style={infoBoxStyle}>
						<p style={{...pStyleDemibold, marginBottom:'4px'}}>Product</p>
						<p>Recognizable product in packaging or otherwise clearly branded.</p>
					</div>
					<div style={infoBoxStyle}>
						<p style={{...pStyleDemibold, marginBottom:'4px'}}>Comms</p>
						<p>Text that is brand related like a campaign slogan, payoff, benefits etc.</p>
					</div>
				</div>
				<div style={indexContainerStyle}>
					<div style={infoBoxStyle}>
						<p style={{...pStyleDemibold, marginBottom:'4px'}}>Logo</p>
						<p>The brand’s logo and logotype. This is the strongest brand cue so don’t miss tagging these.</p>
					</div>
					<div style={infoBoxStyle}>
						<p style={{...pStyleDemibold, marginBottom:'4px'}}>Brand ref (hi)</p>
						<p>Cues that don’t fit the above but have a strong brand association, like a mascot etc.</p>
					</div>
					<div style={infoBoxStyle}>
						<p style={{...pStyleDemibold, marginBottom:'4px'}}>Brand ref (lo)</p>
						<p>Cues that don’t fit the above but have a weaker brand reference. I.e. recurring characters or elements that haven’t (yet) established broader recognition.</p>
					</div>
				</div>
				<p style={pStyle}>&nbsp;</p>
				<p style={pStyle}>Tagging can be done in two ways - either in the list of detected objects or directly in the video.</p>
				<p style={pStyle}>&nbsp;</p>
				<UserGuideSection icon={theme.iconObjectList} image={theme.imgObjectList} imgTop={37} dir='left' border='darkmode'>
					<h3 id="object-list" style={h3Style}>The object list</h3>
					<p style={pStyle}>The detected objects in the list have automatically been sorted into different categories, representing what flowsam believes the object to be. Sometimes, depending on image quality, the category may not be a perfect match, but don’t worry - this will not affect the result of the analysis, it’s only for getting an overview of a (sometimes very) long list of objects.</p>
					<p style={pStyle}>Click the name of the category to expand the list of objects. Each thumbnail in the list represents a single object. There can be many objects in one video frame, and one object may span many frames of the video. The thumbnail only displays the first frame in which the object exists.</p>
					<p style={pStyle}>Clicking an object in the list will make the video to the right jump to the corresponding frame and highlight the object to view it in more detail, if needed.</p>
					<p>You can edit the name of an object in the list just by clicking it. Proper object naming can be useful for identifying the tagged objects in the analysis report later on. Object names can be up to 28 characters long. Keep the mouse over the object while editing its name, otherwise it will lose focus.</p>
				</UserGuideSection>
				<UserGuideSection image={theme.imgObjectListTag} border='darkmode'>
					<h3 style={h3Style}>Tag brand cues in the object list</h3>
					<p style={pStyle}>To tag the object as a brand cue click the one of the six options - Cat., Prod., Comms, Logo, Brand ref (hi), Brand ref (lo) - that best matches the object’s brand value. The object border will turn orange both in the list and in the video to indicate it has been tagged. You can also remove the tag by clicking the same option once more.</p>
					<p style={pStyle}>Continue doing this through the whole list and remember - not all objects need to be tagged, only those that are brand related. If you for some reason want to delete an object from the list, just hit the backspace key.</p>
					<p style={pStyle}>As soon as at least one element has been tagged you will be able to finish the analysis. But it is recommended that you tag every brand related object to get a correct brand performance score.</p>
					<p>Be aware that none of your edits/tagging (except adding new objects) will be saved until you click the “Finish analysis” button. This also means that you can always revert to the saved state of the cue tagging just by reloading the page.</p>
				</UserGuideSection>
				<UserGuideSection icon={theme.iconTheVideo} image={theme.imgVideoOptions} imgTop={44} dir='left' border='darkmode'>
					<h3 id="the-video" style={h3Style}>The video</h3>
					<p style={pStyle}>The video to the right is quite versatile and offers some advanced options you don’t have with the object list. You can play/pause the video, and drag the play button as you would expect with a regular video player.</p>
					<p style={pStyle}>The gear icon below the video reveals extra options for playback speed and showing/hiding tagged and untagged objects, labels and cue types.</p>
					<p><span style={pStyleDemibold}>Clicking the zoom icon to the left of the gear icon will hide the object list and display a larger video where you can also do all the tagging</span>.</p>
				</UserGuideSection>
				<UserGuideSection image={theme.imgVideoSelected} border='darkmode'>
					<p style={pStyle}>The toolbar to the right of the video has these options:</p>
					<ul style={{...listStyle, marginBottom:'20px'}}>
						<li style={pStyle}><span style={pStyleDemibold}>Edit</span> - select/deselect object</li>
						<li style={pStyle}><span style={pStyleDemibold}>Draw & detect</span> - draw a rectangle around a brand cue to make flowsam auto-detect it</li>
						<li style={pStyle}><span style={pStyleDemibold}>Draw</span> - draw a rectangle to create a new object that spans the current and all subsequent video frames to manipulate with the advanced options.</li>
						<li style={pStyle}><span style={pStyleDemibold}>Join</span> - combine objects (telling flowsam they represent the same element)</li>
					</ul>
					<p>Clicking the border or label of an object will highlight it in the video (and also in the list). When an object is selected a small bar below the video will indicate the part(s) of the video’s timeline in which the object is present.</p>
				</UserGuideSection>
				<p style={{...pStyle, marginBottom:'12px'}}>There are some useful keyboard shortcuts that work independently of the selected tool:</p>
				<div style={indexContainerStyle}>
					<div style={infoBoxStyle}>
						<img src={theme.iconSpaceBar} style={{width:'16px', marginBottom:'14px'}} alt="Spacebar" />
						<p style={{...pStyleDemibold, marginBottom:'4px'}}>Spacebar</p>
						<p>Play/pause video.</p>
					</div>
					<div style={infoBoxStyle}>
						<img src={theme.iconArrowKeys} style={{width:'27px', marginBottom:'14px'}} alt="Left/right arrow keys" />
						<p style={{...pStyleDemibold, marginBottom:'4px'}}>Left/right arrow keys</p>
						<p>Step through the video frame by frame.</p>
					</div>
					<div style={infoBoxStyle}>
						<img src={theme.iconShiftKey} style={{width:'16px', marginBottom:'14px'}} alt="Shift key" />
						<p style={{...pStyleDemibold, marginBottom:'4px'}}>Shift key</p>
						<p>Clicking another object than the one selected will join/merge the objects.</p>
					</div>
				</div>
				<div style={indexContainerStyle}>
					<div style={infoBoxStyle}>
						<img src={theme.iconPlusKey} style={{width:'16px', marginBottom:'14px'}} alt="Plus key" />
						<p style={{...pStyleDemibold, marginBottom:'4px'}}>Plus key</p>
						<p>Holding down the plus key allows detecting a new object by drawing a rectangle around the element in the video.</p>
					</div>
					<div style={infoBoxStyle}>
						<img src={theme.iconBackSpace} style={{width:'16px', marginBottom:'14px'}} alt="Backspace" />
						<p style={{...pStyleDemibold, marginBottom:'4px'}}>Backspace</p>
						<p>Delete the selected object from all frames in the video.</p>
					</div>
					<div style={infoBoxStyle}>
						<img src={theme.iconEscapeKey} style={{width:'22px', marginBottom:'14px'}} alt="Escape" />
						<p style={{...pStyleDemibold, marginBottom:'4px'}}>Escape</p>
						<p>Hide the options menu or deselect a selected object in the video.</p>
					</div>
				</div>
				<p style={pStyle}>&nbsp;</p>
				<p style={pStyle}>&nbsp;</p>
				<UserGuideSection image={theme.imgVideoTag} border='darkmode'>
					<h3 style={h3Style}>Tag brand cues in the video</h3>
					<p style={pStyle}>Go through the video by dragging the play button or using the left/right arrow keys. When you see an object that is brand related click its border or label. A menu with the six tagging options (Category, Product, Comms, Logo, Brand ref (hi), Brand ref (lo)) will appear.</p>
					<p style={pStyle}>Click the option that best matches the object’s brand value. The menu closes and the object border turns orange to indicate it has been tagged. You can remove the tag again by selecting the “Not tagged” option.</p>
					<p>While the object is selected you can edit its name directly in the label.</p>
				</UserGuideSection>
				<UserGuideSection image={theme.imgVideoTagAdvanced} dir='left' border='darkmode'>
				<p style={pStyle}>Clicking the three dots to the right will reveal some advanced options:</p>
				<ul style={{...listStyle, marginBottom:'-160px'}}>
					<li style={pStyle}>Tween the object in selected frame range. Go to the start frame of the tween. Click the Tween icon (two boxes with an arrow). The first box in the icon turns red to indicate a start frame has been selected. Go to the end frame of the tween and click the icon once more. The object’s size and position will now be distributed evenly between the start- and end frame. If the object wasn’t present in some of the intermediate frames, it will be added to those frames. You can’t tween two different objects - if you need that, join them into one object first with the join tool.</li>
					<li style={pStyle}>Delete the object from current and preceding frames</li>
					<li style={pStyle}>Delete the object from current and subsequent frames</li>
					<li style={pStyle}>Delete the object from all frames in the video</li>
				</ul>
				</UserGuideSection>
				<UserGuideSection image={theme.imgVideoDraw} imgTop={164} border='darkmode'>
					<p style={pStyle}>Dragging the handle in the upper left side will move the object (in current frame only) and dragging the handle in the lower right side will resize the object (in current frame only). Using these tools you can fine-tune the size and position of an object to get a more precise result. You don’t need to spend a lot of time getting object positions pixel-precise but if they’re “straying” you can fix it.</p>
					<p style={pStyle}>If flowsam missed detecting an important brand related element in the video you can select the “draw & detect” tool in the toolbar (or hold down the plus-key) and draw a rectangle around it. Flowsam will then auto-detect the object in the current and adjacent frames and draw a border around it that you can use for tagging. The precision may vary depending on how much object features and backgrounds change between frames.</p>
					<p>You can also draw a rectangle around a brand cue manually with the “draw” tool. This will create a new object that spans the current and all subsequent frames which you can edit with some of the advanced options.</p>
				</UserGuideSection>
				<UserGuideSection icon={theme.iconRenameJoin} image={theme.imgGroupByCue} imgTop={70} dir='left' border='darkmode'>
					<h3 id="rename-join" style={h3Style}>Rename and join objects</h3>
					<p style={pStyle}>When you’re satisfied that you have tagged all brand related objects you can click the “Group by: Cues” button at the top of the object list (you may need to minimize the video to see the list if you worked with the large-size video).</p>
					<p>This will sort the list by the six tagging categories giving you an overview of all the tagged objects. Now you can refine your tagging by joining objects that represent the same brand element (i.e. instances of the same logo), and rename the tagged objects for easier identification in the report.</p>
				</UserGuideSection>
				<UserGuideSection image={theme.imgJoinDialogue}>
					<p style={pStyle}>Joining objects can be done in two ways:</p>
					<ul style={{...listStyle, marginBottom:'0'}}>
						<li style={pStyle}>Select the “join” tool in the toolbar. Select an object in the list or the video (if one is not already selected). Click on another object you want to join with the first.</li>
						<li style={pStyle}>Select an object in the list or the video. Then shift-click another object you want to join with the first.</li>
					</ul>
					<p>The second object will inherit the name and tagging of the first object. </p>
				</UserGuideSection>
				<UserGuideSection icon={theme.iconFinishAnalysis} image={theme.imgFinishAnalysis} imgTop={119} dir='left' border='darkmode'>
					<h3 id="finish-analysis" style={h3Style}>Finish the analysis</h3>
					<p style={pStyle}>When tagging is done click the “Finish analysis” button. Flowsam will now calculate the brand scores based on your tagged objects and the eye tracking prediction performed earlier in the process. When calculations are done you will automatically continue to the flowsam report.</p>
					<p style={pStyle}>Don’t worry about having missed tagging an object - you can always go back and adjust your tagging and object naming.</p>
				</UserGuideSection>
			</div>
		</div>
		<div id="report" style={backgroundStyle1}>
			<div style={containerStyle}>
				<h2 style={h2Style}>The report</h2>
				<p style={{...pStyle, maxWidth:'720px', marginBottom:'52px'}}>Each completed analysis has a report page showing the brand scores of the video and details on how the different brand cues (tagged objects) perform. This is where you get useful insights about objects and areas in the video that could be further optimized.</p>
				<UserGuideSection icon={theme.iconSummary} image={theme.imgSummary} imgTop={/*10*/3} border='darkmode'>
					<h3 id="summary" style={h3Style}>Summary</h3>
					<p style={pStyle}>The Summary section shows the overall scores in three categories: Brand exposure, Brand attention, and Brand performance (see details below). Each can have a score between 0 and 100. The bar in the circle is red if the value is below 20, yellow if the value is between 20 and 40, and green if the value is above 40. Clicking on a circle will scroll the page to the details section of that category.</p>
					{/*<p>The smaller circles show the norm/average values for analyses created by yourself and others within the same industry (if your subscription includes this option). This way you can benchmark your work.</p>*/}
					<p>The smaller circles show the norm/average values for all your analyses within the same industry. This way you can benchmark your work.</p>
				</UserGuideSection>
				<UserGuideSection icon={theme.iconExposure} image={theme.imgExposure} dir='left' border='lightmode'>
					<h3 id="exposure" style={h3Style}>Brand exposure</h3>
					<p style={pStyle}>Represents a brand object’s <span style={pStyleDemibold}>potential</span> of being seen/perceived. Cue type, time exposed, placement and size matters. Mere presence will have an effect even if the object doesn’t get attention focus. The summary score (circle) is a calculation of all the brand objects’ total exposure.</p>
					<p style={pStyle}>In the video below you can see all the objects’ size and position over time. The graph below the video shows the brand exposure score for each individual frame. This can help you identify parts/scenes of the video with low brand exposure that may be optimized, increasing the potential for brand attention.</p>
					<p style={pStyle}>The gear icon reveals extra options for playback speed and showing/hiding the objects (cues), labels, scores, cue types and attention heatmap.</p>
					<p>The bars (list of objects) below show how much exposure each of the tagged cues/objects get. This will help you identify important objects that may need more exposure. The list can in some cases be quite long so it only shows the ten highest scoring objects, but it can be expanded to show all objects. The list can also be sorted by cue type. <span style={pStyleDemibold}>Click a bar to make the video go to the first frame containing that cue</span>.</p>
				</UserGuideSection>
				<UserGuideSection icon={theme.iconAttention} image={theme.imgAttention} imgTop={0} border='darkmode'>
					<h3 id="attention" style={h3Style}>Brand attention</h3>
					<p style={pStyle}>Flowsam predicts where people will look in each frame of the video. So by knowing which objects are important and what kind of object they are, flowsam can calculate how much attention each object gets and how much brand attention each frame contains. The summary score (circle) indicates a total of how much people will look at your important brand elements.</p>
					<p style={pStyle}>In the video below you can see a heatmap of the predicted attention and how much of it each brand object gets. This can help you identify brand objects that don’t get enough attention. The graph below the video shows the brand attention score for each individual frame. This can help you identify parts/scenes of the video with low brand attention that may be optimized.</p>
					<p style={pStyle}>The gear icon reveals extra options for playback speed and showing/hiding the objects (cues), labels, scores, cue types and attention heatmap.</p>
					<p>The bars (list of objects) below show how much attention each of the tagged cues/objects get. This will help you identify important objects that may need more attention. The list can in some cases be quite long so it only shows the ten highest scoring objects, but it can be expanded to show all objects. The list can also be sorted by cue type. <span style={pStyleDemibold}>Click a bar to make the video go to the first frame containing that cue</span>.</p>
				</UserGuideSection>
				<UserGuideSection icon={theme.iconPerformance} image={theme.imgPerformance} imgTop={0} dir='left' border='lightmode'>
					<h3 id="performance" style={h3Style}>Brand performance</h3>
					<p style={pStyle}>Is the combined value of brand exposure and brand attention. The value is calculated for each frame of the video so areas with low brand performance can be detected and optimized. The summary score (circle) represents the total brand performance of the video.</p>
					<p style={pStyle}>In the video below you can see all the objects over time. The graph below the video shows the brand performance score for each individual frame. This can help you identify parts/scenes of the video with low brand performance that may be optimized (or even cut out).</p>
					<p style={pStyle}>The gear icon reveals extra options for playback speed and showing/hiding the objects (cues), labels, scores, cue types and attention heatmap.</p>
					<p>The bars (list of objects) below show how much each of the tagged cues/objects contribute to the brand performance. This will help you identify important objects that may need optimization of exposure and/or attention. The list can in some cases be quite long so it only shows the ten highest scoring objects, but it can be expanded to show all objects. The list can also be sorted by cue type. <span style={pStyleDemibold}>Click a bar to make the video go to the first frame containing that cue</span>.</p>
				</UserGuideSection>
				<UserGuideSection icon={theme.iconRetention} image={theme.imgRetention} imgTop={0} border='darkmode'>
					<h3 id="retention" style={h3Style}>Retention</h3>
					<p style={pStyle}>Depending on the video’s publishing format and channel some of your audience will stop watching before the video has finished.</p>
					<p style={pStyle}>In the retention section you can see what happens to the video’s brand performance when a standard retention curve is applied – reducing the brand performance score with the percentage of remaining viewers.</p>
					<p style={pStyle}>The graph below the video shows the brand performance of each frame combined with the retention curve. This can help you identify important brand objects in low retention areas that may benefit from being placed earlier in the video.</p>
					<p>The gear icon reveals extra options for playback speed and showing/hiding the objects (cues), labels, scores, cue types and attention heatmap. And as an extra option you can turn the effect of the retention curve on and off.</p>
				</UserGuideSection>
				<UserGuideSection icon={theme.iconShare} image={theme.imgShare} imgTop={108} dir='left' border='lightmode'>
					<h3 id="share" style={h3Style}>Share, edit and download</h3>
					<p style={pStyle}>The “Share report” button will generate a unique link to the report page that you can copy and share with others. This link will allow people to see the report without being logged in to flowsam.</p>
					<p style={pStyle}>“Edit brand cues” will take you back to the cue tagging page so you can fine-tune your cue tagging and object names.</p>
					<p>“Download attention video” enables you to download a full-resolution video with the attention heatmap applied. It may take som time to generate this video so if the button isn’t there try refreshing the page. Depending on your subscription this video may contain a flowsam watermark.</p>
				</UserGuideSection>
			</div>
		</div>
		<div id="settings" style={backgroundStyle2}>
			<div style={containerStyle}>
				<h2 style={h2Style}>Account, settings and profile</h2>
				<p style={{...pStyle, maxWidth:'720px', marginBottom:'62px'}}>Clicking the organization logo (or your own portrait if you have a Gravatar account) in the top right corner will open a menu with account/subscription information, flowsam settings and your user profile.</p>
				<UserGuideSection icon={theme.iconAccount} image={theme.imgAccount} imgTop={0}>
					<h3 id='account' style={h3Style}>Account</h3>
					<p style={pStyle}>Here you can see your organization name, subscription info (hover the “i” for details on the number of analyses included, expiration and limitations on film length/size and user seats), and who is your team manager (responsible for subscription upgrades, payment, user management etc.).</p>
					<p style={pStyle}>You can also see how many analyses your team has left until the next renewal/reset. Click your team manager’s name to send an email, i.e. if you have spent all your analyses for this period and need some more.</p>
					<p>If you’re a team manager you can see a "View/edit" button. Clicking this gives you access to administer your flowsam account, upgrade/cancel subscription, change company info, payment method, upload your logo, and download your invoices. Some of these features may not be available if you’re on a custom subscription.</p>
				</UserGuideSection>
				<UserGuideSection icon={theme.iconSettings} image={theme.imgSettings} imgTop={63} dir='left' border='lightmode'>
					<h3 id="user-settings" style={h3Style}>User settings</h3>
					<p style={pStyle}>Here you can switch between having the analyses list show only your own analyses or all (finished) analyses your team has made. You can see reports on analyses made by other team members, but you can only edit and share your own analyses.</p>
					<p>You can also switch between setting the flowsam interface to light or dark mode. And you can set whether you want to receive the flowsam newsletter or not (can also be cancelled on the unsubscribe link in the newsletter).</p>
				</UserGuideSection>
				<UserGuideSection icon={theme.iconProfile} image={theme.imgProfile} imgTop={0} border='lightmode'>
					<h3 id="profile" style={h3Style}>Profile</h3>
					<p style={pStyle}>Here you can change your name, email and lowsam password. Click the “Edit” button and make the changes. Click the green “Save” button and you’re done.</p>
					<p>You can’t change your email address to one that is already in use by another flowsam user. Passwords must be at least 8 characters and can’t contain spaces.</p>
				</UserGuideSection>
			</div>
		</div>
		<div id="help" style={backgroundStyle1}>
			<div style={{...containerStyle, paddingTop:'155px'}}>
				<UserGuideSection icon={theme.iconHelp} image={theme.imgHelp} imgTop={-65} dir='left'>
					<h2 style={h2Style}>Need help?</h2>
					<p style={pStyle}>If you have questions regarding the use of flowsam, get stuck or experience an error, we are happy to help. Any kind of feedback will help us improve our product – and ultimately your digital video ad brand performance.</p>
					<a href="mailto:support@flowsam.ai" style={{...pStyle, color:'#39C481', textDecoration:'none'}}>support@flowsam.ai</a>
				</UserGuideSection>
			</div>
		</div>
		</>
	)
}
