import React, { useState, useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import useCueTypes from '../services/useCueTypes'
import ReportBar from './ReportBar'

// styles
const containerStyle = {
	margin: '30px 0 5px',
	textAlign: 'center'
}

const tickContainerStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '515px',
	margin: '0 auto',
}

const unitContainerStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '613px',
	margin: '0 auto',
	opacity: '.5'
}

const sortContainerStyle = {
	display: 'flex',
	justifyContent: 'center',
	maxWidth: '500px',
	margin: '17px auto -10px'
}

// component function
export default function ReportBarGraph(props) {

	const { video, type, setCurrentFrame } = props
	const [itemsToShow, setItemsToShow] = useState(10)
	const [listSort, setListSort] = useState('score')
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const selectedSegments = video.segments.filter(seg => video.objectSegmentFilter.indexOf(seg.id) > -1) // get selected segments only
	const highestScore = Math.max.apply(Math, selectedSegments.map(function(o) { return (type === 'exposure' ? o.scoreExposure : type === 'attention' ? o.scoreAttention : o.scorePower) })) // highest score of type
	const scaleMax = Math.ceil(highestScore/5)*5 // round graph scale up to nearest 5
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const cueTypes = useCueTypes()
	const sortedSegments = listSort === 'score' ? selectedSegments.sort((a, b) => type === 'exposure' ? b.scoreExposure - a.scoreExposure : type === 'attention' ? b.scoreAttention - a.scoreAttention : b.scorePower - a.scorePower) : // sort by score
												  selectedSegments.sort((a, b) => (cueTypes.findIndex(cueType => cueType.type === a.cueType) - cueTypes.findIndex(cueType => cueType.type === b.cueType)) || (type === 'exposure' ? b.scoreExposure - a.scoreExposure : type === 'attention' ? b.scoreAttention - a.scoreAttention : b.scorePower - a.scorePower)) // sort by cue type - then by score

	// dynamic styles
	const unitStyle = {
		minWidth: '100px',
		textAlign: 'center',
		color: theme.textColor
	}

	const tickStyle = {
		width: '1px',
		height: '4px',
		borderLeft: '1px solid ' + theme.analysisScoreTickColor,
		boxSizing: 'border-box',
		margin: '2px 0'
	}

	const graphHeadingStyle = {
		color: theme.textColor,
		margin: '70px 0 2px'
	}

	const sortButtonStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: theme.textColor,
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		opacity: '.5',
		height: '19px',
		padding: '0 11px',
		cursor: 'pointer',
		transition: 'opacity .2s'
	}

	// show more button mouseOver handler
	function onOver(e) {
		e.currentTarget.style.opacity = '1'
	}

	// show more button mouseOut handler
	function onOut(e) {
		e.currentTarget.style.opacity = '.5'
	}

	// show more button click handler
	function toggleShowMore(e) {
		e.currentTarget.style.opacity = '.5'
		setItemsToShow(itemsToShow < sortedSegments.length ? sortedSegments.length : 10)
	}

	// list sort button click handler
	function toggleListSort(e, cueType) {
		e.currentTarget.style.opacity = '.5'
		setListSort(cueType)
	}

	function barClicked(seg) {
		const obj = video.objects.filter(obj => seg.id === obj.segment).sort((a, b) => a.frameNo - b.frameNo)[0]
		setCurrentFrame({ frame:obj.frameNo + Math.random(), type:type }) // hack to render same frame (if video currentFrame was changed by other means)
	}

	// iterate over sorted segments
	const scoreBars = sortedSegments.slice(0,itemsToShow).map((seg,i) =>
		<div key={i}>
			<div style={{cursor:'pointer'}} title={'Click to go to first frame of “' + seg.name + '”'} onClick={e=>barClicked(seg)}>
				<ReportBar label={seg.name} score={type === 'exposure' ? seg.scoreExposure : type === 'attention' ? seg.scoreAttention : seg.scorePower} max={scaleMax} cueType={cueTypes.find(cue => cue.type === seg.cueType).label} />
			</div>
			{ i < Math.min(itemsToShow, sortedSegments.length) -1 ?
				seg.cueType === sortedSegments[i+1].cueType || listSort === 'score' ?
					<div style={tickContainerStyle}>
						<div style={tickStyle} />
						<div style={tickStyle} />
					</div>
					:
					<div style={{height:'25px'}} />
				:
				null
			}
		</div>
	)

	const headingText = type === 'exposure' ? 'Exposure by brand cue' : type === 'attention' ? 'Attention by brand cue' : 'Performance by brand cue'
	const showMoreText = itemsToShow < sortedSegments.length ? 'Show more' : 'Show less'
	const showMoreButton = sortedSegments.length > 10 && <div style={sortButtonStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={toggleShowMore}>{showMoreText}</div>
	const sortButton = (listSort === 'score' ?
		<div style={sortButtonStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={e=>toggleListSort(e,'cueType')}>Sort by cue type</div> :
		<div style={sortButtonStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={e=>toggleListSort(e,'score')}>Sort by score</div>
	)

	return (
		<div style={containerStyle}>
			<h4 style={graphHeadingStyle}>{headingText}</h4>
			<div style={unitContainerStyle}>
				<div style={unitStyle}>0</div>
				<div style={unitStyle}>{scaleMax}</div>
			</div>
			{scoreBars}
			<div style={sortContainerStyle}>
				{showMoreButton}
				{sortButton}
			</div>
		</div>
	)
}
