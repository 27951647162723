import React, { useState, useEffect, useContext, useRef } from 'react'
import { Prompt } from 'react-router'
import { useHistory } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { APIContext } from '../services/api'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import useCueTypes from '../services/useCueTypes'
import AnalysisCueObject from './AnalysisCueObject'
import AnalysisCueVideo from './AnalysisCueVideo'
import Alert from './Alert'

// styles
const iconStyle = {
	margin: '0 16px -8px -26px'
}

const listContainerStyle = {
	width: '598px',
	margin: '44px 20px 20px',
	float: 'left'
}

const contentWrapperStyle = {
	height: 0,
	overflowY: 'hidden',
	transition: 'height .4s',
	transitionTimingFunction: 'ease-in-out',
	willChange: 'transform'
}

const listHeaderSortStyle = {
	display: 'flex',
	alignItems: 'center'
}

const listContentStyle = {
	display: 'flex',
	flexWrap: 'wrap',
	padding: '0 4px',
	paddingBottom:'5px'
}

const loaderWrapStyle = {
	position: 'relative'
}

const loaderStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: 'calc(100vh - 422px)',
	paddingTop: '20px'
}

// highlight sort link
function onOver(e) {
	e.currentTarget.style.opacity = '1'
}

// dim sort link
function onOut(e) {
	e.currentTarget.style.opacity = '.5'
}

// component function
export default function AnalysisCues(props) {

	const { title, guid } = props.data
	const context = useContext(APIContext)
	const history = useHistory()
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [video, setVideo] = useState()
	const [selectedSort, setSelectedSort] = useState('objects')
	const [selectedObject, setSelectedObject] = useState()
	const [forceUpdate, setForceUpdate] = useState(0)
	const [sectionsOpened, setSectionsOpened] = useState([])
	const [chunksLoaded, setChunksLoaded] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	const [saving, setSaving] = useState(false)
	const [finishing, setFinishing] = useState(false)
	const [submitProgress, setSubmitProgress] = useState(0)
	const [showList, setShowList] = useState(true)
	const [alertTask, setAlertTask] = useState()
	const [videoUpdated, setVideoUpdated] = useState(false)
	const alertObjectRef = useRef(null)
	const chunkArrayRef = useRef([])
	const origSegmentRef = useRef([])
	const objectsToUpdateRef = useRef([])
	const segmentsToAddRef = useRef([])
	const objectsToAddRef = useRef([])
	const objectIdsToDeleteRef = useRef([])
	const segmentIdsToDeleteRef = useRef([])
	const toolSelectedRef = useRef('edit')
	const videoRef = useRef()
	const savedRef = useRef(false)
	const objHeight = video ? Math.floor(video.height/video.width * 170) : 95
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const cueTypes = useCueTypes()

	let reqAnimID = 0
	let counter = 0
	let numChunksLoaded = 0

	// dynamic styles
	const subHeadingStyle = {
		margin: '30px 0 33px',
		color: theme.textColor
	}

	const listHeaderStyle = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '30px',
		padding: '0 14px 0 23px',
		backgroundColor: theme.backgroundColorObjectList
	}

	const listHeaderTextStyle = {
		color: theme.textColor,
		opacity: '.5'
	}

	const listHeaderLabelStyle = {
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		color: theme.textColor,
		opacity: '.5',
		padding: '1px 6px 0 0'
	}

	const sortButtonStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '17px',
		borderRadius: '19px',
		backgroundColor: theme.backgroundColorObjectListSortButton,
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		color: theme.textColor,
		opacity: globalState.userData && globalState.userData.settings && globalState.userData.settings.theme !== 0 ? '.9' : '1',
		padding: '1px 8px 0',
		marginLeft: '2px'
	}

	const sortTextStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '17px',
		color: theme.textColor,
		opacity: '.5',
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		padding: '1px 8px 0',
		marginLeft: '2px',
		cursor: 'pointer',
		transition: 'opacity .2s'
	}

	const listSectionStyle = {
		marginTop: '10px',
		backgroundColor: theme.backgroundColorObjectList
	}

	const sectionHeaderStyle = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0 22px 0 24px',
		height: '48px',
		fontSize: '18px',
		cursor: 'pointer',
		color: theme.textColor
	}

	const sectionArrowStyle = {
		width: '0',
		height: '0',
		paddingRight: '12px',
		borderStyle: 'solid',
		borderWidth: '8px 0 8px 10px',
		borderColor: 'transparent transparent transparent ' + theme.textColor,
		opacity: '.3',
		transformOrigin: '5px 8px',
		transition: 'transform .4s',
		transitionTimingFunction: 'ease-in-out'
	}

	const objectCountStyle = {
		flexGrow: '1',
		textAlign: 'right',
		fontSize: '14px',
		color: theme.textColor,
		opacity: '.5',
	}

	const tipsStyle = {
		color: theme.textColor,
		opacity: globalState.userData && globalState.userData.settings && globalState.userData.settings.theme !== 0 ? '.5' : '.7',
		marginTop: '14px'
	}

	const sumbitLoaderStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '48px',
		padding: showList ? '40px 0 0' : '21px 0 0'
	}

	const buttonStyle = {
		margin: showList ? '40px 15px 0' : '21px 15px 0'
	}

	const loaderPctStyle = {
		position: 'absolute',
		top:0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: 'calc(100vh - 422px)',
		paddingTop: '52px',
		color: theme.textColor
	}

	const submitLoaderPctStyle = {
		width:'100%',
		height: '20px',
		marginBottom: '-20px',
		color: theme.textColor
	}


	// get video data on mount, guid change or history change
	useEffect(() => {
		videoRef.current = null
		setVideo(videoRef.current)
		setSectionsOpened([])
		setChunksLoaded(false)
		setSelectedObject()
		chunkArrayRef.current = []
		origSegmentRef.current = []
		context.io.socket.get('/api/v1/video/' + guid, (data, res) => {
			if (res.statusCode === 200) {
				if (data.guid) {
					data.objects = data.objects.filter(obj => obj.frameNo < data.frameCount-2) // remove custom objects with frameCount exceeding reduced video length (due to Google not detecting objects in last 3 frames)
					origSegmentRef.current = data.segments.map(seg => ({...seg})) // deep clone segment array for change reference
					videoRef.current = data
					setVideo(videoRef.current)
				} else {
					history.push('/notfound')
				}
			} else {
				// TODO: error handling
			}
		})
	}, [context.io.socket, guid, history])

	// subscribe to events for this analysis
	useEffect(() => {
		context.io.socket.post('/api/v1/user/subscribe', { roomName: 'video-' + guid }, (data, res) => {
			if (res.statusCode !== 200) {
				// TODO: handle error
			}
		})
	}, [context.io.socket, guid])

	// scroll listener on mount
	useEffect(() => {
		window.addEventListener('scroll', positionVideoOnScroll)
 		return () => window.removeEventListener('scroll', positionVideoOnScroll) // cleanup on unmount
	}, [])

	// conditional window unload listener
	useEffect(() => {
		hasChanges && !submitted ? window.addEventListener('beforeunload', leaveWarning) : window.removeEventListener('beforeunload', leaveWarning)
 		return () => window.removeEventListener('beforeunload', leaveWarning) // cleanup on unmount
	})

	// set tool (received from video component)
	function setTool(tool) {
		toolSelectedRef.current = tool
	}

	// refresh video w new objects and segment after detecting custom object
	function refreshVideo(data) {
		savedRef.current = true
		const segment = {...data}
		delete segment.objects
		video.segments.push(segment)
		video.objects.push(...data.objects) // add received objects array to video objects array
		origSegmentRef.current.push({...segment}) // clone segment - not reference
		const selObj = video.objects.find(obj => obj.segment === segment.id)
		setSelectedObject({ ...selObj, render:Math.random() }) // added random 'render' property to force video frame render
	}

	// position video group when scrolling
	function positionVideoOnScroll() {
		const lc = document.getElementById('lcontainer')
		const vc = document.getElementById('vcontainer')
		if (lc && vc) {
			const diff = lc.clientHeight - vc.clientHeight
			if (diff > 0) { // if list column is taller than video column
				const pos_top = window.pageYOffset
				if (pos_top > 300 + diff) {
					vc.style.position = 'static'
					vc.style.marginLeft = '20px'
					vc.style.marginTop = diff+64+'px'
				} else if (pos_top > 280) {
					vc.style.position = 'fixed'
					vc.style.top = '81px'
					vc.style.marginLeft = '658px';
					vc.style.marginTop = '44px'
				} else {
					vc.style.position = 'static'
					vc.style.marginLeft = '20px'
					vc.style.marginTop = '44px'
				}
			} else {
				vc.style.position = 'static'
				vc.style.marginLeft = '20px'
				vc.style.marginTop = '44px'
			}
		}
	}

	// warning before leaving if segment has changes
	function leaveWarning(e) {
		e.preventDefault()
		e.returnValue = 'If you leave now your cue tagging and name changes will be lost'
	}

	// open/close list section
	function toggleSection(e) {
		let sectionsOpenedArray = sectionsOpened.slice()
		const idx = sectionsOpenedArray.indexOf(e.currentTarget.id)
		idx === -1 ? sectionsOpenedArray.push(e.currentTarget.id) : sectionsOpenedArray.splice(idx, 1) // add/remove section id
		setSectionsOpened(sectionsOpenedArray)
		cancelAnimationFrame(reqAnimID)
		reqAnimID = requestAnimationFrame(positionVideo)
	}

	// position video group while transitioning cue section height
	function positionVideo() {
		positionVideoOnScroll()
		if (counter++ > 25) {
			cancelAnimationFrame(reqAnimID)
		} else {
			reqAnimID = requestAnimationFrame(positionVideo)
		}
	}

	// set video frame to match clicked cue object or join two cue objects
	function goToFrame(e,obj) {
		const sameSegment = selectedObject && obj.segment === selectedObject.segment
		const join = selectedObject && selectedObject.segment && !selectedObject.hide && !sameSegment && (e.shiftKey || toolSelectedRef.current === 'join')
		if (obj.segment && join) {
			// join cue objects
			const selSegment = video.segments.find(seg => seg.id === selectedObject.segment)
			const addSegment = video.segments.find(seg => seg.id === obj.segment)
			alertObjectRef.current = { type:'confirm', title:'Join “' + selSegment.name + '” and “' + addSegment.name + '”? The name and tagging of “' + selSegment.name + '” will be preserved.', actionLabel:'Join' }
			setAlertTask(()=>(action)=>{ // define alert action and display alert
				if (action) {
					const objectsToMove = video.objects.filter(o => o.segment === obj.segment) // find all objects in segment to move
					objectsToMove.map(o => {
						// change segment id of all objects to move to the id of selected segment
						o.segment = selectedObject.segment
						// maintain array of objects to update when saving
						if (o.id > -1) { // if not a new drawn object
							if (selSegment.id < 0) { // old object moving to new drawn segment
								objectIdsToDeleteRef.current.push(o.id) // push old object id to objects delete array
								o.id = Math.min(...objectsToAddRef.current.map(o => o.id), 0) - 1 // assign next lowest new id to old object
								objectsToAddRef.current.push(o) // push object to add-array
								const uoidx = objectsToUpdateRef.current.findIndex(obj => obj.id === o.id)
								uoidx > -1 && objectsToUpdateRef.current.splice(uoidx,1) // remove old object from objects to update array, if present
							} else { // push to objects update array
								const objInUpdateArray = objectsToUpdateRef.current.find(updObj => updObj.id === o.id)
								objInUpdateArray ? objInUpdateArray.segment = o.segment : objectsToUpdateRef.current.push(o) // update or add to update-array
							}
						}
						return 0
					})
					// cleanup
					addSegment.id > -1 && segmentIdsToDeleteRef.current.push(addSegment.id) // add moved empty segment id to array of segment ids to delete when saving if not a new drawn segment
					if (addSegment.id < 0) {
						const asidx = segmentsToAddRef.current.findIndex(seg => seg.id === addSegment.id)
						segmentsToAddRef.current.splice(asidx,1) // remove new drawn segment from segments to add array
					}
					if (selSegment.id > -1) { // flag segment for updating if not new drawn segment
						selSegment.dirty = true
					}
					video.segments = video.segments.filter(seg => seg.id !== addSegment.id) // remove moved empty segment from segments array
				}
				setAlertTask() // remove alert
			})
		} else {
			// select or deselect cue object
			const selObj = (selectedObject && obj.id === selectedObject.id ?
				{ frameNo:selectedObject.frameNo, hide:true } : // object is already selected - deselect it
				{ ...obj, render:Math.random() } // select object (added random 'render' property to force render even if same cue object is clicked)
			)
			setSelectedObject(selObj)
		}
	}

	// tween objects in segment
	function tweenObjectsInSegment(tweenObjectsRef) {
		const frameObjects = tweenObjectsRef.current.sort((a, b) => a.frameNo - b.frameNo)
		const numFrames = frameObjects[1].frameNo - frameObjects[0].frameNo - 1
		for (let i=0; i<numFrames; i++) {
			// new position and size for next object
			const x = frameObjects[0].x + (frameObjects[1].x-frameObjects[0].x)/(numFrames+1)*(i+1)
			const y = frameObjects[0].y + (frameObjects[1].y-frameObjects[0].y)/(numFrames+1)*(i+1)
			const w = frameObjects[0].w + (frameObjects[1].w-frameObjects[0].w)/(numFrames+1)*(i+1)
			const h = frameObjects[0].h + (frameObjects[1].h-frameObjects[0].h)/(numFrames+1)*(i+1)
			const objectsInFrame = video.objects.filter(obj => obj.segment === frameObjects[0].segment && obj.frameNo === frameObjects[0].frameNo + 1 + i)
			let tweenObject = null
			if (objectsInFrame.length === 0) { // no object found - create new object
				tweenObject = {
					video: video.id,
					id: Math.min(...objectsToAddRef.current.map(o => o.id), 0) - 1, // 1 lower than lowest object id, ensuring a unique negative id
					segment: frameObjects[0].segment,
					frameNo: frameObjects[0].frameNo+1+i
				}
				video.objects.push(tweenObject)
				objectsToAddRef.current.push(tweenObject)
			} else if (objectsInFrame.length === 1) { // one object found
				tweenObject = objectsInFrame[0]
			} else { // multiple objects found - find the one closest to the new position
				tweenObject = objectsInFrame.reduce((obj, o) => Math.abs(obj.x - x) + Math.abs(obj.y - y) < Math.abs(o.x - x) + Math.abs(o.y - y) ? obj : o)
			}
			//update object position and size
			tweenObject.x = x
			tweenObject.y = y
			tweenObject.w = w
			tweenObject.h = h
			// push to update-array if not new drawn object
			tweenObject.id > -1 && objectsToUpdateRef.current.push(tweenObject)
		}
		if (frameObjects[0].segment > -1) { // flag segment for updating if not new drawn segment
			video.segments.find(seg => seg.id === frameObjects[0].segment).dirty = true
		}
		setForceUpdate(forceUpdate+1)
		tweenObjectsRef.current = []
	}

	// create new segment from drawn rectangle
	function createSegment(drawObject) {
		const segmentStartFrame = drawObject.object.frameNo
		const segmentEndFrame = video.frames.length - 2
		const newSegment = {
			video: video.id,
			id: Math.min(...segmentsToAddRef.current.map(o => o.id), 0) - 1, // 1 lower than lowest segment id, ensuring a unique negative id
			name: 'New object',
			entityName: 'Custom object',
			entityId: 'custom-object',
			cueType: null
		}
		segmentsToAddRef.current.push(newSegment)
		video.segments.push(newSegment)
		 // create frame objects
		for (let i=segmentStartFrame; i<=segmentEndFrame; i++) {
			const newFrameObject = {
				video: video.id,
				id: Math.min(...objectsToAddRef.current.map(o => o.id), 0) - 1, // 1 lower than lowest object id, ensuring a unique negative id
				segment: newSegment.id,
				frameNo: i,
				x: drawObject.object.x,
				y: drawObject.object.y,
				w: drawObject.object.w,
				h: drawObject.object.h
			}
			objectsToAddRef.current.push(newFrameObject)
			video.objects.push(newFrameObject)
		}
		setSelectedObject(video.objects.find(o => o.segment === newSegment.id && o.frameNo === segmentStartFrame))
	}

	// delete segment/objects
	function deleteSelectedSegment(segmentId, frameNo, dir) {
		const delSegment = videoRef.current.segments.find(seg => seg.id === segmentId)
		const confirmMessage = (dir === 'left' || dir === 'right' ?
			'Delete “' + delSegment.name + '” from current frame and all ' + (dir === 'left' ? 'preceding' : 'subsequent') + ' frames in the video?' :
			'Delete “' + delSegment.name + '” from all frames in the video?'
		)
		alertObjectRef.current = { type:'confirm', title:confirmMessage}
		setAlertTask(()=>(action)=>{ // define alert action and display alert
			if (action) {
				const segmentObjects = videoRef.current.objects.filter(obj => obj.segment === segmentId)
				const deleteObjects = dir === 'left' || dir === 'right' ? segmentObjects.filter(obj => (dir === 'left' ? obj.frameNo <= frameNo : obj.frameNo >= frameNo)) : segmentObjects
				// delete objects (all or range)
				deleteObjects.map(o => { // iterate all object ids to delete
					segmentId > -1 && objectIdsToDeleteRef.current.push(o.id) // add object id to array of object ids to delete when saving - except for new drawn objects that haven’t been saved
					const oidx = videoRef.current.objects.findIndex(obj => obj.id === o.id)
					videoRef.current.objects.splice(oidx,1) // remove from objects array
					if (segmentId < 0) {
						const aoidx = objectsToAddRef.current.findIndex(obj => obj.id === o.id)
						objectsToAddRef.current.splice(aoidx,1) // remove new drawn object from objects to add array
					}
					return 0
				})
				// delete segment if no objects left
				if (segmentObjects.length === deleteObjects.length) {
					segmentId > -1 && segmentIdsToDeleteRef.current.push(segmentId) // add segment id to array of segment ids to delete when saving - except for new drawn segment that hasn’t been saved
					const sidx = videoRef.current.segments.findIndex(seg => seg.id === segmentId)
					videoRef.current.segments.splice(sidx,1) // remove from segments array
					segmentId > -1 && origSegmentRef.current.splice(sidx,1)
					if (segmentId < 0) {
						const asidx = segmentsToAddRef.current.findIndex(seg => seg.id === segmentId)
						segmentsToAddRef.current.splice(asidx,1) // remove new drawn segment from segments to add array
					}
					setSelectedObject({frameNo:frameNo})
				}
			}
			setAlertTask() // remove alert
		})
	}

	// switch list between showing objects and cues
	function switchListSort(sort) {
		// close all list sections
		setSectionsOpened([])
		// switch list sort
		setSelectedSort(sort)
		// position video section if list height changes
		cancelAnimationFrame(reqAnimID)
		reqAnimID = requestAnimationFrame(positionVideo)
	}

	// cache image chunks
	if (video && !chunksLoaded && !chunkArrayRef.current.length) {
		const tmpImg = new Image()
		tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + guid + '/maps-frames/' + guid + '-chunk' + ("0000000"+chunkArrayRef.current.length).slice(-7) + '.jpg'
		tmpImg.onload = checkLoaded
		chunkArrayRef.current.push(tmpImg)
	}

	// check that all chunks are loaded
	function checkLoaded(e) {
		e.target.onload = null
		if (!document.getElementById('chunk-load-pct')) {
			return
		}
		numChunksLoaded++
		if (numChunksLoaded === video.chunks) {
			setChunksLoaded(true)
		} else {
			document.getElementById('chunk-load-pct').innerHTML = (50*numChunksLoaded/video.chunks).toFixed(0) + '%'
		}
		if (chunkArrayRef.current.length < video.chunks) {
			const tmpImg = new Image()
			tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames/' + video.guid + '-chunk' + ("0000000"+chunkArrayRef.current.length).slice(-7) + '.jpg'
			tmpImg.onload = checkLoaded
			chunkArrayRef.current.push(tmpImg)
		}
	}

	// submit selected cues to backend
	function submitCues(e,save) {
		save ? setSaving(true) : setFinishing(true)
		const selectedSegIds = video.segments.filter(seg => seg.cueType !== null).map(seg => seg.id)
		const updatedSegs = video.segments.filter(seg => seg.dirty)
		video.frontendState = { ...video.frontendState, status: save ? 'work-in-progress' : 'finished' }
		const videoUpdateRequest = {
			title: video.title,
			segIdsToDelete: segmentIdsToDeleteRef.current,
			objIdsToDelete: objectIdsToDeleteRef.current,
			segsToUpdate: updatedSegs,
			segsToAdd: segmentsToAddRef.current,
			objsToUpdate: objectsToUpdateRef.current,
			objsToAdd: objectsToAddRef.current,
			objectSegmentFilter: selectedSegIds,
			project: video.project && video.project.id,
			frontendState: video.frontendState
		}
		// send cue selection data
		context.io.socket.post('/api/v1/video/' + guid + (save ? '?dont_calculate' : ''), videoUpdateRequest, (data, res) => {
			if (res.statusCode === 200) {
				if (save) {
					// reset after save
					objectsToAddRef.current = []
					objectsToUpdateRef.current = []
					objectIdsToDeleteRef.current = []
					segmentIdsToDeleteRef.current = []
					segmentsToAddRef.current = []
					video.segments.map(seg => seg.dirty = false)
					// re-map segment id on newly created segments
					for (let i=0; i<Object.keys(data.newSegsIdMap).length; i++) {
						const key = Object.keys(data.newSegsIdMap)[i]
						const newId = data.newSegsIdMap[key]
						const mapSegment = video.segments.find(seg => seg.id === parseInt(key))
						if (mapSegment) mapSegment.id = newId
					}
					// re-map object id & segment on newly created objects
					for (let i=0; i<Object.keys(data.newObjIdMap).length; i++) {
						const key = Object.keys(data.newObjIdMap)[i]
						const newId = data.newObjIdMap[key]
						const mapObject = video.objects.find(obj => obj.id === parseInt(key))
						if (mapObject) {
							mapObject.id = newId
							mapObject.segment = data.newObjs.find(obj => obj.id === newId).segment
						}
					}
					origSegmentRef.current = video.segments.map(seg => ({...seg})) // deep clone segment array for change reference
					savedRef.current = true
					setSaving(false)
				} else {
					// listen for succesful verification event
					setSubmitted(true)
					context.io.socket.off('video-recalc-' + guid)
					context.io.socket.on('video-recalc-' + guid, msg => { // TODO: remove listener after receiving data
						history.push('/report/' + guid)
					})
					context.io.socket.off('video-progress-' + guid)
					context.io.socket.on('video-progress-' + guid, msg => { // TODO: remove listener after receiving data
						if (msg.done.statisticsCalc < 100) {
							setSubmitProgress(msg.done.statisticsCalc)
						}
					})
				}
			} else {
				// TODO: error handling
			}
		})
	}

	// update video & reset stuff
	function updateVideo(newVideo) {
		setSectionsOpened([])
		setSelectedObject()
		objectsToAddRef.current = []
		objectsToUpdateRef.current = []
		objectIdsToDeleteRef.current = []
		segmentIdsToDeleteRef.current = []
		segmentsToAddRef.current = []
		origSegmentRef.current = newVideo.segments.map(seg => ({...seg})) // deep clone segment array for change reference
		videoRef.current = newVideo
		savedRef.current = true
		setVideo(videoRef.current)
		setVideoUpdated(true)
	}

	// activate submit button if a segment has been edited and at least one cue is tagged
	const hasChanges = video && (video.segments.filter(seg => seg.dirty).length > 0 || segmentsToAddRef.current.length > 0 || segmentIdsToDeleteRef.current.length > 0 || objectsToAddRef.current.length > 0 || objectsToUpdateRef.current.length > 0 || objectIdsToDeleteRef.current.length > 0)
	const canSubmit = (hasChanges && video.segments.filter(seg => seg.cueType !== null).length > 0)

	// list object/cues sort buttons
	const sortButtons = (selectedSort === 'objects' ?
		<><div title="Sort list by brand cue type" style={sortTextStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={(e)=>switchListSort('cues')}>Cues</div><div title="Sort list by object category"  style={sortButtonStyle}>Objects</div></> :
		<><div title="Sort list by brand cue type"  style={sortButtonStyle}>Cues</div><div title="Sort list by object category" style={sortTextStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={(e)=>switchListSort('objects')}>Objects</div></>
	)

	// submit buttom or beatloader
	const inProgress = video && video.frontendState && video.frontendState.status === 'work-in-progress'
	const submitButtons = (finishing ?
		<div>
			<div style={sumbitLoaderStyle}><BeatLoader color={'#39C481'} /></div>
			<div style={submitLoaderPctStyle}>{submitProgress}%</div>
		</div> :
		<div style={{display:'inline-block', width:'100%'}}>
			<button className="fs-button" style={{...buttonStyle, minWidth:'170px', paddingLeft:'30px', paddingRight:'30px'}} tabIndex="-1" disabled={!hasChanges} onClick={e=>submitCues(e,true)}>{saving ? '\u00A0\u00A0SAVING...' : 'SAVE' + (savedRef.current && !hasChanges ? 'D' : '')}</button>
			<button className="fs-button" style={buttonStyle} tabIndex="-1" disabled={!canSubmit && !inProgress && !videoUpdated} onClick={submitCues}>FINISH ANALYSIS</button>
		</div>
	)

	// get unique content sections
	let distinctEntities
	if (selectedSort === 'objects') {
		distinctEntities = video && [...new Set(video.segments.map(segment => segment.entityName))].sort()
	} else {
		distinctEntities = cueTypes
	}

	// object list content
	const list = (
		chunksLoaded && showList &&
		// chunks loaded - iterate sections
		distinctEntities.map((entity,i) => {

			// get content for each object section
			const segments = (selectedSort === 'objects' ?
				video.segments.filter(seg => seg.entityName === entity) :
				video.segments.filter(seg => seg.cueType === entity.type)
			)

			// list style logic
			const entityName = selectedSort === 'objects' ? entity : entity.label
			const height = Math.ceil(segments.length/3) * (objHeight+60) + (segments.length > 0) * 2 // precise calc to get right height on first render
			const wrapperStyle = sectionsOpened.indexOf(entityName) === -1 ? contentWrapperStyle : { ...contentWrapperStyle, height:height }
			const arrowStyle = sectionsOpened.indexOf(entityName) === -1 ? sectionArrowStyle : { ...sectionArrowStyle, transform: 'rotate(90deg)' }
			const entityTitle = entityName.charAt(0).toUpperCase()+entityName.slice(1)

			// list content
			const listElements = (
				segments.map(seg => {
					// object logic
					const origSeg = origSegmentRef.current.find(orig => orig.id === seg.id)
					const obj = video.objects.find(obj => seg.id === obj.segment) // TODO: investigate - objects without segment?!!!
					const chunkIdx = obj && Math.floor(obj.frameNo/30)
					const chunkImg = obj && chunkArrayRef.current[chunkIdx]
					const focused = obj && selectedObject && obj.segment === selectedObject.segment
					return (obj &&
						<AnalysisCueObject key={obj.id} obj={obj} chunkImg={chunkImg} segment={seg} origSegment={origSeg} focused={focused} goFrame={goToFrame} forceUpdate={e=>setForceUpdate(forceUpdate+1)} />
					)}
				)
			)

			// section id
			const sectionId = selectedSort === 'objects' ? entity : entity.label

			return (
				<div key={i} style={listSectionStyle}>
					<div id={sectionId} style={sectionHeaderStyle} onClick={toggleSection}>
						<div style={arrowStyle} />{entityTitle}<div style={objectCountStyle}>{segments.length} object{segments.length !== 1 && 's'}</div>
					</div>
					<div style={wrapperStyle}>
						<div style={listContentStyle}>
							{listElements}
						</div>
					</div>
				</div>
			)
		})
	)

	// list container
	const listContainer = (showList &&
		<div id="lcontainer" style={listContainerStyle}>
			<div style={listHeaderStyle}>
				<div style={listHeaderTextStyle}>Detected objects</div>
				<div style={listHeaderSortStyle}>
					<div style={listHeaderLabelStyle}>Group by:</div>{sortButtons}
				</div>
			</div>
			{list}
		</div>
	)

	// video canvas section
	const canvas = (chunksLoaded &&
		<AnalysisCueVideo
			chunks={chunkArrayRef.current}
			video={video}
			goFrame={goToFrame}
			toolSelected={setTool}
			selectedObject={selectedObject}
			origSegments={origSegmentRef.current}
			refreshVideo={refreshVideo}
			showObjectList={setShowList}
			tweenObjectsInSegment={tweenObjectsInSegment}
			deleteSelectedSegment={deleteSelectedSegment}
			createSegment={createSegment}
			maxWidth={showList ? 442 : 1080}
 			forceUpdate={e=>setForceUpdate(forceUpdate+1)}
			objectsToUpdate={objectsToUpdateRef.current}
			updateVideo={updateVideo}
	 	/>
	)

	const tips = (showList &&
		<div style={tipsStyle}>
			<p style={{fontFamily:'Greycliff demibold', paddingBottom:'12px'}}>Quick tips:</p>
			<p style={{paddingBottom:'12px'}}>Hold the cursor over an object in the list to view and edit its name. Click an object to jump to its corresponding frame in the video.</p>
			<p style={{paddingBottom:'12px'}}>Play/pause the video with spacebar. For more fine-grained control use arrow-keys to step through each video frame.</p>
			<p style={{paddingBottom:'12px'}}>A highlighted area below the video indicates a section of the video<br/>in which the selected object is present.</p>
			<p style={{paddingBottom:'12px'}}>Click the border of an object in the video to highlight it in the list.</p>
			<p style={{paddingBottom:'12px'}}>If two objects represent the same brand element you can join them by selecting the first and shift-clicking the second.</p>
			<p style={{paddingBottom:'14px'}}>Did flowsam miss an important brand element? Draw a rectangle around it while pressing the plus-key to make flowsam auto-detect it.</p>
		</div>
	)

	// build cue type heading string from cueTypes variables
	let cueTypeString = ''
	cueTypes.filter(cType => cType.type !== null).map((cueType,i) => {
		cueTypeString += "“" + cueType.label + "”" + (i < cueTypes.length-3 ? ', ' : i < cueTypes.length-2 ? ' or ' : '')
		return 0
	})

	const subTxt = (showList ?
		<h4 style={{color:theme.textColor}}>Below is a list of objects detected in the video. Tag the ones that are brand related as either<br />{cueTypeString} to finish the analysis.</h4> :
		<h4 style={{color:theme.textColor}}>Below is a video with objects detected. Tag the ones that are brand related as either<br />{cueTypeString} to finish the analysis.</h4>
	)

	const cueContainerStyle = {
		textAlign: 'left',
		width: showList ? '1120px' : '100%'
	}

	const videoContainerStyle = {
		width: showList ? '442px' : '1080px',
		margin: showList ? '44px 20px 0' : '44px auto 0',
		float: showList ? 'right' : 'none',
		position: showList ? 'inherit' : 'static'
	}

	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// analysis cues content
	const content = (
		// no chunks or video yet - show loader
		!video || !video.guid || !chunksLoaded ?
			<div style={loaderWrapStyle}>
				<div style={loaderStyle}>
					<BeatLoader color={'#39C481'} />
				</div>
				<div id="chunk-load-pct" style={loaderPctStyle}>0%</div>
			</div>
		:
		// we got video and chunks - show content
		<>
			<h2 style={{color:theme.textColor}}><img style={iconStyle} src={theme.iconAnalysis} alt="Analysis icon" />{!title && video ? video.title : title}</h2>
			<h3 style={subHeadingStyle}>Add brand cues</h3>
			{subTxt}
			<div style={cueContainerStyle}>
				{listContainer}
				<div id="vcontainer" style={videoContainerStyle}>
					{canvas}
					{tips}
				</div>
			</div>
			<Prompt when={hasChanges && !submitted} message="If you leave now your cue tagging and name changes will be lost" />
			{submitButtons}
			{alert}
		</>
	)

	return content
}
