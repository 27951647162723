import React, { useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { easingFunctions } from '../services/easingFunctions'
import { themes } from '../themes/themes'
import AnalysisScore from './AnalysisScore'

// styles
const scoresStyle = {
	display: 'flex',
	justifyContent: 'center',
	flexWrap: 'wrap'
}

const normStyle = {
	display: 'flex',
	justifyContent: 'center',
	padding: '43px 0 10px'
}

// component function
export default function ReportSummary(props) {

	const { video, code } = props
	const { scoreExposure:exposure, scoreAttention:attention, scorePower:power } = video
	const { user:userNorm, industry:industryNorm } = props.norms
	const [globalState, setGlobalState] = useContext(GlobalContext) // eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	//const normString = code ? 'User\nnorm' : 'Your\nnorm'
	const normString = code ? 'User norm' : 'Your norm'

	const userExposureNorm = userNorm.find(norm => norm.normType === 1).value
	const userAttentionNorm = userNorm.find(norm => norm.normType === 2).value
	const userPowerNorm = userNorm.find(norm => norm.normType === 3).value
	const industryExposureNorm = industryNorm.find(norm => norm.normType === 4).value
	const industryAttentionNorm = industryNorm.find(norm => norm.normType === 5).value
	const industryPowerNorm = industryNorm.find(norm => norm.normType === 6).value

	// scroll to element
	function goDetails(gotoId, ms) {
		const start = window.pageYOffset
		const to = start + document.getElementById(gotoId).getBoundingClientRect().top - 80
		const time = Date.now()
		const duration = ms; // animation milliseconds
		(function step() {
			var dx = Math.min(1, (Date.now() - time) / duration)
			var pos = start + (to - start) * easingFunctions.easeInOutQuad(dx)
			window.scrollTo(0, pos)
			dx < 1 && requestAnimationFrame(step)
		})()
	}

	return (
		<>
			<h2 style={{color:theme.textColor}}>Summary</h2>
			<div style={scoresStyle}>
				<div>
					<div title="Click for details" style={{cursor:'pointer'}} onClick={e=>goDetails('exposure',700)}>
						<AnalysisScore type="Brand exposure" marg="51px 40px 12px" dia="200" stw="20" fsize="60" lsize="25" score={exposure} />
					</div>
					<div style={normStyle}>
						<AnalysisScore type={normString} marg="10px 9px 1px" score={userExposureNorm} />
						{/*<!AnalysisScore type={'Industry\nnorm'} marg="10px 9px 1px" score={industryExposureNorm} />*/}
					</div>
				</div>
				<div>
					<div title="Click for details" style={{cursor:'pointer'}} onClick={e=>goDetails('attention',1000)}>
						<AnalysisScore type="Brand attention" marg="51px 40px 12px" dia="200" stw="20" fsize="60" lsize="25" score={attention} />
					</div>
					<div style={normStyle}>
						<AnalysisScore type={normString} marg="10px 9px 1px" score={userAttentionNorm} />
						{/*<AnalysisScore type={'Industry\nnorm'} marg="10px 9px 1px" score={industryAttentionNorm} />*/}
					</div>
				</div>
				<div>
					<div title="Click for details" style={{cursor:'pointer'}} onClick={e=>goDetails('power',1300)}>
						<AnalysisScore type="Brand performance" marg="51px 40px 12px" dia="200" stw="20" fsize="60" lsize="25" score={power} />
					</div>
					<div style={normStyle}>
						<AnalysisScore type={normString} marg="10px 9px 1px" score={userPowerNorm} />
						{/*<AnalysisScore type={'Industry\nnorm'} marg="10px 9px 1px" score={industryPowerNorm} />*/}
					</div>
				</div>
			</div>
		</>
	)
}
