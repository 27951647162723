import React, { useState, useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'

// component function
export default function AnalysisCueVideoToolbar(props) {

	const { toolSelect, canvasWidth, maxWidth, reuse } = props
	const [toolSelected, setToolSelected] = useState('edit')
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]

	// dynamic styles
	const toolItemStyle = {
		width: '48px',
		opacity: '.8',
		cursor: 'pointer',
		transition: 'opacity .2s',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '14px 7px',
		textAlign: 'center',
		padding: '31px 0 6px',
		marginBottom: canvasWidth > 640 ? '6px' : '2px',
		fontSize: '12px',
		lineHeight: '13px',
		fontFamily: 'Greycliff demibold',
		color: theme.textColor,
		boxSizing: 'border-box',

	}

	const toolContainerStyle = {
		position: 'absolute',
		right: (maxWidth-canvasWidth)/2 - 58,
		top: '0'
	}

	// highlight tool icon on enter
	function onEnter(e) {
		if (e.currentTarget.id !== toolSelected) {
			e.currentTarget.style.opacity = '1'
		}
	}

	// dim tool icon on leave
	function onLeave(e) {
		if (e.currentTarget.id !== toolSelected) {
			e.currentTarget.style.opacity = '.8'
		}
	}

	// tool selected
	function changeTool(e) {
		e.currentTarget.id !== 'reuse' && setToolSelected(e.currentTarget.id) // local state
		toolSelect(e.currentTarget.id) // parent state
	}

	const editStyle = toolSelected === 'edit' ? { ...toolItemStyle, opacity:'1', cursor:'default', backgroundColor:theme.progressBarBackColor, backgroundImage:'url(' + theme.iconEdit + ')' } : { ...toolItemStyle, backgroundImage:'url(' + theme.iconEdit + ')' }
	const detectStyle = toolSelected === 'detect' ? { ...toolItemStyle, opacity:'1', cursor:'default', backgroundColor:theme.progressBarBackColor, backgroundImage:'url(' + theme.iconDetect + ')' } : { ...toolItemStyle, backgroundImage:'url(' + theme.iconDetect + ')' }
	const drawStyle = toolSelected === 'draw' ? { ...toolItemStyle, opacity:'1', cursor:'default', backgroundColor:theme.progressBarBackColor, backgroundImage:'url(' + theme.iconDraw + ')' } : { ...toolItemStyle, backgroundImage:'url(' + theme.iconDraw + ')' }
	const joinStyle = toolSelected === 'join' ? { ...toolItemStyle, opacity:'1', cursor:'default', backgroundColor:theme.progressBarBackColor, backgroundImage:'url(' + theme.iconJoin + ')' } : { ...toolItemStyle, backgroundImage:'url(' + theme.iconJoin + ')' }
	const reuseStyle = reuse ? { ...toolItemStyle, backgroundImage:'url(' + theme.iconReuse + ')' } : { ...toolItemStyle, opacity:'.35', cursor:'default', backgroundImage:'url(' + theme.iconReuse + ')' }

	const drawDetectTxt = (canvasWidth > 640 ?
		<>draw<span style={{letterSpacing:'-1px'}}> </span>&amp;<br/>detect</> :
		<>detect</>
	)

	return (
		<div style={toolContainerStyle}>
			<div id="edit" title="Tag end edit brand cues" style={editStyle} onClick={changeTool} onMouseEnter={onEnter} onMouseLeave={onLeave}>edit</div>
			<div id="detect" title="Draw a rectangle around a brand cue to make flowsam auto-detect it (shortcut: Plus-key)" style={detectStyle} onClick={changeTool} onMouseEnter={onEnter} onMouseLeave={onLeave}>{drawDetectTxt}</div>
			<div id="draw" title="Draw a rectangle to create a new object that spans the current and all subsequent video frames" style={drawStyle} onClick={changeTool} onMouseEnter={onEnter} onMouseLeave={onLeave}>draw</div>
			<div id="join" title="Join brand cues that represent the same element" style={joinStyle} onClick={changeTool} onMouseEnter={onEnter} onMouseLeave={onLeave}>join</div>
			{/*<div id="reuse" title={reuse ? "Reuse cue tagging from matching scenes in other videos" : "No matching scenes found in other videos"} style={reuseStyle} onClick={reuse ? changeTool : null} onMouseEnter={reuse ? onEnter : null} onMouseLeave={reuse ? onLeave : null}>reuse</div>*/}
		</div>
	)
}
