import React, { useState, useContext } from 'react'
import { APIContext } from '../services/api'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'

// styles
const actionContainerStyle = {
	width: '102px',
	position: 'absolute',
	right: '0',
	bottom: '125px'
}

const actionDotsStyle = {
	width: '3px',
	padding: '9px 10px 7px',
	float: 'right',
	opacity: '.4',
	transition: 'opacity .2s'
}

// component function
export default function AnalysisListItemMenu(props) {

	const { guid, status, forceUpdate, editDetails, setAlertTask, alertObjectRef } = props
	const context = useContext(APIContext)
	const [menuVisible, setMenuVisible] = useState(false)
	const history = useHistory()
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]

	// dynamic styles
	const actionMenuItemStyle = {
		height: '32px',
		paddingLeft: '16px',
		color: theme.textColor,
		backgroundColor: theme.listItemMenuColor,
		borderBottom: '1px solid ' + theme.listItemMenuBorderColor,
		display: 'flex',
		alignItems: 'center',
		boxSizing: 'border-box',
		userSelect: 'none',
		transition: 'background-color .2s'
	}

	const dotStyle = {
		width: '3px',
		height: '3px',
		borderRadius: '3px',
		marginBottom: '3px',
		backgroundColor: theme.listItemMenuDotsColor
	}

	// mouseover on menu item
	function onOver(e) {
		e.currentTarget.style.backgroundColor = theme.listItemMenuHighlightColor
	}

	// mouseout on menu item
	function onOut(e) {
		e.currentTarget.style.backgroundColor = theme.listItemMenuColor
	}

	// mouseover on dots
	function onDotsOver(e) {
		e.currentTarget.style.opacity = '1'
	}

	// mouseout on dots
	function onDotsOut(e) {
		e.currentTarget.style.opacity = '.4'
	}

	// menu item click handler
	function handleMenuAction(e) {
		e.stopPropagation()
		if (e.currentTarget.id === 'edit-cues') {
			history.push('/' + guid)
		} else if (e.currentTarget.id === 'delete') {
			alertObjectRef.current = { type:'confirm', title:'Are you sure you want to delete this analysis?\nThis cannot be undone.' }
			setAlertTask(()=>(action)=>{ // define alert action and display alert
				if (action) {
					context.io.socket.delete('/api/v1/video/' + guid, (data, res) => {
						if (res.statusCode === 200) {
							forceUpdate()
						} else {
							// TODO: error handling
						}
					})
				}
				setAlertTask() // remove alert
			})
		} else {
			editDetails(true)
		}
	}

	// show menu
	function showMenu(e) {
		e.stopPropagation()
		!menuVisible && document.addEventListener('click', hideMenu)
		setMenuVisible(true)
	}

	// hide menu
	function hideMenu(e) {
		e.stopPropagation()
		document.removeEventListener('click', hideMenu)
		setMenuVisible(false)
	}

	// menu hidden visible style
	const actionMenuStyle = {
		visibility: menuVisible ? 'visible' : 'hidden'
	}

	const editMenu = status === 'finished' && <div id="edit-cues" style={actionMenuItemStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={handleMenuAction}>Edit cues</div>
	const detailsMenu = status !== 'analyzing' && <div id="edit-details" style={actionMenuItemStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={handleMenuAction}>Edit details</div>

	return (
		<div style={actionContainerStyle}>
		<div style={actionMenuStyle}>
			{editMenu}
			{detailsMenu}
			<div id="delete" style={actionMenuItemStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={handleMenuAction}>Delete</div>
		</div>
			<div style={actionDotsStyle} onMouseOver={onDotsOver} onMouseOut={onDotsOut} onClick={showMenu}>
				<div style={dotStyle} />
				<div style={dotStyle} />
				<div style={dotStyle} />
			</div>
		</div>
	)
}
