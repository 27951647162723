import React, { useEffect, useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'

// styles
const menuItemStyle = {
	display: 'flex',
	alignItems: 'center',
	marginTop: '11px',
	cursor: 'pointer'
}

const menuItemStyleHidden = {
	display: 'none',
	alignItems: 'center',
	marginTop: '11px',
	cursor: 'pointer'
}

const speedSelectorStyle = {
	display: 'flex',
	alignItems: 'center',
	marginTop: '13px'
}

// highlight speed link
function onOver(e) {
	e.currentTarget.style.opacity = '1'
}

// dim speed link
function onOut(e) {
	e.currentTarget.style.opacity = '.5'
}

// component function
export default function ReportVideoSettings(props) {

	const { reportSettings, setReportSettings, toggleSettings, type } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const speedArray = [1, .5, .25]

	// dynamic styles
	const menuContainerStyle = {
		width: '172px',
		//with focal points:
		//height: type === 'retention' ? '280px' : '251px',
		height: type === 'retention' ? '251px' : '222px',
		position: 'absolute',
		right: '0',
		bottom: '194px',
		padding: '3px 15px',
		boxSizing: 'border-box',
		backgroundColor: theme.backgroundColorVideoSettings
	}

	const labelStyle = {
		marginBottom: '-2px',
		color: theme.textColor
	}

	const speedLabelStyle = {
		height: '19px',
		margin: '0 7px 0 -1px',
		paddingBottom: '1px',
		color: theme.textColor
	}

	const speedButtonStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '34px',
		height: '19px',
		borderRadius: '19px',
		backgroundColor: 'rgba(255,255,255,' + (globalState.userData && globalState.userData.settings && globalState.userData.settings.theme !== 0 ? '.2' : '1') + ')',
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		padding: '1px 0 0',
		color: theme.textColor
	}

	const speedTextStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '34px',
		height: '19px',
		opacity: '.5',
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		padding: '1px 0 0',
		cursor: 'pointer',
		transition: 'opacity .2s',
		color: theme.textColor
	}

	const checkBoxStyle = {
		width: '18px',
		height: '18px',
		border: '4px solid ' + theme.checkboxBackgroundColor,
		backgroundColor: theme.checkboxCheckedColor,
		boxSizing: 'border-box',
		marginRight: '8px',
		transition: 'background-color .2s'
	}

	// set up settings toggle listeners on mount
	useEffect(() => {
		document.addEventListener('mouseup', toggleSettings)
		document.getElementById('menuContainer-'+type).addEventListener('mouseup', ignoreHide)
		// cleanup on unmount
		return () => {
			document.removeEventListener('mouseup', toggleSettings)
			document.getElementById('menuContainer-'+type).removeEventListener('mouseup', ignoreHide)
		}
 	},[toggleSettings, type])

	// ignore hide settings if click is inside settings
	function ignoreHide(e) {
		e.stopPropagation()
	}

	// toggle attention visibility
	function toggleAttention() {
		setReportSettings({...reportSettings, showAttention:!reportSettings.showAttention, showExtremaPoints:(reportSettings.showAttention?reportSettings.showExtremaPoints:false)})
	}

	// toggle extrema points visibility
	function toggleExtremaPoints() {
		setReportSettings({...reportSettings, showExtremaPoints:!reportSettings.showExtremaPoints, showAttention:(reportSettings.showExtremaPoints?reportSettings.showAttention:false)})
	}

	// toggle cue visibility
	function toggleCues() {
		setReportSettings({...reportSettings, showCues:!reportSettings.showCues})
	}

	// toggle label visibility
	function toggleLabels() {
		setReportSettings({...reportSettings, showLabels:!reportSettings.showLabels})
	}

	// toggle cue types visibility
	function toggleCueTypes() {
		setReportSettings({...reportSettings, showCueTypes:!reportSettings.showCueTypes})
	}

	// toggle score visibility
	function toggleScores() {
		setReportSettings({...reportSettings, showScores:!reportSettings.showScores})
	}

	// toggle untagged visibility
	function toggleUntagged() {
		setReportSettings({...reportSettings, showUntagged:!reportSettings.showUntagged})
	}

	// toggle retention effect on graph
	function toggleRetention() {
		setReportSettings({...reportSettings, showRetention:!reportSettings.showRetention})
	}

	// set video playback speed
	function selectSpeed(speed) {
		setReportSettings({...reportSettings, speedSelected:speed})
	}

	const attentionCheckBoxStyle = reportSettings.showAttention ? checkBoxStyle : { ...checkBoxStyle, backgroundColor: theme.checkboxBackgroundColor }
	const extremaPointsCheckBoxStyle = reportSettings.showExtremaPoints ? checkBoxStyle : { ...checkBoxStyle, backgroundColor: theme.checkboxBackgroundColor }
	const cuesCheckBoxStyle = reportSettings.showCues ? checkBoxStyle : { ...checkBoxStyle, backgroundColor:theme.checkboxBackgroundColor }
	const labelsCheckBoxStyle = reportSettings.showLabels ? checkBoxStyle : { ...checkBoxStyle, backgroundColor: theme.checkboxBackgroundColor }
	const cueTypesCheckBoxStyle = reportSettings.showCueTypes ? checkBoxStyle : { ...checkBoxStyle, backgroundColor: theme.checkboxBackgroundColor }
	const scoresCheckBoxStyle = reportSettings.showScores ? checkBoxStyle : { ...checkBoxStyle, backgroundColor: theme.checkboxBackgroundColor }
	const untaggedCheckBoxStyle = reportSettings.showUntagged ? checkBoxStyle : { ...checkBoxStyle, backgroundColor: theme.checkboxBackgroundColor }
	const retentionCheckBoxStyle = reportSettings.showRetention ? checkBoxStyle : { ...checkBoxStyle, backgroundColor: theme.checkboxBackgroundColor }

	const speedButtons = speedArray.map((speed,i) => {
		const speedString = '1/' + (1/speed)
		return (speed === reportSettings.speedSelected ?
			<div key={i} style={speedButtonStyle}>{speedString}</div> :
			<div key={i} style={speedTextStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={(e)=>selectSpeed(speed)}>{speedString}</div>
		)
	})

	const retentionToggle = (type === 'retention' &&
		<div style={menuItemStyle} onClick={toggleRetention}>
			<div style={retentionCheckBoxStyle} />
			<div style={labelStyle}>Retention effect</div>
		</div>
	)

	return (
		<div id={'menuContainer-'+type} style={menuContainerStyle}>
			<div style={menuItemStyle} onClick={toggleAttention}>
				<div style={attentionCheckBoxStyle} />
				<div style={labelStyle}>Attention</div>
			</div>
			<div style={menuItemStyleHidden} onClick={toggleExtremaPoints}>
				<div style={extremaPointsCheckBoxStyle} />
				<div style={labelStyle}>Focal points, 1 sec.</div>
			</div>
			<div style={menuItemStyle} onClick={toggleCues}>
				<div style={cuesCheckBoxStyle} />
				<div style={labelStyle}>Brand cues</div>
			</div>
			<div style={menuItemStyle} onClick={toggleLabels}>
				<div style={labelsCheckBoxStyle} />
				<div style={labelStyle}>Cue labels</div>
			</div>
			<div style={menuItemStyle} onClick={toggleCueTypes}>
				<div style={cueTypesCheckBoxStyle} />
				<div style={labelStyle}>Cue types</div>
			</div>
			<div style={menuItemStyle} onClick={toggleScores}>
				<div style={scoresCheckBoxStyle} />
				<div style={labelStyle}>Cue scores</div>
			</div>
			<div style={menuItemStyle} onClick={toggleUntagged}>
				<div style={untaggedCheckBoxStyle} />
				<div style={labelStyle}>Untagged objects</div>
			</div>
			{retentionToggle}
			<div style={speedSelectorStyle}>
				<div style={speedLabelStyle}>fps:</div>
				{speedButtons}
			</div>
		</div>
	)
}
