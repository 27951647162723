import React, { useEffect, useRef, useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'

// styles
const graphCanvasStyle = {
	width: '838px',
	height: '90px',
	margin: '-151px 0 0 15px',
	display: 'block'
}

// component function
export default function ReportVideoGraph(props) {

	const { video, type, retentionEffect } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const graphCanvasRef = useRef()
	const graphCtxRef = useRef()
	const graphCanvasWidth = 838 * window.devicePixelRatio
	const graphCanvasHeight = 90 * window.devicePixelRatio

	// dynamic styles
	const graphLabelStyle = {
		width: '100%',
		marginTop: '22px',
		textAlign: 'center',
		color: theme.textColor
	}

	// context- & canvas refs & other inits on mount
	useEffect(() => {
		// graph canvas init
		graphCtxRef.current = graphCanvasRef.current.getContext('2d')
		graphCtxRef.current.fillStyle = theme.progressBarColor
		graphCtxRef.current.globalAlpha = 0.75
		drawGraph()
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[retentionEffect])

	// draw graph below video
	function drawGraph() {
		const ctx = graphCtxRef.current
		const step = graphCanvasWidth/(video.frameCount-3)

		// draw graph
		ctx.beginPath()
		ctx.moveTo(0, graphCanvasHeight)
		if (type === 'exposure') {
			video.frames.map((frame,i) => {
				ctx.lineTo(i * step, graphCanvasHeight - frame.thisFrameScoreExposure * graphCanvasHeight/100)
				return 0
			})
		} else if (type === 'attention') {
			video.frames.map((frame,i) => {
				ctx.lineTo(i * step, graphCanvasHeight - frame.thisFrameScoreAttention * graphCanvasHeight/100)
				return 0
			})
		} else if (type === 'power') {
			video.frames.map((frame,i) => {
				ctx.lineTo(i * step, graphCanvasHeight - frame.thisFrameScorePower * graphCanvasHeight/100)
				return 0
			})
		} else if (type === 'retention') {
			ctx.clearRect(0, 0, graphCanvasWidth, graphCanvasHeight)
			if (retentionEffect) { // show brand performance curve with retention effect applied
				const standard = video.retentionFrames.filter(ret => ret.retentionType === 1) // type 1 = standard retention curve
				standard.map((ret,i) => {
					ctx.lineTo(i * step, graphCanvasHeight - ret.thisFrameScorePower * graphCanvasHeight/100)
					return 0
				})
			} else { // show brand performance curve without retention effect applied
				video.frames.map((frame,i) => {
					ctx.lineTo(i * step, graphCanvasHeight - frame.thisFrameScorePower * graphCanvasHeight/100)
					return 0
				})
			}
		}
		ctx.closePath()
		ctx.fill()

		// draw retention curve
		if (type === 'retention') {
			const retData = video.retentionInputData.find(ret => ret.retentionType === 1) // type 1 = standard retention curve
			const time = video.frameCount/video.frameRate
			ctx.lineWidth = 3.5 * window.devicePixelRatio
			ctx.strokeStyle = '#e5a01b'
			ctx.globalAlpha = 1
			ctx.moveTo(0, graphCanvasHeight)
			ctx.beginPath()
			retData.data.x.map((ret,i) => {
				ctx.lineTo(ret/time*graphCanvasWidth, graphCanvasHeight - retData.data.y[i] * (graphCanvasHeight-5))
				return 0
			})
			ctx.lineTo(graphCanvasWidth, graphCanvasHeight - retData.data.y[retData.data.y.length-1] * (graphCanvasHeight-5)) // line to canvas end if movie > 3 minutes
			ctx.stroke()
		}
	}

	const graphLabel =	type === 'exposure' ? 'Brand exposure' :
						type === 'attention' ? 'Brand attention' :
						type === 'power' ? 'Brand performance' :
						retentionEffect ? 'Retention & Brand performance (affected by retention)' :  'Retention & brand performance (not affected by retention)'

	return (
		<>
			<canvas style={graphCanvasStyle} ref={graphCanvasRef} width={graphCanvasWidth} height={graphCanvasHeight} />
			<div style={graphLabelStyle}>{graphLabel}</div>
		</>
	)
}
