import React, { useState, useEffect, useContext, useRef } from 'react'
import { APIContext } from '../services/api'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import { industryOptions } from '../services/industryOptions'
import DropDown from './DropDown'
import DropZone from './DropZone'
import Alert from './Alert'

// styles
const inputContainerStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'stretch',
	padding: '52px 20px 60px'
}

const analysisTypeContainerStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	margin: '-9px 0 14px'
}

const typeSelectionStyle = {
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer'
}

const iconStyle = {
	margin: '0 16px -8px -26px'
}

// component function
export default function AnalysisForm(props) {

	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [projectOptions, setProjectOptions] = useState([])
	const [brandOptions, setBrandOptions] = useState([])
	const [analysisType, setAnalysisType] = useState(0)
	const [title, setTitle] = useState('')
	const [project, setProject] = useState('')
	const [brand, setBrand] = useState('')
	const [industry, setIndustry] = useState('')
	const [video, setVideo] = useState()
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const animaticsAllowed = globalState.userData && globalState.userData.organization && globalState.userData.organization.subscriptionPlan && globalState.userData.organization.subscriptionPlan.config && globalState.userData.organization.subscriptionPlan.config.Animatics_pre_testing_with_same_features_as_above_except_industry_benchmarking
	const analysesExtra = globalState.userData.organization.freeAnalyses ? globalState.userData.organization.freeAnalyses : 0

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// dynamic styles
	const inputStyle = {
		color: theme.textColor,
		backgroundColor: theme.backgroundColor3,
		borderColor: theme.inputBorderColor,
		marginBottom: '18px'
	}

	const typeRadioButtonStyle = {
		width: '21px',
		height: '21px',
		borderRadius: '50%',
		backgroundColor: theme.radioButtonColor,
		margin: '0 10px 1px 0'
	}

	const typeRadioButtonSelectedStyle = {
		...typeRadioButtonStyle,
		backgroundColor: '#39C481'
	}

	const typeTextStyle = {
		color: theme.textColor,
		opacity: '.7'
	}

	const typeTextSelectedStyle = {
		...typeTextStyle,
		opacity: '1'
	}

	const typeSelectionSelectedStyle = {
		...typeSelectionStyle,
		cursor: 'default'
	}

	const infoButtonStyle = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxSizing: 'border-box',
		width: '18px',
		height: '18px',
		borderRadius: '50%',
		backgroundColor: theme.radioButtonColor,
		color: theme.textColorSettingsElements,
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		margin: '0 30px 4px 8px',
		padding: '2px 0 0',
		transition: 'color .2s'
	}

	const infoBoxStyle = {
		position: 'absolute',
		bottom: '34px',
		left: '32px',
		width: '243px',
		backgroundColor: theme.infoBoxColor,
		padding: '13px 14px 14px 16px',
		color: theme.textColorSettingsElementsActive,
		fontSize: '12px',
		lineHeight: '16px',
		textAlign: 'left',
		fontFamily: 'Greycliff demibold',
		borderRadius: '5px',
		zIndex: '500',
		opacity: '0',
		pointerEvents: 'none',
		transition: 'opacity .35s'
	}

	// get project- and brand list
	useEffect(() => {
		// projects
		context.io.socket.get('/api/v1/project', (data, res) => {
			if (res.statusCode === 200) {
				const projects = data.map(project => {
					return { value:project.id, label:project.name }
				})
				setProjectOptions(projects)
			} else {
				// TODO: error handling
			}
		})
		// brands
		context.io.socket.get('/api/v1/brand', (data, res) => {
			if (res.statusCode === 200) {
				const brands = data.map(brand => {
					return { value:brand.id, label:brand.name }
				})
				setBrandOptions(brands)
			} else {
				// TODO: error handling
			}
		})
	}, [context.io.socket])

	// show animatics info box
	function showInfo(e) {
		e.currentTarget.style.color = theme.textColorSettingsElementsActive
		document.getElementById('infoBox').style.opacity = 1
	}

	// hide animatics info box
	function hideInfo(e) {
		e.currentTarget.style.color = theme.textColorSettingsElements
		document.getElementById('infoBox').style.opacity = 0
	}

	// submit handler
	function handleSubmit(e) {
		e.preventDefault()
		const formData = {
			analysisType: analysisType === 0 ? 1 : analysisType,
			title: title,
			project: project,
			brand: brand,
			industry: industry,
			video: video
		}
		props.action(formData)
	}

	// validate formData
	function validate() {
		if (globalState.userData.organization.subscriptionPlan.id === 9 && analysesExtra <= 0) { // free trial spent
			showTrialAlert()
		} else if (analysisType === 0 && animaticsAllowed) {
			showAlert('Please select an analysis type (Normal or Animatic)')
		} else if (!title) {
			showAlert('Please enter a title for your analysis')
		} else if (!project) {
			showAlert('Please enter or select a project for your analysis')
		} else if (!brand) {
			showAlert('Please enter or select a brand for your analysis')
		} else if (industry === '') {
			showAlert('Please select an industry for your analysis')
		} else if (!video) {
			showAlert('Please drag a video (.mp4) to the dropzone')
		}
	}

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	// show analyses spent alert
	function showTrialAlert() {
		alertObjectRef.current = { title:'Sorry, you have spent your free trial analysis', message:'Please upgrade to a flowsam subscription to get more analyses', cancelLabel:'Cancel', actionLabel:'Upgrade', type:'confirm'}
		setAlertTask(()=>(action)=>{ // execute alert
			if (action) { // upgrade
				window.location.href='https://flowsam.ai/features-plans/'
			}
			setAlertTask() // remove confirm dialogue
		})
	}

	// en-/disable submit button
	const canSubmit = ((analysisType !== 0 || !animaticsAllowed) && title && project && brand && industry!=='' && video && !(globalState.userData.organization.subscriptionPlan.id === 9 && analysesExtra <= 0))

	// show analysis type selector if subscription includes animatics
	const analysisTypeSelector = (animaticsAllowed &&
		<div style={analysisTypeContainerStyle}>
			<div style={analysisType === 1 ? typeSelectionSelectedStyle : typeSelectionStyle} onClick={e=>setAnalysisType(1)}>
				<div style={analysisType === 1 ? typeRadioButtonSelectedStyle : typeRadioButtonStyle} /><h4 style={analysisType === 1 ? typeTextSelectedStyle : typeTextStyle}>Normal video</h4>
			</div>
			<div style={analysisType === 2 ? typeSelectionSelectedStyle : typeSelectionStyle} onClick={e=>setAnalysisType(2)}>
				<div style={analysisType === 2 ? typeRadioButtonSelectedStyle : typeRadioButtonStyle} /><h4 style={analysisType === 2 ? typeTextSelectedStyle : typeTextStyle}>Animatic</h4>
				<div id='info' style={infoButtonStyle} onMouseEnter={showInfo} onMouseLeave={hideInfo}>i</div>
				<div id='infoBox' style={infoBoxStyle}>
					Black/white drawing animated storyboard<br/>
					video. If your animatic is coloured/realistic,<br/>
					please select “Normal video”.
				</div>
			</div>
		</div>
	)

	return (
		<form onSubmit={handleSubmit}>
			<h2 style={{color:theme.textColor}}><img style={iconStyle} src={theme.iconAnalysis} alt="Analysis icon" />New video analysis</h2>
			<div style={inputContainerStyle}>
				<div>
					{analysisTypeSelector}
					<input
						name="title"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						className="fs-input-text"
						style={inputStyle}
						placeholder="Analysis title"
					/>
					<DropDown
						options={projectOptions}
						onChange={(label) => setProject(label)}
						placeholder="Project name"
						searchable={true}
					/>
					<div style={{height:'18px'}} />
					<DropDown
						options={brandOptions}
						onChange={(label) => setBrand(label)}
						placeholder="Brand"
						searchable={true}
					/>
					<div style={{height:'18px'}} />
					<DropDown
						options={industryOptions}
						onChange={(value) => setIndustry(value)}
						placeholder="Industry"
						searchable={false}
					/>
				</div>
				<DropZone fileAdded={(file) => setVideo(file)} />
			</div>
			<div style={{width:'260px', margin:'0 auto'}} onClick={validate}>
				<button tabIndex="-1" disabled={!canSubmit} className="fs-button" style={{pointerEvents:canSubmit ? 'auto' : 'none'}}>START ANALYSIS</button>
			</div>
			{alert}
		</form>
	)
}
