import React, { useState, useEffect, useRef, useContext } from 'react'
import { BeatLoader } from 'react-spinners'
import { isMobile } from 'react-device-detect'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import ReportVideoGraph from './ReportVideoGraph'
import ReportVideoSettings from './ReportVideoSettings'
import useLocalStorage from '../services/useLocalStorage'
import useCueTypes from '../services/useCueTypes'
import iconGear from '../assets/images/icon-gear.svg'
import videoPlay from '../assets/images/video-play.svg'
import videoPause from '../assets/images/video-pause.svg'

// styles
const videoContainerStyle = {
	position: 'relative',
	width: '896px',
	margin: '43px auto 5px'
}

const controlsStyle = {
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	marginTop: '39px'
}

const draggerStyle = {
	width: '30px',
	height: '30px',
	backgroundImage: 'url(' + videoPlay + ')',
	cursor: 'pointer',
	position: 'relative',
	top: '-23px',
	left: '0',
	transition: 'background-image .3s',
	zIndex: '500'
}

// component function
export default function ReportVideo(props) {

	const { video, type, currentFrame } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [forceUpdate, setForceUpdate] = useState(0)
	const [settingsVisible, setSettingsVisible] = useState(false)
	const [reportSettings, setReportSettings] = useLocalStorage('reportSettings-'+type, { showAttention:(type==='exposure'?false:true), showExtremaPoints:false, showCues:true, showLabels:true, showCueTypes:false, showScores:false, showUntagged:false, showRetention:true, speedSelected:1 })
	const chunksLoadedRef = useRef(false)
	const chunksLoadingRef = useRef(false)
	const showAttentionRef = useRef(reportSettings.showAttention)
	const showExtremaPointsRef = useRef(reportSettings.showExtremaPoints)
	const showCuesRef = useRef(reportSettings.showCues)
	const showLabelsRef = useRef(reportSettings.showLabels)
	const showCueTypesRef = useRef(reportSettings.showCueTypes)
	const showScoresRef = useRef(reportSettings.showScores)
	const showRetentionRef = useRef(reportSettings.showRetention)
	const showUntaggedRef = useRef(reportSettings.showUntagged)
	const speedSelectedRef = useRef(reportSettings.speedSelected)
	const reqAnimIdRef = useRef()
	const isPlayingRef = useRef(false)
	const frameRef = useRef(0)
	const displayFrameNoRef = useRef(false)
	const chunkArrayRef = useRef([])
	const chunkArrayAttRef = useRef([])
	const canvasRef = useRef()
	const ctxRef = useRef()
	const selectedObjectsRef = useRef(video.objects.filter(obj => video.objectSegmentFilter.indexOf(obj.segment) > -1)) // filter objects to contain only selected objects
	const unselectedObjectsRef = useRef(video.objects.filter(obj => video.objectSegmentFilter.indexOf(obj.segment) === -1)) // filter objects to contain only unselected objects
	const canvasWidth = video ? video.width * video.processing_scale * window.devicePixelRatio * 1.4 : 640 * window.devicePixelRatio
	const canvasHeight = video ? video.height * video.processing_scale * window.devicePixelRatio * (1/video.streamInfo.pixelAspectRatio) * 1.4 : 360 * window.devicePixelRatio
	const interval = 1000/video.frameRate
	const labelMaxChars = 28
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const cueTypes = useCueTypes()

	// dynamic styles
	const lineStyle = {
		width: '100%',
		height: '1px',
		backgroundColor: theme.backgroundColorVideoLine,
		marginRight: '12px'
	}

	const timeStyle = {
		width: '100px',
		position: 'relative',
		top: '-77px',
		left: '-35px',
		textAlign: 'center',
		color: theme.textColor
	}

	const pointerStyle = {
		width: '15px',
		height: '87px',
		position: 'relative',
		top: '-41px',
		left: '15px',
		borderLeft: '1px dashed ' + theme.textColor,
		opacity: '.7'
	}

	const scoreStyle = {
		width: '100px',
		position: 'relative',
		top: '-36px',
		left: '-35px',
		textAlign: 'center',
		zIndex: '999',
		color: theme.textColor
	}

	const gearStyle = {
		cursor: 'pointer',
		opacity: globalState.userData && globalState.userData.settings && globalState.userData.settings.theme !== 0 ? '1' : '.5'
	}

	const loaderStyle = {
		width: video ? video.width * video.processing_scale * 1.4 : 640,
		height: video ? video.height * video.processing_scale * (1/video.streamInfo.pixelAspectRatio) * 1.4 : 360,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.backgroundColor2,
		marginTop: video ? -video.height * video.processing_scale * (1/video.streamInfo.pixelAspectRatio) * 1.4 : -360,
		marginLeft: 'auto',
		marginRight: 'auto'
	}

	const loaderPctStyle = {
		width: video ? video.width * video.processing_scale * 1.4 : 640,
		height: video ? video.height * video.processing_scale * (1/video.streamInfo.pixelAspectRatio) * 1.4 : 360,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.backgroundColor2,
		marginTop: (video ? -video.height * video.processing_scale * (1/video.streamInfo.pixelAspectRatio) * 1.4 : -360) + 32,
		marginLeft: 'auto',
		marginRight: 'auto',
		color: theme.textColor
	}

	const canvasStyle = {
		width: video ? video.width * video.processing_scale * 1.4 : 640,
		height: video ? video.height * video.processing_scale * (1/video.streamInfo.pixelAspectRatio) * 1.4 : 360,
		display: 'block',
		margin: '0 auto',
		boxShadow: globalState.userData && globalState.userData.settings &&  globalState.userData.settings.theme !== 0 ? 'none' : '0 0 0 1px rgba(45,53,68,.1)'
	}

	const lookupFrameNoFrame = {}

	let then = Date.now()
	let mouseDownX = 0
	let lastMouseX = 0
	let draggerX = 0
	let numChunksLoaded = 0
	let spacePressed = false

	// set refs (for js functions that don’t get state)
	showAttentionRef.current = reportSettings.showAttention
	showExtremaPointsRef.current = reportSettings.showExtremaPoints
	showCuesRef.current = reportSettings.showCues
	showLabelsRef.current = reportSettings.showLabels
	showCueTypesRef.current = reportSettings.showCueTypes
	showScoresRef.current = reportSettings.showScores
	showRetentionRef.current = reportSettings.showRetention
	showUntaggedRef.current = reportSettings.showUntagged
	speedSelectedRef.current = reportSettings.speedSelected

	// context- & canvas refs & other inits on mount
	useEffect(() => {
		// video canvas init
		ctxRef.current = canvasRef.current.getContext('2d')
		ctxRef.current.imageSmoothingEnabled = false
		ctxRef.current.font = (12 * window.devicePixelRatio) + 'px Greycliff demibold'
		ctxRef.current.shadowColor = '#888'

		// space bar video control
		window.addEventListener('keydown', keyPressed)
		window.addEventListener('keyup', keyReleased)
		// check if canvas font is loaded
		document.fonts && document.fonts.addEventListener('loadingdone', fontsDidLoad) // not IE/Edge
		// cleanup on unmount
		return () => {
			cancelAnimationFrame(reqAnimIdRef.current)
			window.removeEventListener("keydown", keyPressed)
			window.removeEventListener("keyup", keyReleased)
			document.fonts && document.fonts.removeEventListener('loadingdone', fontsDidLoad) // not IE/Edge
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// generate lookup tables
	useEffect(() => {
		video.frames.map(f => lookupFrameNoFrame[f.frameNo] = f)
	},[video, lookupFrameNoFrame])

	// render video frame on component updates
	useEffect(() => {
		renderFrame(frameRef.current)
 	})

	// go to first frame of clicked bar graph segment and render video
	useEffect(() => {
		if (currentFrame && currentFrame.type === type) {
			pauseVideo()
			frameRef.current = Math.floor(currentFrame.frame) // hack - removes random value from currentFrame
			renderFrame(frameRef.current)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	}, [currentFrame, type])

	// fonts loaded - update canvas
	function fontsDidLoad(e) {
		renderFrame(frameRef.current)
	}

	// check if video is inside viewport - enable video controls if more than half of the video is visible
	function isInViewport(elem) {
		var distance = elem.getBoundingClientRect()
		return (
			distance.top >= -video.height * video.processing_scale * (1/video.streamInfo.pixelAspectRatio) * 1.4/2 + 95 &&
			distance.left >= 0 &&
			distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) + video.height * video.processing_scale * (1/video.streamInfo.pixelAspectRatio) * 1.4 /2 &&
			distance.right <= (window.innerWidth || document.documentElement.clientWidth)
		)
	}

	// mouse down on player control - start drag (or click)
	function startDrag(e) {
		if (!chunksLoadedRef.current) { // prevent drag/play while preloading
			return
		}
		mouseDownX = lastMouseX = isMobile ? e.touches[0].clientX : e.clientX
		draggerX = parseFloat(document.getElementById('vcontrol-'+type).style.left, 10)
		window.addEventListener(isMobile ? "touchmove" : "mousemove", dragController)
		window.addEventListener(isMobile ? "touchend" : "mouseup", stopDrag)
		isPlayingRef.current && pauseVideo(true)
	}

	// mouse up on player control - stop drag (or end click)
	function stopDrag(e) {
		cancelAnimationFrame(reqAnimIdRef.current)
		window.removeEventListener(isMobile ? "touchmove" : "mousemove", dragController)
		window.removeEventListener(isMobile ? "touchend" : "mouseup", stopDrag)
		if (mouseDownX !== lastMouseX) {
			isPlayingRef.current = true
		}
		toggleVideoPlay()
	}

	// drag controller
	function dragController(e) {
		lastMouseX = isMobile ? e.touches[0].clientX : e.clientX
		reqAnimIdRef.current = requestAnimationFrame(updateController)
	}

	// update controller position
	function updateController() {
		let dist = lastMouseX - mouseDownX
		dist = dist < -draggerX ? -draggerX : dist > 837-draggerX ? 837-draggerX : dist
		const leftPos = draggerX + dist
		frameRef.current = Math.round(leftPos/837 * (video.frameCount-3))
		renderFrame(frameRef.current)
	}

	// toggle video play/pause
	function toggleVideoPlay() {
		cancelAnimationFrame(reqAnimIdRef.current)
		isPlayingRef.current = !isPlayingRef.current
		if (isPlayingRef.current) {
			reqAnimIdRef.current = requestAnimationFrame(playFrame)
		} else {
			renderFrame(frameRef.current)
		}
		document.getElementById('vcontrol-'+type).style.backgroundImage = 'url(' + (isPlayingRef.current ? videoPause : videoPlay) + ')'
	}

	// video play frame loop
	function playFrame() {
		reqAnimIdRef.current = requestAnimationFrame(playFrame)
		const now = Date.now()
		const delta = now - then
		if (delta > interval / speedSelectedRef.current) { // check if enough time has elapsed to execute
			then = now - delta % interval / speedSelectedRef.current
			++frameRef.current === video.frameCount-3 && pauseVideo() // video end - skipped last frame since it hasn’t any objects
			frameRef.current = frameRef.current > video.frameCount-3 ? 0 : frameRef.current
			renderFrame(frameRef.current)
		}
	}

	// pause video
	function pauseVideo(keepPlayState=false) {
		cancelAnimationFrame(reqAnimIdRef.current)
		if (!keepPlayState) {
			isPlayingRef.current = false
		}
		const videoControl = document.getElementById('vcontrol-'+type)
		if (videoControl) {
			videoControl.style.backgroundImage = 'url(' + videoPlay + ')'
		}
	}

	// handle key events
	function keyPressed(e) {
		if (spacePressed) {
			e.preventDefault()
		} else if (e.keyCode === 32) { // spacebar
			e.preventDefault()
			toggleSettings(null,false)
			if (!chunksLoadedRef.current) { // prevent keypress play while preloading
				return
			}
			if (isInViewport(canvasRef.current)) {
				spacePressed = true
				toggleVideoPlay()
			}
		} else if (e.keyCode === 27) { // escape
			toggleSettings(null,false)
		} else if (e.keyCode === 37 && isInViewport(canvasRef.current)) { // left arrow
			isPlayingRef.current === true && pauseVideo()
			frameRef.current > 0 && renderFrame(--frameRef.current)
		} else if (e.keyCode === 39 && isInViewport(canvasRef.current)) { // right arrow
			isPlayingRef.current === true && pauseVideo()
			frameRef.current < video.frameCount-3 && renderFrame(++frameRef.current)
		}
	}

	// cancel spacebar pressed flag
	function keyReleased(e) {
		if (e.keyCode === 32) {
			spacePressed = false
		}
	}

	// cut frame image from chunk and paint it on canvas, draw objects, annotations and update controls/values
	function renderFrame(rframe) {
		if (!chunksLoadedRef.current) { // prevent rendering while preloading
			return
		}
		const ctx = ctxRef.current
		const chunk = Math.floor(rframe/30)
		const col = rframe % 5
		const row = Math.floor(rframe % 30 / 5)
		const w = chunkArrayRef.current[0].width/5
		const h = chunkArrayRef.current[0].height/6
		const selChunk = showAttentionRef.current ? chunkArrayAttRef.current[chunk] : chunkArrayRef.current[chunk]
		ctx.drawImage(selChunk, w*col, h*row, w, h, 0, 0, canvasWidth, canvasHeight)

		if(showExtremaPointsRef.current){
			let fIdx = rframe - Math.round(video.frameRate)
			fIdx = fIdx < 0 ? 0 : fIdx
			for(let i = fIdx; i <= rframe; i++){
				const extremaPoints = lookupFrameNoFrame[i].localExtremaPoints
				if (extremaPoints && extremaPoints.length){
					extremaPoints.map(p => {
						if(p.z > 31){
							ctx.beginPath()
							ctx.arc(p.x*canvasWidth, p.y*canvasHeight, 24 - ((rframe-i)*(20/Math.round(video.frameRate))), 0, 2 * Math.PI)
							ctx.fillStyle = `rgba(255, 0, 0, ${0.5 - ((rframe-i)*(0.495/Math.round(video.frameRate)))})`
							ctx.fill()
						}
						return 0
					})
				}
			}
		}

		// draw untagged objects if selected in settings
		if (showUntaggedRef.current) {
			const objects = unselectedObjectsRef.current.filter(obj => obj.frameNo === rframe)
			objects.map(obj => {
				// object box
				ctx.strokeStyle = '#fff'
				ctx.lineWidth = 1 * window.devicePixelRatio
				ctx.beginPath()
				ctx.rect(Math.round(obj.x * canvasWidth) + .5 * window.devicePixelRatio, Math.round(obj.y * canvasHeight) + .5 * window.devicePixelRatio, Math.round(obj.w * canvasWidth) - 2 * window.devicePixelRatio, Math.round(obj.h * canvasHeight) - 2 * window.devicePixelRatio)
				ctx.shadowBlur = 50
				ctx.stroke()
				ctx.shadowBlur = 0
				return 0
			})
			if (showLabelsRef.current) {
				objects.map(obj => {
					// annotations (on top of borders)
					let segment = video.segments.find(seg => seg.id === obj.segment)
					let segmentName = ''
					if (showLabelsRef.current) {
						segmentName += segment.name.slice(0,labelMaxChars)
					}
					const width = Math.ceil(ctx.measureText(segmentName).width + 14 * window.devicePixelRatio)
					let backX = Math.round(obj.x * canvasWidth)
					let backY = Math.round(obj.y * canvasHeight) - 16 * window.devicePixelRatio
					backX = backX + width > canvasWidth ? canvasWidth - width : backX
					backY = backY < 0 ? backY = 0 : backY
					ctx.fillStyle = 'rgba(255,255,255,.8)'
					ctx.fillRect(backX, backY, width, 17 * window.devicePixelRatio)
					ctx.fillStyle = '#2D3544'
					ctx.fillText(segmentName, backX + 7 * window.devicePixelRatio, backY + 12 * window.devicePixelRatio)
					return 0
				})
			}
		}

		// draw tagged objects if selected in settings
		if (showCuesRef.current) {
			const objects = selectedObjectsRef.current.filter(obj => obj.frameNo === rframe)
			objects.map(obj => {
				// object box
				ctx.strokeStyle = '#e5a01b'
				ctx.lineWidth = 2 * window.devicePixelRatio
				ctx.beginPath()
				ctx.rect(Math.round(obj.x * canvasWidth) + 1 * window.devicePixelRatio, Math.round(obj.y * canvasHeight) + 1 * window.devicePixelRatio, Math.round(obj.w * canvasWidth) - 2 * window.devicePixelRatio, Math.round(obj.h * canvasHeight) - 3 * window.devicePixelRatio)
				ctx.stroke()
				return 0
			})
			if (showLabelsRef.current || showScoresRef.current || showCueTypesRef.current) {
				objects.map(obj => {
					// annotations (on top of borders)
					let segment = video.segments.find(seg => seg.id === obj.segment)
					let segmentName = ''
					if (showLabelsRef.current) {
						segmentName += segment.name.slice(0,labelMaxChars)
					}
					if (showCueTypesRef.current) {
						const cueType = cueTypes.find(cue => cue.type === segment.cueType).label
						segmentName += (showLabelsRef.current ? '  |  ' : '') + cueType
					}
					if (showScoresRef.current) {
						if (type === 'retention' && showRetentionRef.current) { // use retention affected values if retention video and retention effect is active
							segment = video.retentionSegments.filter(ret => ret.retentionType === 1).find(seg => seg.segment === obj.segment) // type 1 = standard retention curve
						}
						const spacer = showLabelsRef.current || showCueTypesRef.current ? '  |  ' : ''
						const objectScore = type === 'exposure' ? segment.scoreExposure.toFixed(2) : type === 'retention' || type === 'power' ? segment.scorePower.toFixed(2) : segment.scoreAttention.toFixed(2)
						segmentName += showScoresRef.current ? type === 'exposure' ? spacer + 'exp: ' + objectScore : type === 'retention' || type === 'power' ? spacer + 'perf: ' + objectScore : spacer + 'att: ' + objectScore : ''
					}
					const width = Math.ceil(ctx.measureText(segmentName).width + 14 * window.devicePixelRatio)
					let backX = Math.round(obj.x * canvasWidth)
					let backY = Math.round(obj.y * canvasHeight) - 15 * window.devicePixelRatio
					backX = backX + width > canvasWidth ? canvasWidth - width : backX
					backY = backY < 0 ? backY = 0 : backY
					ctx.fillStyle = '#e5a01b'
					ctx.fillRect(backX, backY, width, 17 * window.devicePixelRatio)
					ctx.fillStyle = '#fff'
					ctx.fillText(segmentName, backX + 7 * window.devicePixelRatio, backY + 12 * window.devicePixelRatio)
					return 0
				})
			}
		}


		// controls, score & time - showing minutes and hours as necessary
		const timeContainer = document.getElementById('vtime-'+type)
		const videoControl = document.getElementById('vcontrol-'+type)
		const pointer = document.getElementById('pointer-'+type)
		const scoreContainer = document.getElementById('score-'+type)

		if (timeContainer && videoControl && pointer && scoreContainer) {
			if (displayFrameNoRef.current) {
				timeContainer.innerHTML = 'frame ' + (frameRef.current + 1)
			} else {
				const secs = rframe/video.frameRate
				const timestring = new Date(secs * 1000).toISOString()
				timeContainer.innerHTML = secs > 3600 ? timestring.substr(12, 10) : secs > 600 ? timestring.substr(14, 8) : timestring.substr(15, 7)
			}
			if (type === 'retention') { // use power (w. applied retention effect if needed)
				const standard = video.retentionFrames.filter(ret => ret.retentionType === 1) // type 1 = standard retention curve
				scoreContainer.innerHTML = showRetentionRef.current ? Math.round(standard[rframe].thisFrameScorePower) : Math.round(video.frames[rframe].thisFrameScorePower)
			} else { // use attention
				scoreContainer.innerHTML =	type === 'exposure' ?  Math.round(video.frames[rframe].thisFrameScoreExposure) :
											type === 'attention' ? Math.round(video.frames[rframe].thisFrameScoreAttention) :
											Math.round(video.frames[rframe].thisFrameScorePower) // power
			}
			// position video player control knob and time container
			const left = 837/(video.frameCount-3) * rframe
			videoControl.style.left = left + 'px'
			timeContainer.style.left = (left - 35) + 'px'
			pointer.style.left = (left + 15) + 'px'
			scoreContainer.style.left = (left - 35) + 'px'
		}
	}

	// cache image chunks
	if (video && !chunksLoadingRef.current && !chunksLoadedRef.current) {

		/*
		// chunks without attention heatmap
		for (let i=0; i<video.chunks; i++) {
			const tmpImg = new Image()
			tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames/' + video.guid + '-chunk' + ("0000000"+i).slice(-7) + '.jpg'
			tmpImg.onload = checkLoaded
			chunkArrayRef.current.push(tmpImg)
		}
		// chunks with attention heatmap
		for (let j=0; j<video.chunks; j++) {
			const tmpAttImg = new Image()
			tmpAttImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames-blend/' + video.guid + '-chunk' + ("0000000"+j).slice(-7) + '.jpg'
			tmpAttImg.onload = checkLoadedAtt
			chunkArrayAttRef.current.push(tmpAttImg)
		}
		*/

		chunksLoadingRef.current = true;

		//console.log(chunkArrayRef.current.length);
		//console.log(chunkArrayAttRef.current.length);

		const tmpImg = new Image()
		tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames/' + video.guid + '-chunk' + ("0000000"+chunkArrayRef.current.length).slice(-7) + '.jpg'
		tmpImg.onload = checkLoaded
		chunkArrayRef.current.push(tmpImg)

		const tmpAttImg = new Image()
		tmpAttImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames-blend/' + video.guid + '-chunk' + ("0000000"+chunkArrayAttRef.current.length).slice(-7) + '.jpg'
		tmpAttImg.onload = checkLoadedAtt
		chunkArrayAttRef.current.push(tmpAttImg)

	}

	// check that all chunks are loaded
	function checkLoaded(e) {
		e.target.onload = null
		if(!document.getElementById('chunk-load-pct-' + type)){
			return
		}
		numChunksLoaded++
		if (numChunksLoaded === 2 * video.chunks) { // with and without attention heatmap
			chunksLoadedRef.current = true
			setForceUpdate(forceUpdate+1)
			renderFrame(0)
		} else {
			document.getElementById('chunk-load-pct-' + type).innerHTML = (50*numChunksLoaded/video.chunks).toFixed(0) + '%'
		}

		if (chunkArrayRef.current.length < video.chunks) {
			const tmpImg = new Image()
			tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames/' + video.guid + '-chunk' + ("0000000"+chunkArrayRef.current.length).slice(-7) + '.jpg'
			tmpImg.onload = checkLoaded
			chunkArrayRef.current.push(tmpImg)
		}
	}

	// check that all chunks are loaded
	function checkLoadedAtt(e) {
		e.target.onload = null
		if(!document.getElementById('chunk-load-pct-' + type)){
			return
		}
		numChunksLoaded++
		if (numChunksLoaded === 2 * video.chunks) { // with and without attention heatmap
			chunksLoadedRef.current = true
			setForceUpdate(forceUpdate+1)
			renderFrame(0)
		} else {
			document.getElementById('chunk-load-pct-'+type).innerHTML = (50*numChunksLoaded/video.chunks).toFixed(0) + '%'
		}

		if (chunkArrayAttRef.current.length < video.chunks) {
			const tmpAttImg = new Image()
			tmpAttImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames-blend/' + video.guid + '-chunk' + ("0000000"+chunkArrayAttRef.current.length).slice(-7) + '.jpg'
			tmpAttImg.onload = checkLoadedAtt
			chunkArrayAttRef.current.push(tmpAttImg)
		}
	}

	// toggle time/frameNo display
	function toggleFrameNoDisplay() {
		displayFrameNoRef.current = !displayFrameNoRef.current
		renderFrame(frameRef.current)
	}

	// show/hide settings
	function toggleSettings(e, b=!settingsVisible) {
		setSettingsVisible(b)
	}

	// jump to frame when clicked on timeline
	function jumpToFrame(e) {
		const clientX = isMobile ? e.touches[0].clientX : e.clientX
		if (clientX - e.currentTarget.getBoundingClientRect().x - 15 > 837+15) return
		let gotoFrame = Math.round((clientX - e.currentTarget.getBoundingClientRect().x - 15)/837 * (video.frameCount-3))
		if (gotoFrame < 0) gotoFrame = 0
		if (gotoFrame > video.frameCount-3) gotoFrame =  video.frameCount-3
		frameRef.current = gotoFrame
		isPlayingRef.current === true && pauseVideo()
		renderFrame(frameRef.current)
	}

	// canvas
	const canvas = (isMobile ?
		<canvas style={canvasStyle} ref={canvasRef} width={canvasWidth} height={canvasHeight} onTouchStart={startDrag} /> :
		<canvas style={canvasStyle} ref={canvasRef} width={canvasWidth} height={canvasHeight} onMouseDown={startDrag} />
	)

	// preloader
	const preLoader = !chunksLoadedRef.current && <div style={loaderStyle}><BeatLoader color={'#39C481'} /></div>
	const preLoaderPct = !chunksLoadedRef.current && <div id={"chunk-load-pct-"+type} style={loaderPctStyle}>0%</div>

	// video settings
	const settings = (settingsVisible &&
		<ReportVideoSettings
			reportSettings={reportSettings}
			setReportSettings={setReportSettings}
			toggleSettings={toggleSettings}
			type={type}
		/>
	)

	const controls = (isMobile ?
		<div style={controlsStyle} onTouchStart={jumpToFrame}>
			<div style={lineStyle} />
			<img src={iconGear} style={gearStyle} onMouseUp={toggleSettings} alt="Video settings" />
		</div>
		:
		<div style={controlsStyle} onMouseDown={jumpToFrame}>
			<div style={lineStyle} />
			<img src={iconGear} style={gearStyle} onMouseUp={toggleSettings} alt="Video settings" />
		</div>
	)

	const dragger = (isMobile ?
		<div style={draggerStyle} onTouchStart={startDrag} id={"vcontrol-"+type} /> :
		<div style={draggerStyle} onMouseDown={startDrag} id={"vcontrol-"+type} />
	)

	return (
		<div style={videoContainerStyle}>
			{canvas}
			{preLoader}
			{preLoaderPct}
			{settings}
			{controls}
			{dragger}
			<div style={timeStyle} id={"vtime-"+type} onClick={toggleFrameNoDisplay}>0:00.00</div>
			<div style={pointerStyle} id={"pointer-"+type} />
			<div style={scoreStyle} id={"score-"+type}>0</div>
			<ReportVideoGraph video={video} type={type} retentionEffect={showRetentionRef.current} />
		</div>
	)
}
