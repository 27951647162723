import React, { useState, useRef, useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import Alert from './Alert'

// component function
export default function DropZone(props) {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [highlight, setHighlight] = useState()
	const [file, setFile] = useState()
	const [error, setError] = useState()
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const fileInputRef = useRef()
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const analysesExtra = globalState.userData && globalState.userData.organization && globalState.userData.organization.freeAnalyses ? globalState.userData.organization.freeAnalyses : 0
	const maxMb = globalState.userData && globalState.userData.organization && globalState.userData.organization.subscriptionPlan && globalState.userData.organization.subscriptionPlan.config && globalState.userData.organization.subscriptionPlan.config.Max_Mb_per_film ? globalState.userData.organization.subscriptionPlan.config.Max_Mb_per_film : 1000

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// dynamic styles
	const videoFieldStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '340px',
		border: '2px solid',
		borderRadius: '10px',
		borderStyle: 'dashed',
		borderColor: theme.dropZoneBorderColor,
		color: theme.textColor,
		marginLeft: '40px',
		padding: '30px',
		boxSizing: 'border-box',
		cursor: 'pointer',
		overflow: 'hidden'
	}

	// open file select dialogue
	function openFileDialog() {
		if (globalState.userData.organization.subscriptionPlan.id === 9 && analysesExtra <= 0) { // free trial spent
			showTrialAlert()
		} else {
			fileInputRef.current.click()
		}
	}

	// validate file when added
	function onFileAdded(e) {
		validateFile(e.target.files[0])
	}

	// handle dropped file
	function onDrop(e) {
		e.preventDefault()
		setHighlight(false)
		validateFile(e.dataTransfer.files[0])
	}

	// handle drag enter
	function onDragOver(e) {
		e.preventDefault()
		setHighlight(true)
	}

	// handle drag leave
	function onDragLeave() {
		setHighlight(false)
	}

	// validate file
	function validateFile(file) {
		if (!file) return
		if (globalState.userData.organization.subscriptionPlan.id === 9 && analysesExtra <= 0) { // free trial spent
			showTrialAlert()
		} else {
			let err = null
			err = file.size > maxMb * 1000 * 1000 ? 'Sorry, the video size exceeds the ' + maxMb + ' MB per video allowed in your subscription' : err
			err = (file.type !== 'video/mp4' && file.type.indexOf('image/') != 0) ? 'Sorry, flowsam only likes .mp4 video or image files' : err
			setError(err ? err : null)
			setFile(err ? null : file)
			props.fileAdded(err ? null : file)
		}
	}

	// show analyses spent alert
	function showTrialAlert() {
		alertObjectRef.current = { title:'Sorry, you have spent your free trial analysis', message:'Please upgrade to a flowsam subscription to get more analyses', cancelLabel:'Cancel', actionLabel:'Upgrade', type:'confirm'}
		setAlertTask(()=>(action)=>{ // execute alert
			if (action) { // upgrade
				window.location.href='https://flowsam.ai/features-plans/'
			}
			setAlertTask() // remove confirm dialogue
		})
	}

	const dropZoneStyle = { ...videoFieldStyle, backgroundColor: highlight && theme.dropZoneHighlightColor }
	const statusStyle = { pointerEvents:'none', color: error && !highlight && theme.dropzoneErrorColor }
	const statusText = highlight ? 'Drop it right here' : error ? error : file ? file.name : 'Drag mp4 or image file - or click here'

	return (
		<>
			<div
				style={dropZoneStyle}
				onDragOver={onDragOver}
				onDragLeave={onDragLeave}
	    		onDrop={onDrop}
	    		onClick={openFileDialog}>
				<input
					type="file"
					ref={fileInputRef}
					style={{display:'none'}}
					onChange={onFileAdded}
					accept="video/mp4,image/*"
				/>
				<h4 style={statusStyle}>{statusText}</h4>
			</div>
			{alert}
		</>
	)
}
