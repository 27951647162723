import React, { useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'

// styles
const footerContainerStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: '688px',
	maxWidth: '1080px',
	height: '120px',
	margin: '0 auto',
	padding: '0 40px',
	opacity: '.6',
	textAlign: 'center'
}

// component function
export default function Footer() {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]

	// dynamic styles
	const footerBarStyle = {
		backgroundColor: theme.backgroundColor0
	}

	const textStyle = {
		color: theme.textColor
	}

	const aStyle = {
		color: theme.textColor,
		textDecoration: 'none',
		display: 'inline-block'
	}

	// divider
	const divider = <span style={{padding: '0 16px', opacity:'.5', display:'inline-block'}}>|</span>

	return (
		<div style={footerBarStyle}>
			<div style={footerContainerStyle}>
				<p style={textStyle}>Flowsam<sup>®</sup>{divider}Plaiground ApS{divider}Jagtvej 200, 3.{divider}DK-2100 Copenhagen{divider}Denmark{divider}<nobr>Tel. +45 4025 7041</nobr>{divider}<a style={aStyle} tabIndex="-1" href="mailto:info@flowsam.ai">info@flowsam.ai</a></p>
			</div>
		</div>
	)
}
