import React, { useContext, useState, useRef, useEffect } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import Alert from './Alert'
import { APIContext } from '../services/api'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import creditCardLogos from '../assets/images/credit-card-logos.svg'
import secureLogos from '../assets/images/secure-logos.svg'

// styles
const paymentBoxLeftStyle = {
	flexBasis: '290px',
	flexGrow: '335',
	paddingRight: '60px',
	boxSizing: 'border-box',
	textAlign: 'left'
}

const paymentBoxRightStyle = {
	flexBasis: '290px',
	flexGrow: '200',
	margin: '30px 0 0'
}

// component function
export default function AccountPayment(props) {

	const {org} = props

	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [alertTask, setAlertTask] = useState()
	const [card, setCard] = useState()
	const [canSubmit, setCanSubmit] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const alertObjectRef = useRef(null)
	const themeId = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? globalState.userData.settings.theme : 0
	const theme = themes[themeId]
	const dateOptions = { year:'numeric', month:'short', day:'numeric' }

	const plan = org.subscriptionPlan
	const calculations = org.subscriptionCalculations
	const country = org.companyStoreData.customer.address && org.companyStoreData.customer.address.country
	const taxFactor = country === 'DK' ? .25 : 0
	const taxTxt = taxFactor > 0 ? '(incl. ' + (taxFactor * 100) + '% tax) ' : ' '
	const price = plan ? plan.config.Monthly_billing ? numberWithCommas(plan.config.Monthly_ex_VAT_Monthly_billing * (1+taxFactor)) : numberWithCommas(plan.config.Monthly_ex_VAT_Annual_billing * (1+taxFactor)) : ''
	const cycleName = plan && plan.config.Calc_cycle_length_month === 12 ? 'yearly' : 'monthly'
	const subRenew = calculations && new Date(calculations.currentPeriodEnd).toLocaleDateString("en-GB", dateOptions)

	const stripeCardElementsOptions = {
		hidePostalCode: true,
		style: {
			base: {
				iconColor: themeId === 1 ? '#fff' : '#666',
				color: theme.textColor,
				fontFamily: 'Greycliff, Helvetica, Arial, Lucida, sans-serif',
				fontSize: '18px',
				fontSmoothing: 'antialiased',
				'::placeholder': {
					color: themeId === 1 ? 'rgba(255, 255, 255, 0.6)' : 'rgba(45,53,68,.5)'
				}
			}
		}
	}
	const stripe = useStripe()
	const elements = useElements()

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// dynamic styles
	const accountElementBack = {
		backgroundColor: theme.backgroundColorPage,
		padding: '53px 50px 70px',
		borderTop: '1px solid ' + theme.borderColorAccount,
		minWidth: '980px'
	}

	const paymentBoxStyle = {
		display: 'flex',
		flexWrap: 'wrap',
		backgroundColor: theme.backgroundColorAccountBox,
		margin: '27px 0 40px',
		padding: '0 30px 30px',
		color: theme.textColor
	}

	const labelStyle = {
		fontSize: '12px',
		lineHeight: '16px',
		fontFamily: 'Greycliff demibold',
		color: theme.textColor,
		opacity: '.5',
		whiteSpace: 'nowrap'
	}

	// current credit card brand and last 4 digits
	useEffect(() => {
		getCurrentCard()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.io.socket])

	// get info about current card
	function getCurrentCard(updated) {
		context.io.socket.get('/api/v1/subscription/customer/payment-method', (data, res) => {
			if (res.statusCode === 200) {
				setCard(data.card)
				if (updated) {
					elements.getElement(CardElement).clear()
					showAlert('Credit card has been updated')
				}
			} else if (res.statusCode === 404) {
				// no credit card
			} else {
				showAlert('Error getting credit card info: ' + res.error.message)
			}
			setSubmitting(false)
		})
	}

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	// add thousand separators to number
	function numberWithCommas(val) {
	    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}

	// update payment method
	function updatePaymentMethod() {
		if (submitting) return
		setSubmitting(true)
		context.io.socket.get('/api/v1/subscription/customer/setup-intent', (data, res) => {
			if (res.statusCode === 200) {
				stripe.confirmCardSetup(data.client_secret,{payment_method:{card:elements.getElement(CardElement)}}).then(result => {
					if (result.error) {
						showAlert('Error: ' + (result.error.message || result.error.error.decline_code))
						setSubmitting(false)
					} else {
						const req = { paymentMethodId: result.setupIntent.payment_method }
						context.io.socket.patch('/api/v1/subscription/customer/payment-method', req, (data, res) => {
							if (res.statusCode === 200) {
								getCurrentCard(true) // update card info on page
							} else {
								showAlert('Error updating credit card: ' + res.error.message)
								setSubmitting(false)
							}
						})
					}
				})
			} else {
				showAlert('Error updating credit card: ' + res.error.message)
				setSubmitting(false)
			}
		})
	}

	const cardTxt = (card && calculations && calculations.subCancel === 0 && !calculations.subExpired ?
		'You are billed ' + price + ' € ' + taxTxt + cycleName + ' in advance on this credit card. Next due date is ' + subRenew + '.' :
		'You are not billed on this card because your subscription has expired or has been canceled.'
	)

	return (
		<div style={accountElementBack}>
			<div className="accountElementContainer">
				<h2 style={{color:theme.textColor}}>Credit card</h2>
				<div style={paymentBoxStyle}>
					<div style={paymentBoxLeftStyle}>
						<div style={{...labelStyle, margin:'24px 0 1px'}}>Current credit card</div>
						<div style={{fontFamily:'Greycliff demibold'}}>{card ? '[' + card.brand.charAt(0).toUpperCase()+card.brand.slice(1) + '] xxxx-xxxx-xxxx-' + card.last4 : 'No credit card added'}</div>
						<p style={{margin:'18px 0 -7px'}}>{cardTxt}</p>
					</div>
					<div style={paymentBoxRightStyle}>
						<div style={{backgroundColor:themeId === 1 ? '#4B576B' : '#fff', margin:'0', padding:'8px 16px 10px'}}>
							<CardElement options={stripeCardElementsOptions} onChange={e=>setCanSubmit(e.complete)}/>
						</div>
						<div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
							<img src={creditCardLogos} style={{width:'246px', height:'30px', margin:'30px 20px 0 0'}} alt="Credit card logos" />
							<img src={secureLogos} style={{width:'174px', height:'42px', margin:'20px 0 -2px'}} alt="Stripe and 3D secure" />
						</div>
					</div>
				</div>
				<button tabIndex="-1" disabled={!canSubmit || submitting} className="fs-button accountSubmitBtn" onClick={updatePaymentMethod}>{submitting ? card ? 'UPDATING CARD...' : 'ADDING CARD...' : card ? 'UPDATE CREDIT CARD' : 'ADD CREDIT CARD'}</button>
			</div>
			{alert}
		</div>
	)
}
