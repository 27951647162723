import React, { useContext } from 'react'
import CreatableSelect from 'react-select/creatable'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'

// component function
export default function DropDown(props) {

	const { value, options, onChange, placeholder, searchable, small=false } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]

	// styles
	const customStyles = {
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? theme.inputHighlightColor : theme.backgroundColor3,
			color: state.isFocused ? theme.textColor : theme.inputPlaceholderColor,
			fontSize: small ? '14px' : '18px',
			height: small ? '34px' : '40px',
			padding: small ? '7px 0 0 17px' : '10px 0 0 20px',
			textAlign: 'left',
			'&:active': {
				backgroundColor: theme.inputHighlightColor
			}
		}),
		control: (provided) => ({
			...provided,
			border: 0,
			borderRadius: 0,
			borderBottom: '1px ' + theme.inputBorderColor + ' solid',
			backgroundColor: theme.backgroundColor3,
			fontSize: small ? '14px' : '18px',
			height: small ? '38px' : '40px',
			padding: small ? '2px 10px 0 9px' : '0 10px 2px 12px',
			boxShadow: 'none',
			'&:hover': {
				borderBottom: '1px ' + theme.inputBorderColor + ' solid'
			}
		}),
		input: (provided) => ({
			...provided,
			color: theme.textColor,
			marginLeft: 0
		}),
		singleValue: (provided) => ({
			...provided,
			color: theme.textColor,
			marginLeft: 0
		}),
		menu: (provided) => ({
			...provided,
			marginTop: 0,
			borderRadius: 0,
			backgroundColor: theme.backgroundColor3,
			boxShadow: 'none'
		}),
		placeholder: (provided) => ({
			...provided,
			color: theme.inputPlaceholderColor,
			marginLeft: 0
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: theme.textColor,
			opacity: '.6',
			display: searchable ? 'none' : 'block',
			padding: small ? '4px 0 0' : '4px 7px 0 0',
		}),
		indicatorSeparator: () => ({
			display: 'none'
		})
	}

	return (
		<CreatableSelect
			value={value}
			styles={customStyles}
			onChange={(selectedOption) => onChange(searchable ? selectedOption.label : selectedOption.value)}
			options={options}
			placeholder={placeholder}
			isSearchable={searchable}
		/>
	)
}
