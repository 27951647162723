import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import AnalysisProgressBar from './AnalysisProgressBar'
import Alert from './Alert'
import { APIContext } from '../services/api'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'

// styles
const iconStyle = {
	margin: '0 16px -8px -26px'
}

const buttonStyle = {
	marginTop: '44px'
}

const dotsStyle = {
	display: 'inline-block',
	width: '20px',
	textAlign: 'left'
}

// component function
export default function AnalysisProgress(props) {

	// progress default object
	const progressInit = {
		todo: { transcode: 1, preProc: 1, objectDetection: 1, objectDetectionPostProc: 1, mapImage: 1, flowsam: 1, mapImageBlend: 1, attentionCalc: 1, statisticsCalc: 1 },
		done: { transcode: 0, preProc: 0, objectDetection: 0, objectDetectionPostProc: 0, mapImage: 0, flowsam: 0, mapImageBlend: 0, attentionCalc: 0, statisticsCalc: 0 }
	}

	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [uploaded, setUploaded] = useState(0)
	const [progress, setProgress] = useState(progressInit)
	const [guid, setGuid] = useState()
	const [activityDots, setActivityDots] = useState('')
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const intervalRef = useRef(0)
	const { analysisType, title='No title', project, brand, industry, video } = props.data
	const { todo, done } = progress
	const canSubmit = JSON.stringify(todo) === JSON.stringify(done)
	const subtxt = uploaded < 100 ? 'Preparing' : canSubmit ? 'Analysis complete' : 'Analyzing'
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]

	// dynamic styles
	const subHeadingStyle = {
		margin: '30px 0 32px',
		color: theme.textColor
	}

	const newButtonStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '120px',
		height: '30px',
		margin: '25px auto -50px',
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		color: theme.textColor,
		opacity: '.5',
		transition: 'opacity .2s'
	}

	// listen for toast error events
	useEffect(() => {
		context.io.socket.on('toast', handleToastError)
		return () => context.io.socket.off('toast', handleToastError) // cleanup on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[context.io.socket])

	// handle toast errors
	function handleToastError(msg) {
		if (msg.severity === 'error') {
			alertObjectRef.current = { title:JSON.parse(msg.detail).reason }
			setAlertTask(()=>(action)=>{
				setAlertTask()
				handleNewClick() // go back to upload form
			}) // close alert on response
		}
	}

	// upload video and listen for analysis progress
	useEffect(() => {
		if (video) {

			// upload video file
			const data = new FormData()
			data.append('videofile', video, video.name)

			axios.post(process.env.REACT_APP_SAILS_URL + '/api/v1/video/upload', data, {
				withCredentials: true,
				onUploadProgress: ProgressEvent => {
					setUploaded(Math.round(ProgressEvent.loaded / ProgressEvent.total * 100))
				}
			})

			// upload done
			.then(res => {
				if (res.status === 200) { // file upload ok

					setGuid(res.data.guid)

					// send analysis data
					context.io.socket.post('/api/v1/video', { guid:res.data.guid, analysisType:analysisType, title:title, project:{name:project}, brand:{name:brand}, industryCode:industry, filename:video.name }, (data, res) => {
						if (res.statusCode !== 200) {
							// TODO: handle error
						}
					})

					// listen for succesful verification event
					context.io.socket.on('video-verified', msg => {

						// subscribe to progress events for this analysis
						context.io.socket.post('/api/v1/user/subscribe', { roomName: 'video-' + res.data.guid }, (data, res) => {
							if (res.statusCode !== 200) {
								// TODO: handle error
							}
						})

						// listen for analysis progress
						context.io.socket.on('video-progress-' + res.data.guid, msg => {
							setProgress(msg)
						})

						// cache chunks as soon as they are ready for download
						context.io.socket.on('cache-img-' + res.data.guid, msg => {
							const tmpImg = new Image()
							switch(msg.type) {
								case 'maps-frames':
									tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + res.data.guid + '/maps-frames/' + res.data.guid + '-chunk' + ('0000000' + msg.chunk).slice(-7) + '.jpg'
									break
								case 'maps-frames-blend':
									tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + res.data.guid + '/maps-frames-blend/' + res.data.guid + '-chunk' + ('0000000' + msg.chunk).slice(-7) + '.jpg'
									break
								default:
									break
							}
						})
					})

				} else { // file upload failed
					// TODO: handle error - unless this is handled by a toast error
				}
			})
		}
	}, [analysisType, title, project, brand, industry, video, context.io.socket])

	// start dot animation on component mount
	useEffect(() => {
		// animate activity-dots
		function animateDots() {
			setActivityDots(activityDots === '...' ? '' : activityDots + '.')
		}
		intervalRef.current = setInterval(animateDots, 500)
		return () => clearInterval(intervalRef.current) // cleanup on unmount
	}, [activityDots])

	// stop dot animation if analysis is done
	if (canSubmit && intervalRef.current) {
		clearInterval(intervalRef.current)
		intervalRef.current = null
		setActivityDots('')
	}

	// button click handler
	function handleClick() {
		props.action(guid)
	}

	// new analysis button enter handler
	function onEnter(e) {
		e.currentTarget.style.opacity = '1'
	}

	// new analysis button leave handler
	function onLeave(e) {
		e.currentTarget.style.opacity = '.5'
	}

	// new analysis button click handler
	function handleNewClick() {
		props.new()
	}

	// new analysis button - show after upload when received guid
	const newButton = guid && <div style={newButtonStyle} onMouseEnter={onEnter} onMouseLeave={onLeave} onClick={handleNewClick}>&lt;&nbsp;&nbsp;New analysis&nbsp;&nbsp;</div>

	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	return (
		<>
			<h2 style={{color:theme.textColor}}><img style={iconStyle} src={theme.iconAnalysis} alt="Analysis icon" />{title}</h2>
			<h3 id="statusTxt" style={subHeadingStyle}>&nbsp;&nbsp;&nbsp;{subtxt}<span style={dotsStyle}>{activityDots}</span></h3>
			<AnalysisProgressBar label="Video upload" pct={uploaded} />
			<AnalysisProgressBar label="Video transcoding" pct={done.transcode/todo.transcode*100} />
			<AnalysisProgressBar label="Optical flow & frame extraction" pct={done.preProc/todo.preProc*100} />
			<AnalysisProgressBar label="Object detection" pct={done.objectDetection/todo.objectDetection*100} />
			<AnalysisProgressBar label="Object detection post processing" pct={done.objectDetectionPostProc/todo.objectDetectionPostProc*100} />
			<AnalysisProgressBar label="Frame sequence generation" pct={done.mapImage/todo.mapImage*100} />
			<AnalysisProgressBar label="Attention prediction" pct={done.flowsam/todo.flowsam*100} />
			<AnalysisProgressBar label="Attention heat map generation" pct={done.mapImageBlend/todo.mapImageBlend*100} />
			<AnalysisProgressBar label="Attention metrics calculation" pct={done.attentionCalc/todo.attentionCalc*100} />
			<AnalysisProgressBar label="Crunching numbers" pct={done.statisticsCalc/todo.statisticsCalc*100} />
			<button className="fs-button" style={buttonStyle} tabIndex="-1" disabled={!canSubmit} onClick={handleClick}>ADD BRAND CUES</button>
			{newButton}
			{alert}
		</>
	)
}
