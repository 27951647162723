import React, { useState, useEffect, useRef, useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import AlertReuseVideoSettings from './AlertReuseVideoSettings'
import useLocalStorage from '../services/useLocalStorage'
import useCueTypes from '../services/useCueTypes'
import iconGear from '../assets/images/icon-gear.svg'
import videoPlay from '../assets/images/video-play.svg'
import videoPause from '../assets/images/video-pause.svg'

// styles
const videoContainerStyle = {
	position: 'relative',
	width: '640px',
	marginBottom: '6px'
}

const controlsStyle = {
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	marginTop: '5px'
}

const draggerStyle = {
	width: '30px',
	height: '30px',
	backgroundImage: 'url(' + videoPlay + ')',
	cursor: 'pointer',
	position: 'relative',
	top: '-23px',
	left: '0',
	transition: 'background-image .3s',
	zIndex: '500'
}

const selectOverlayStyle = {
	position: 'relative',
	width: '640px',
	height: '360px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: '-360px'
}

// component function
export default function AlertReuseVideo(props) {

	const { video, currentFrame, matchedFrames, setSelectedFrames } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [forceUpdate, setForceUpdate] = useState(0)
	const [settingsVisible, setSettingsVisible] = useState(false)
	const [reuseVideoSettings, setReuseVideoSettings] = useLocalStorage('reuseVideoSettings', { showCues:true, showLabels:true, showCueTypes:false, showUntagged:false, speedSelected:1 })
	const [percentLoaded, setPercentLoaded] = useState(.01)
	const chunksLoadedRef = useRef(false)
	const chunksLoadingRef = useRef(false)
	const showCuesRef = useRef(reuseVideoSettings.showCues)
	const showLabelsRef = useRef(reuseVideoSettings.showLabels)
	const showCueTypesRef = useRef(reuseVideoSettings.showCueTypes)
	const showUntaggedRef = useRef(reuseVideoSettings.showUntagged)
	const speedSelectedRef = useRef(reuseVideoSettings.speedSelected)
	const reqAnimIdRef = useRef()
	const isPlayingRef = useRef(false)
	const frameRef = useRef(0)
	const chunkArrayRef = useRef([])
	const canvasRef = useRef()
	const ctxRef = useRef()
	const canvasWidth = (video.height/video.width > 360/640) ? Math.ceil(video.width/video.height * 360) * window.devicePixelRatio * video.streamInfo.pixelAspectRatio : 640 * window.devicePixelRatio
	const canvasHeight = 360 * window.devicePixelRatio
	const scenesRef = useRef(video.scenes.filter(sc => sc.frameNo <= video.frameCount-3)) // don’t include scene if it is outside video player range (we cut last 3 frames because Google doesn’t detect objects in these)
	const selectedFramesRef = useRef([])
	const selectedObjectsRef = useRef(video.objects.filter(obj => video.objectSegmentFilter.indexOf(obj.segment) > -1)) // filter objects to contain only selected objects
	const unselectedObjectsRef = useRef(video.objects.filter(obj => video.objectSegmentFilter.indexOf(obj.segment) === -1)) // filter objects to contain only unselected objects
	const interval = 1000/video.frameRate
	const labelMaxChars = 28
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const cueTypes = useCueTypes()

	// dynamic styles
	const canvasContainerStyle = {
		width: '640px',
		backgroundColor: theme.backgroundColorPage,
	}

	const canvasStyle = {
		width: canvasWidth/window.devicePixelRatio,
		height: '360px',
		display: 'block',
		margin: '0 auto'
	}

	const lineStyle = {
		width: '100%',
		height: '1px',
		backgroundColor: theme.backgroundColorVideoLine,
		marginRight: '12px'
	}

	const gearStyle = {
		cursor: 'pointer',
		opacity: globalState.userData && globalState.userData.settings && globalState.userData.settings.theme !== 0 ? '1' : '.5'
	}

	const loaderStyle = {
		width: '640px',
		height: '360px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.backgroundColorPage,
		marginTop: '-360px'
	}

	const loaderBarStyle = {
		width: '200px',
		height: '2px',
		borderRadius: '2px',
		backgroundColor: theme.backgroundColorLoaderBar,
		transition: 'width .5s'
	}

	const sceneSelectorStyle = {
		backgroundColor: theme.backgroundColorPage,
		height: '20px',
		marginTop: '6px'
	}

	const labelStyle = {
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		color: theme.textColor,
		opacity: '.3',
		letterSpacing: '.1px',
		width: '640px',
		textAlign: 'center',
		marginTop: '6px'
	}

	const sceneBarStyle = {
		height: '20px',
		marginBottom: '-20px',
		opacity: '.75',
		cursor: 'pointer'
	}

	const selectButtonStyle = {
		width: canvasWidth/window.devicePixelRatio - 30 + 'px',
		maxWidth: '240px',
		height: '39px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontFamily: 'Greycliff demibold',
		fontSize: '18px',
		padding: '0'
	}

	let then = Date.now()
	let mouseDownX = 0
	let lastMouseX = 0
	let draggerX = 0
	let numChunksLoaded = 0
	let spacePressed = false

	// set refs (for js functions that don’t get state)
	showCuesRef.current = reuseVideoSettings.showCues
	showLabelsRef.current = reuseVideoSettings.showLabels
	showCueTypesRef.current = reuseVideoSettings.showCueTypes
	showUntaggedRef.current = reuseVideoSettings.showUntagged
	speedSelectedRef.current = reuseVideoSettings.speedSelected

	// context- & canvas refs & other inits on mount
	useEffect(() => {
		// video canvas init
		ctxRef.current = canvasRef.current.getContext('2d')
		ctxRef.current.imageSmoothingEnabled = false
		ctxRef.current.font = (12 * window.devicePixelRatio) + 'px Greycliff demibold'
		ctxRef.current.shadowColor = '#888'

		// space bar video control
		window.addEventListener('keydown', keyPressed)
		window.addEventListener('keyup', keyReleased)
		// check if canvas font is loaded
		document.fonts && document.fonts.addEventListener('loadingdone', fontsDidLoad) // not IE/Edge
		// cleanup on unmount
		return () => {
			cancelAnimationFrame(reqAnimIdRef.current)
			window.removeEventListener("keydown", keyPressed)
			window.removeEventListener("keyup", keyReleased)
			document.fonts && document.fonts.removeEventListener('loadingdone', fontsDidLoad) // not IE/Edge
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// update scenes array with extra properties for managing scenes & submitting matched frames
	useEffect(() => {
		scenesRef.current.map((scene,i) => {
			scene.last = !scenesRef.current[i+1]
			scene.startFrame = scene.frameNo
			scene.endFrame = scene.last ? video.frameCount-2 : scenesRef.current[i+1].frameNo
			scene.matched = matchedFrames.filter(fr => fr >= scene.startFrame && fr < scene.endFrame + scene.last)
			scene.selected = false
			scene.mouseOver = false
			scene.playheadOver = false
			return null
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// render video frame on component updates
	useEffect(() => {
		renderFrame(frameRef.current)
 	})

	// go to first frame of clicked bar graph segment and render video
	useEffect(() => {
		if (currentFrame) {
			pauseVideo()
			frameRef.current = Math.floor(currentFrame.frame) // hack - removes random value from currentFrame
			renderFrame(frameRef.current)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	}, [currentFrame])

	// fonts loaded - update canvas
	function fontsDidLoad(e) {
		renderFrame(frameRef.current)
	}

	// mouse down on player control - start drag (or click)
	function startDrag(e) {
		if (!chunksLoadedRef.current) { // prevent drag/play while preloading
			return
		}
		mouseDownX = e.clientX
		draggerX = parseFloat(document.getElementById('vcontrol-reuse').style.left, 10)
		window.addEventListener("mousemove", dragController)
		window.addEventListener("mouseup", stopDrag)
		isPlayingRef.current && pauseVideo(true)
	}

	// mouse up on player control - stop drag (or end click)
	function stopDrag(e) {
		cancelAnimationFrame(reqAnimIdRef.current)
		window.removeEventListener("mousemove", dragController)
		window.removeEventListener("mouseup", stopDrag)
		if (mouseDownX !== e.clientX) {
			isPlayingRef.current = true
		}
		toggleVideoPlay()
	}

	// drag controller
	function dragController(e) {
		lastMouseX = e.clientX
		reqAnimIdRef.current = requestAnimationFrame(updateController)
	}

	// update controller position
	function updateController() {
		let dist = lastMouseX - mouseDownX
		dist = dist < -draggerX ? -draggerX : dist > 581-draggerX ? 581-draggerX : dist
		const leftPos = draggerX + dist
		frameRef.current = Math.round(leftPos/581 * (video.frameCount-3))
		renderFrame(frameRef.current)
	}

	// toggle video play/pause
	function toggleVideoPlay() {
		cancelAnimationFrame(reqAnimIdRef.current)
		isPlayingRef.current = !isPlayingRef.current
		if (isPlayingRef.current) {
			reqAnimIdRef.current = requestAnimationFrame(playFrame)
		} else {
			renderFrame(frameRef.current)
		}
		document.getElementById('vcontrol-reuse').style.backgroundImage = 'url(' + (isPlayingRef.current ? videoPause : videoPlay) + ')'
	}

	// video play frame loop
	function playFrame() {
		reqAnimIdRef.current = requestAnimationFrame(playFrame)
		const now = Date.now()
		const delta = now - then
		if (delta > interval / speedSelectedRef.current) { // check if enough time has elapsed to execute
			then = now - delta % interval / speedSelectedRef.current
			++frameRef.current === video.frameCount-3 && pauseVideo() // video end - skipped last frame since it hasn’t any objects
			frameRef.current = frameRef.current > video.frameCount-3 ? 0 : frameRef.current
			renderFrame(frameRef.current)
		}
	}

	// pause video
	function pauseVideo(keepPlayState=false) {
		cancelAnimationFrame(reqAnimIdRef.current)
		if (!keepPlayState) {
			isPlayingRef.current = false
		}
		const videoControl = document.getElementById('vcontrol-reuse')
		if (videoControl) {
			videoControl.style.backgroundImage = 'url(' + videoPlay + ')'
		}
	}

	// handle key events
	function keyPressed(e) {
		if (spacePressed) {
			e.preventDefault()
		} else if (e.keyCode === 32) { // spacebar
			e.preventDefault()
			toggleSettings(null,false)
			if (!chunksLoadedRef.current) { // prevent keypress play while preloading
				return
			}
			spacePressed = true
			toggleVideoPlay()
		} else if (e.keyCode === 27) { // escape
			toggleSettings(null,false)
		} else if (e.keyCode === 37) { // left arrow
			isPlayingRef.current === true && pauseVideo()
			frameRef.current > 0 && renderFrame(--frameRef.current)
		} else if (e.keyCode === 39) { // right arrow
			isPlayingRef.current === true && pauseVideo()
			frameRef.current < video.frameCount-3 && renderFrame(++frameRef.current)
		}
	}

	// cancel spacebar pressed flag
	function keyReleased(e) {
		if (e.keyCode === 32) {
			spacePressed = false
		}
	}

	// cut frame image from chunk and paint it on canvas, draw objects, annotations and update controls/values
	function renderFrame(rframe) {
		if (!chunksLoadedRef.current) { // prevent rendering while preloading
			return
		}
		const ctx = ctxRef.current
		const chunk = Math.floor(rframe/30)
		const col = rframe % 5
		const row = Math.floor(rframe % 30 / 5)
		const w = chunkArrayRef.current[0].width/5
		const h = chunkArrayRef.current[0].height/6
		const selChunk = chunkArrayRef.current[chunk]
		ctx.drawImage(selChunk, w*col, h*row, w, h, 0, 0, canvasWidth, canvasHeight)

		if (matchedFrames.indexOf(rframe) > -1) { // only draw objects if they are in a matched frame

			// draw untagged objects if selected in settings
			if (showUntaggedRef.current) {
				const objects = unselectedObjectsRef.current.filter(obj => obj.frameNo === rframe)
				objects.map(obj => {
					// object box
					ctx.strokeStyle = '#fff'
					ctx.lineWidth = 1 * window.devicePixelRatio
					ctx.beginPath()
					ctx.rect(Math.round(obj.x * canvasWidth) + .5 * window.devicePixelRatio, Math.round(obj.y * canvasHeight) + .5 * window.devicePixelRatio, Math.round(obj.w * canvasWidth) - 2 * window.devicePixelRatio, Math.round(obj.h * canvasHeight) - 2 * window.devicePixelRatio)
					ctx.shadowBlur = 50
					ctx.stroke()
					ctx.shadowBlur = 0
					return 0
				})
				if (showLabelsRef.current) {
					objects.map(obj => {
						// annotations (on top of borders)
						let segment = video.segments.find(seg => seg.id === obj.segment)
						let segmentName = ''
						if (showLabelsRef.current) {
							segmentName += segment.name.slice(0,labelMaxChars)
						}
						const width = Math.ceil(ctx.measureText(segmentName).width + 14 * window.devicePixelRatio)
						let backX = Math.round(obj.x * canvasWidth)
						let backY = Math.round(obj.y * canvasHeight) - 16 * window.devicePixelRatio
						backX = backX + width > canvasWidth ? canvasWidth - width : backX
						backY = backY < 0 ? backY = 0 : backY
						ctx.fillStyle = 'rgba(255,255,255,.8)'
						ctx.fillRect(backX, backY, width, 17 * window.devicePixelRatio)
						ctx.fillStyle = '#2D3544'
						ctx.fillText(segmentName, backX + 7 * window.devicePixelRatio, backY + 12 * window.devicePixelRatio)
						return 0
					})
				}
			}

			// draw tagged objects if selected in settings
			if (showCuesRef.current) {
				const objects = selectedObjectsRef.current.filter(obj => obj.frameNo === rframe)
				objects.map(obj => {
					// object box
					ctx.strokeStyle = '#e5a01b'
					ctx.lineWidth = 2 * window.devicePixelRatio
					ctx.beginPath()
					ctx.rect(Math.round(obj.x * canvasWidth) + 1 * window.devicePixelRatio, Math.round(obj.y * canvasHeight) + 1 * window.devicePixelRatio, Math.round(obj.w * canvasWidth) - 2 * window.devicePixelRatio, Math.round(obj.h * canvasHeight) - 3 * window.devicePixelRatio)
					ctx.stroke()
					return 0
				})
				if (showLabelsRef.current || showCueTypesRef.current) {
					objects.map(obj => {
						// annotations (on top of borders)
						let segment = video.segments.find(seg => seg.id === obj.segment)
						let segmentName = ''
						if (showLabelsRef.current) {
							segmentName += segment.name.slice(0,labelMaxChars)
						}
						if (showCueTypesRef.current) {
							const cueType = cueTypes.find(cue => cue.type === segment.cueType).label
							segmentName += (showLabelsRef.current ? '  |  ' : '') + cueType
						}
						const width = Math.ceil(ctx.measureText(segmentName).width + 14 * window.devicePixelRatio)
						let backX = Math.round(obj.x * canvasWidth)
						let backY = Math.round(obj.y * canvasHeight) - 15 * window.devicePixelRatio
						backX = backX + width > canvasWidth ? canvasWidth - width : backX
						backY = backY < 0 ? backY = 0 : backY
						ctx.fillStyle = '#e5a01b'
						ctx.fillRect(backX, backY, width, 17 * window.devicePixelRatio)
						ctx.fillStyle = '#fff'
						ctx.fillText(segmentName, backX + 7 * window.devicePixelRatio, backY + 12 * window.devicePixelRatio)
						return 0
					})
				}
			}
		}

		// controls, score & time - showing minutes and hours as necessary
		const videoControl = document.getElementById('vcontrol-reuse')
		if (videoControl) {
			// position video player control knob and time container
			const left = 581/(video.frameCount-3) * rframe
			videoControl.style.left = left + 'px'

			// highlight corresponding scene bar if playhead is inside bar range
			let render = false
			const frameIsInScene = scenesRef.current.find(s => s.matched && s.matched.length > 0 && s.startFrame <= frameRef.current && s.endFrame > frameRef.current)
			for (let i=0; i<scenesRef.current.length; i++) {
				if (frameIsInScene && frameIsInScene.name === scenesRef.current[i].name) { // playhead is inside bar range
					if (scenesRef.current[i].playheadOver === false) {
						scenesRef.current[i].playheadOver = true
						render = true
					}
				} else if (scenesRef.current[i].playheadOver === true) {
					scenesRef.current[i].playheadOver = false
					render = true
				}
			}
			// re-render scene bars
			render && setForceUpdate(forceUpdate+Math.random()) // random = hack - +1 doesn’t work for some reason
		}
	}

	// cache image chunks
	if (video && !chunksLoadingRef.current && !chunksLoadedRef.current) {
		chunksLoadingRef.current = true;
		const tmpImg = new Image()
		tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames/' + video.guid + '-chunk' + ("0000000"+chunkArrayRef.current.length).slice(-7) + '.jpg'
		tmpImg.onload = checkLoaded
		chunkArrayRef.current.push(tmpImg)
	}

	// check that all chunks are loaded
	function checkLoaded(e) {
		e.target.onload = null
		numChunksLoaded ++
		setPercentLoaded(numChunksLoaded/video.chunks)
		if (numChunksLoaded === video.chunks) {
			setTimeout(function() { // delay so we can see progress bar finish animation
				chunksLoadedRef.current = true
				setForceUpdate(forceUpdate+1)
			}, 700)
		}
		if (chunkArrayRef.current.length < video.chunks) {
			const tmpImg = new Image()
			tmpImg.src = process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.guid + '/maps-frames/' + video.guid + '-chunk' + ("0000000"+chunkArrayRef.current.length).slice(-7) + '.jpg'
			tmpImg.onload = checkLoaded
			chunkArrayRef.current.push(tmpImg)
		}
	}

	// show/hide settings
	function toggleSettings(e, b=!settingsVisible) {
		setSettingsVisible(b)
	}

	// scene mouseover
	function sceneOver(e) {
		scenesRef.current.find(s => s.name === e.currentTarget.id).mouseOver = true
		setForceUpdate(forceUpdate+1)
	}

	// scene mouseout
	function sceneOut(e) {
		scenesRef.current.find(s => s.name === e.currentTarget.id).mouseOver = false
		setForceUpdate(forceUpdate+1)
	}

	// scene select/deselect
	function toggleScene(e,sc) {
		const scene = sc ? sc : scenesRef.current.find(s => s.name === e.currentTarget.id)
		scene.selected = !scene.selected
		selectedFramesRef.current = []
		scenesRef.current.map(scene => scene.selected && selectedFramesRef.current.push(...scene.matched))
		setSelectedFrames(selectedFramesRef.current)
	}

	// button mouseover
	function buttonOver(e,sel) {
		e.currentTarget.style.backgroundColor = sel ? '#3ed88b' : theme.backgroundColorSceneButtonReuseOver
	}

	// button mouseout
	function buttonOut(e,sel) {
		e.currentTarget.style.backgroundColor = sel ? '#39c481' : theme.backgroundColorSceneButtonReuseOut
	}

	// video canvas or preloader
	const preLoader = (!chunksLoadedRef.current &&
		<div style={loaderStyle}>
			<div style={loaderBarStyle}>
				<div style={{...loaderBarStyle, backgroundColor:'#39c481', width:(percentLoaded*100)+'%'}} />
			</div>
		</div>
	)

	// select button overlay
	const frameIsInScene = scenesRef.current.find(s => s.matched && s.matched.length > 0 && s.startFrame <= frameRef.current && s.endFrame > frameRef.current)
	const selectOverlay = (chunksLoadedRef.current && frameIsInScene &&
		<div style={selectOverlayStyle}>
			{frameIsInScene.selected ?
				<button className="fs-button" style={{...selectButtonStyle, backgroundColor:'#39c481', color:'#fff'}} onMouseOver={e=>buttonOver(e,true)} onMouseOut={e=>buttonOut(e,true)} onClick={e=>toggleScene(e,frameIsInScene)}>Deselect scene</button> :
				<button className="fs-button" style={{...selectButtonStyle, backgroundColor:theme.backgroundColorSceneButtonReuseOut, color:theme.textColor}} onMouseOver={e=>buttonOver(e,false)} onMouseOut={e=>buttonOut(e,false)} onClick={e=>toggleScene(e,frameIsInScene)}>Select scene</button>
			}
		</div>
	)

	// scene bars
	const frameWidth = 581/(video.frameCount-3)
	const sceneBars = chunksLoadedRef.current && scenesRef.current.map((scene,i) => {
		if (scene.matched.length > 0) { // use only scenes with matched frames
			const barLeft = frameWidth * scene.startFrame + 15 + 'px'
			const barWidth = frameWidth * (scene.endFrame - scene.startFrame) - (scene.last ? -1 : 1) + 'px'
			const bgColor = scene.selected ? scene.mouseOver || scene.playheadOver ? '#3ed88b' : '#39c481' : theme.backgroundColorSceneBarReuse
			const opacity = scene.selected || scene.mouseOver || scene.playheadOver ? '1' : '.6'
			return <div key={i} id={scene.name} style={{...sceneBarStyle, marginLeft:barLeft, width:barWidth, backgroundColor:bgColor, opacity:opacity}} onMouseOver={sceneOver} onMouseOut={sceneOut} onClick={toggleScene} title={scene.name+' - '+scene.matched.length+' matched frame'+(scene.matched.length!==1 ? 's' : '')} />
		}
		return null
	})

	// video settings
	const settings = (settingsVisible &&
		<AlertReuseVideoSettings
			reuseVideoSettings={reuseVideoSettings}
			setReuseVideoSettings={setReuseVideoSettings}
			toggleSettings={toggleSettings}
		/>
	)

	return (
		<div style={videoContainerStyle}>
			<div style={canvasContainerStyle}>
				<canvas style={canvasStyle} ref={canvasRef} width={canvasWidth} height={canvasHeight} />
			</div>
			{preLoader}
			{selectOverlay}
			{settings}
			<div style={sceneSelectorStyle}>
				{sceneBars}
			</div>
			<div style={labelStyle}>Click the scenes in the bar that you want to copy</div>
			<div style={controlsStyle}>
				<div style={lineStyle} />
				<img src={iconGear} style={gearStyle} onMouseUp={toggleSettings} alt="Video settings" title="Video settings" />
			</div>
			<div style={draggerStyle} onMouseDown={startDrag} id="vcontrol-reuse" />
		</div>
	)
}
