import React, { useState, useContext, useEffect } from 'react'
import { BeatLoader } from 'react-spinners'
import { APIContext } from '../services/api'
import DropDown from './DropDown'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'
import { industryOptions } from '../services/industryOptions'

// styles
const submitButtonStyle = {
	position: 'absolute',
	bottom: '39px',
	right: '120px',
	width: '100px',
	padding: '6px 20px 5px',
	fontSize: '14px',
	letterSpacing: '.2px'
}

const sumbitLoaderStyle = {
	position: 'absolute',
	bottom: '39px'
}

// component function
export default function AnalysisListItemDetails(props) {

	const { item, closeDetails, projects, brands, updateProjects, updateBrands, setAlertTask, alertObjectRef } = props
	item.brand = item.brand ? item.brand : { id:null, name:null }
	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [projectOptions, setProjectOptions] = useState(projects)
	const [brandOptions, setBrandOptions] = useState(brands)
	const [title, setTitle] = useState(item.title)
	const [project, setProject] = useState({ value:null, label:item.project.name })
	const [brand, setBrand] = useState({ value:null, label:item.brand.name })
	const [industry, setIndustry] = useState(industryOptions.find(ind => ind.value === item.industryCode))
	const [submitting, setSubmitting] = useState(false)
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]

	// dynamic styles
	const detailsContainerStyle = {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		width: '340px',
		height: '354px',
		margin: '0 15px 30px',
		backgroundColor: theme.backgroundColor2,
		cursor: 'default'
	}

	const inputStyle = {
		color: theme.textColor,
		backgroundColor: theme.backgroundColor3,
		borderColor: theme.inputBorderColor,
		marginBottom: '13px'
	}

	const closeButtonStyle = {
		position: 'absolute',
		top: '12px',
		right: '12px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.backgroundColor3,
		fontSize: '12px',
		color: theme.textColor,
		width: '20px',
		height: '20px',
		borderRadius: '11px',
		cursor: 'pointer'
	}

	// update object title and cue type on prop change
	useEffect(() => {
		setProjectOptions(projects)
		setBrandOptions(brands)
	}, [projects,brands])

	// menu item click handler
	function submitDetails(e) {
		setSubmitting(true)
		const videoUpdateRequest = {
			title: title,
			project: { name: project.label },
			brand: { name: brand.label },
			industryCode: industry.value,
			objectSegmentFilter: item.objectSegmentFilter,
		}
		// send details data
		const dontCalculate = industry.value === item.industryCode ? "?dont_calculate" : "" // industry not changed - we don’t need to recalculate
		context.io.socket.post('/api/v1/video/' + item.guid + dontCalculate, videoUpdateRequest, (data, res) => {
			if (res.statusCode === 200) {
				// refresh parent project- and/or brand list if new project and/or brand was created
				projectOptions.find(proj => proj.label === project.label) === undefined && updateProjects()
				brandOptions.find(br => br.label === brand.label) === undefined && updateBrands()
				// update item props
				item.title = title
				item.project.name = project.label
				item.brand.name = brand.label
				item.industryCode = industry.value
				// close
				setSubmitting(false)
				closeDetails(false)
			} else {
				setSubmitting(false)
				// TODO: error handling
			}
		})
	}

	// prevent click on background
	function cancelClick(e) {
		e.stopPropagation()
	}

	// close details overlay
	function closeDetailsOverlay() {
		// confirm close or save if changes
		if (canSubmit && !submitting) {
			alertObjectRef.current = { type:'confirm', title:'Save changes?'}
			setAlertTask(()=>(action)=>{ // define alert action and display alert
				if (action) {
					submitDetails()
				} else {
					// reset values
					setTitle(item.title)
					setProject({ value:null, label:item.project.name })
					setBrand({ value:null, label:item.brand.name })
					setIndustry(industryOptions.find(ind => ind.value === item.industryCode))
					closeDetails(false)
				}
				setAlertTask() // remove alert
			})
		} else {
			closeDetails(false)
		}
	}

	// enable button depending on data changed
	const canSubmit = title !== item.title || project.label !== item.project.name || (item.brand && brand.label !== item.brand.name) || industry.value !== item.industryCode

	// submit buttom or beatloader
	const submitButton = (submitting ?
		<div style={sumbitLoaderStyle}><BeatLoader color={'#39C481'} /></div> :
		<button style={submitButtonStyle} tabIndex="-1" disabled={!canSubmit} className="fs-button" onClick={submitDetails}>SAVE</button>
	)

	return (
		<div style={detailsContainerStyle} onClick={cancelClick}>
			<div style={closeButtonStyle} onClick={closeDetailsOverlay}>x</div>
			<h4 style={{marginBottom:'13px', color:theme.textColor}}>Analysis details</h4>
			<div>
				<input
					name="title"
					value={title}
					onChange={(e)=>setTitle(e.target.value)}
					className="fs-input-text-small"
					style={inputStyle}
					placeholder="Analysis title"
				/>
				<DropDown
					value={project}
					options={projectOptions}
					onChange={(label)=>setProject({ value:null, label:label })} // we only need project name (label) for submission
					placeholder="Project name"
					searchable={true}
					small={true}
				/>
				<div style={{height:'13px'}} />
				<DropDown
					value={brand.label ? brand : ''}
					options={brandOptions}
					onChange={(label)=>setBrand({ value:null, label:label })} // we only need brand name (label) for submission
					placeholder="Brand"
					searchable={true}
					small={true}
				/>
				<div style={{height:'13px'}} />
				<DropDown
					value={industry}
					options={industryOptions}
					onChange={(value)=>setIndustry(industryOptions.find(ind => ind.value === value))}
					placeholder="Industry"
					searchable={false}
					small={true}
				/>
				<div style={{height:'62px'}} />
			</div>
			{submitButton}
		</div>
	)
}
