import React, { useEffect, useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'

// styles
const menuItemStyle = {
	display: 'flex',
	alignItems: 'center',
	marginTop: '11px',
	cursor: 'pointer'
}

const speedSelectorStyle = {
	display: 'flex',
	alignItems: 'center',
	marginTop: '13px'
}

// highlight speed link
function onOver(e) {
	e.currentTarget.style.opacity = '1'
}

// dim speed link
function onOut(e) {
	e.currentTarget.style.opacity = '.5'
}

// component function
export default function AnalysisCueVideoSettings(props) {

	const { cueVideoSettings, setCueVideoSettings, toggleSettings } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const speedArray = [1, .5, .25]

	// dynamic styles
	const menuContainerStyle = {
		width: '170px',
		height: '164px',
		position: 'absolute',
		right: '0',
		bottom: '87px',
		padding: '3px 0 3px 15px',
		boxSizing: 'border-box',
		backgroundColor: theme.backgroundColorVideoSettings,
		zIndex: '800'
	}

	const labelStyle = {
		marginBottom: '-2px',
		color: theme.textColor
	}

	const speedLabelStyle = {
		height: '19px',
		margin: '0 7px 0 -1px',
		paddingBottom: '1px',
		color: theme.textColor
	}

	const speedButtonStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '34px',
		height: '19px',
		borderRadius: '19px',
		backgroundColor: 'rgba(255,255,255,' + (globalState.userData && globalState.userData.settings && globalState.userData.settings.theme !== 0 ? '.2' : '1') + ')',
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		padding: '1px 0 0',
		color: theme.textColor
	}

	const speedTextStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '34px',
		height: '19px',
		opacity: '.5',
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		padding: '1px 0 0',
		cursor: 'pointer',
		transition: 'opacity .2s',
		color: theme.textColor
	}

	const checkBoxStyle = {
		width: '18px',
		height: '18px',
		border: '4px solid ' + theme.checkboxBackgroundColor,
		backgroundColor: theme.checkboxCheckedColor,
		boxSizing: 'border-box',
		marginRight: '8px',
		transition: 'background-color .2s'
	}

	// set up settings toggle listeners on mount
	useEffect(() => {
		document.addEventListener('mouseup', toggleSettings)
		document.getElementById('menuContainer').addEventListener('mouseup', ignoreHide)
		// cleanup on unmount
		return () => {
			document.removeEventListener('mouseup', toggleSettings)
			document.getElementById('menuContainer').removeEventListener('mouseup', ignoreHide)
		}
 	},[toggleSettings])

	// ignore hide settings if click is inside settings
	function ignoreHide(e) {
		e.stopPropagation()
	}

	// toggle tagged visibility
	function toggleTagged() {
		setCueVideoSettings({...cueVideoSettings, showTagged:!cueVideoSettings.showTagged})
	}

	// toggle untagged visibility
	function toggleUntagged() {
		setCueVideoSettings({...cueVideoSettings, showUntagged:!cueVideoSettings.showUntagged})
	}

	// toggle labels visibility
	function toggleLabels() {
		setCueVideoSettings({...cueVideoSettings, showLabels:!cueVideoSettings.showLabels})
	}

	// toggle cue types visibility
	function toggleCueTypes() {
		setCueVideoSettings({...cueVideoSettings, showCueTypes:!cueVideoSettings.showCueTypes})
	}

	// set video playback speed
	function selectSpeed(speed) {
		setCueVideoSettings({...cueVideoSettings, speedSelected:speed})
	}

	const taggedCheckBoxStyle = cueVideoSettings.showTagged ? checkBoxStyle : { ...checkBoxStyle, backgroundColor:  theme.checkboxBackgroundColor }
	const untaggedCheckBoxStyle = cueVideoSettings.showUntagged ? checkBoxStyle : { ...checkBoxStyle, backgroundColor: theme.checkboxBackgroundColor }
	const labelsCheckBoxStyle = cueVideoSettings.showLabels ? checkBoxStyle : { ...checkBoxStyle, backgroundColor:  theme.checkboxBackgroundColor }
	const cueTypesCheckBoxStyle = cueVideoSettings.showCueTypes ? checkBoxStyle : { ...checkBoxStyle, backgroundColor:  theme.checkboxBackgroundColor }

	const speedButtons = speedArray.map((speed,i) => {
		const speedString = '1/' + (1/speed)
		const btn = (speed === cueVideoSettings.speedSelected ?
			<div key={i} style={speedButtonStyle}>{speedString}</div> :
			<div key={i} style={speedTextStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={(e)=>selectSpeed(speed)}>{speedString}</div>
		)
		return btn
	})

	return (
		<div id="menuContainer" style={menuContainerStyle}>
			<div style={menuItemStyle} onClick={toggleTagged}>
				<div style={taggedCheckBoxStyle} />
				<div style={labelStyle}>Tagged objects</div>
			</div>
			<div style={menuItemStyle} onClick={toggleUntagged}>
				<div style={untaggedCheckBoxStyle} />
				<div style={labelStyle}>Untagged objects</div>
			</div>
			<div style={menuItemStyle} onClick={toggleLabels}>
				<div style={labelsCheckBoxStyle} />
				<div style={labelStyle}>Object labels</div>
			</div>
			<div style={menuItemStyle} onClick={toggleCueTypes}>
				<div style={cueTypesCheckBoxStyle} />
				<div style={labelStyle}>Cue types</div>
			</div>
			<div style={speedSelectorStyle}>
				<div style={speedLabelStyle}>fps:</div>
				{speedButtons}
			</div>
		</div>
	)
}
