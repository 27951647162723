import React, { useEffect, useContext, useState } from 'react'
import { APIContext } from './services/api'
import { GlobalContext } from './services/globalState'
import { Route, Switch, useHistory } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'
import Analyses from './Analyses'
import Report from './Report'
import UserGuide from './UserGuide'
import Login from './Login'
import Account from './Account'
import NotFound from './NotFound'
import Header from './components/Header'
import Footer from './components/Footer'
import AlertWelcome from './components/AlertWelcome'
import AlertTerms from './components/AlertTerms'
import { themes } from './themes/themes'

// component function
export default function App() {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const context = useContext(APIContext)
	const history = useHistory()
	const [welcomeAlertTask, setWelcomeAlertTask] = useState()
	const [termsAlertTask, setTermsAlertTask] = useState()

	// check if user is logged in
	useEffect(() => {
		context.io.socket.get('/api/v1/user', (data, res) => {
			if (res.statusCode === 200) {
				// initialize pseudo-class elements that can’t be styled directly with javascript - using css variables:
				let settings = data.settings
				const theme = settings && settings.theme
				if (theme) {
					document.documentElement.style.setProperty('--scrollbar-color', themes[theme].scrollbarColor) // scrollbar pseudo-class fix - won’t work in IE11 but scrollbar colors don’t anyway
					document.documentElement.style.setProperty('--scrollbar-thumb-color', themes[theme].scrollbarThumbColor)
					document.documentElement.style.setProperty('--scrollbar-thumb-hover-color', themes[theme].scrollbarThumbHoverColor)
					document.documentElement.style.setProperty('--input-placeholder-color', themes[theme].inputPlaceholderColor) // input placeholder pseudo-class fix - won’t work in IE11
					document.documentElement.style.backgroundColor = themes[theme].backgroundColor0
					document.body.style.backgroundColor = themes[theme].backgroundColor0
				}
				setGlobalState({ loggedIn:true, userData:{ ...data, settings:settings } })
				// show welcome message on first login
				const introDone = settings && settings.introDone
				if (!introDone && data.id > 60) { // only on newer user profiles
					showWelcome()
				} else if (data.permissionType1 !== '1') { // haven’t accepted terms (if user was added by team manager)
					showTermsAccept()
				}
			} else {
				setGlobalState({ loggedIn:false })
				if (history.location.pathname.toLowerCase().indexOf('/shared') < 0 && history.location.pathname.toLowerCase().indexOf('/notfound') < 0) { // go to login unless you’re on publicly accessible paths
					if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
						history.push('/login')
					} else {
						window.location.href = process.env.REACT_APP_PUBLIC_URL
					}
				}
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.io.socket, globalState.loggedIn, setGlobalState, history])

	// show welcome alert
	function showWelcome() {
		setWelcomeAlertTask(()=>(action)=>{ // show welcome message
			const settings = { ...globalState.userData.settings, introDone:true }
			context.io.socket.patch('/api/v1/user', { settings:settings }, (data, res) => { // save introDone flag in user settings
				if (res.statusCode === 200) {
					setGlobalState({ ...globalState, userData:{ ...data, settings:settings } })
					if (data.permissionType1 !== '1') { // haven’t accepted terms (if user was added by team manager)
						showTermsAccept()
					}
				} else {
					// TODO: error handling
				}
			})
			setWelcomeAlertTask()
		})
	}

	// show terms accept alert
	function showTermsAccept() {
		setTermsAlertTask(()=>(action)=>{ // show terms message
			if (action) { // terms accepted
				context.io.socket.patch('/api/v1/user', { permissionType1:'1' }, (data, res) => { // save terms accepted on user profile
					if (res.statusCode === 200) {
						setGlobalState({ ...globalState, userData:{ ...data, permissionType1:'1' } })
					} else {
						// TODO: error handling
					}
				})
			} else { // not accepted - log out
				logout()
			}
			setTermsAlertTask()
		})
	}

	// log out
	function logout() {
		context.io.socket.post('/api/v1/user/logout', (data, res) => {
			if (res.statusCode === 200) {
				setGlobalState({ loggedIn: false })
				if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
					history.push('/login')
				} else {
					window.location.href = process.env.REACT_APP_PUBLIC_URL
				}
			} else {
				// TODO: error handling
			}
		})
	}

	const welcomeAlert = welcomeAlertTask && <AlertWelcome action={welcomeAlertTask} />
	const termsAlert = termsAlertTask && <AlertTerms action={termsAlertTask} />

	return (
		<>
			<Header />
			<ScrollToTop>
				<Switch>
					<Route path="/shared/:guid/:code" component={Report} />
					<Route path="/shared/:guid" component={NotFound} />
					<Route path="/shared" component={NotFound} />
					<Route path="/report/:guid" component={Report} />
					<Route path="/userguide" component={UserGuide} />
					<Route path="/login" component={Login} />
					<Route path="/account" component={Account} />
					<Route path="/notfound" component={NotFound} />
					<Route exact path="/:guid" component={Analyses} />
					<Route exact path="/" component={Analyses} />
					<Route component={NotFound} />
				</Switch>
			</ScrollToTop>
			<Footer />
			{welcomeAlert}
			{termsAlert}
		</>
	)
}
