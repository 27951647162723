import React, { useContext } from 'react'
import { GlobalContext } from '../services/globalState'
import { themes } from '../themes/themes'

// styles
const barContainerStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	margin: '-6px 0 -5px'
}

const tickContainerStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	width: '515px',
	margin: '0 auto',
}

// component function
export default function ReportBar(props) {

	const { label, score, max, cueType } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const scoreText = score.toFixed(2) // score w 2 decimals
	const labelMaxChars = 28
	const labelText = label === null || label === '' || label.replace(/\s\s+/g, ' ') === ' ' ? '[no name]' : label.slice(0,labelMaxChars)
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]

	// dynamic styles
	const backBarStyle = {
		minWidth: '525px',
		height: '11px',
		backgroundColor: theme.progressBarBackColor,
		borderRadius: '6px'
	}

	const labelStyle = {
		width: '258px',
		textAlign: 'right',
		margin: '2px 20px 0 -228px',
		whiteSpace: 'nowrap',
		color: theme.textColor
	}

	const scoreStyle = {
		width: '30px',
		minWidth: '30px',
		textAlign: 'left',
		margin: '2px 0 0 20px',
		color: theme.textColor
	}

	const cueTypeStyle = {
		width: '0',
		overflow: 'visible',
		margin: '4px -17px 0 17px',
		whiteSpace: 'nowrap',
		textAlign: 'left',
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		color: theme.textColor,
		opacity: '.5',
	}

	const frontBarStyle = {
		height: '11px',
		backgroundColor: theme.progressBarColor,
		borderRadius: '6px',
		opacity: '.75',
		width: Math.min(score*100/max, 100) + '%'
	}

	const tickStyle = {
		width: '1px',
		height: '4px',
		borderLeft: '1px solid ' + theme.analysisScoreTickColor,
		boxSizing: 'border-box',
		margin: '2px 0'
	}

	return (
		<>
			<div style={tickContainerStyle}>
				<div style={tickStyle} />
				<div style={tickStyle} />
			</div>
			<div style={barContainerStyle}>
				<div style={labelStyle}>{labelText}</div>
				<div style={backBarStyle}>
					<div style={frontBarStyle} />
				</div>
				<div style={scoreStyle}>{scoreText}</div>
				<div style={cueTypeStyle}>{cueType}</div>
			</div>
			<div style={tickContainerStyle}>
				<div style={tickStyle} />
				<div style={tickStyle} />
			</div>
		</>
	)
}
