import React, { useState, useEffect, useRef, useContext } from 'react'
import { APIContext } from '../services/api'
import { GlobalContext } from '../services/globalState'
import { BeatLoader } from 'react-spinners'
import { themes } from '../themes/themes'
import useCueTypes from '../services/useCueTypes'
import AnalysisCueVideoToolbar from './AnalysisCueVideoToolbar'
import AnalysisCueVideoSettings from './AnalysisCueVideoSettings'
import AnalysisCueVideoObject from './AnalysisCueVideoObject'
import Alert from './Alert'
import AlertReuse from './AlertReuse'
import useLocalStorage from '../services/useLocalStorage'
import iconVideoLarge from '../assets/images/icon-video-large.svg'
import iconVideoSmall from '../assets/images/icon-video-small.svg'
import iconGear from '../assets/images/icon-gear.svg'
import videoPause from '../assets/images/video-pause.svg'
import videoPlay from '../assets/images/video-play.svg'

// styles
const controlsStyle = {
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	marginTop: '18px'
}

const cueMarkerContainerStyle = {
	height: '2px'
}

const cueMarkerStyle = {
	height: '3px',
	marginBottom: '-3px'
}

// component function
export default function AnalysisCueVideo(props) {

	const { chunks, video, goFrame, toolSelected, selectedObject={frameNo:0}, origSegments, refreshVideo, showObjectList, tweenObjectsInSegment, deleteSelectedSegment, createSegment, maxWidth, forceUpdate, objectsToUpdate, updateVideo } = props
	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [settingsVisible, setSettingsVisible] = useState(false)
	const [detectingObject, setDetectingObject] = useState(false)
	const [selectedTool, setSelectedTool] = useState('edit')
	const [currentFrame, setCurrentFrame] = useState(0)	// eslint-disable-line no-unused-vars
	const [alertTask, setAlertTask] = useState()
	const [showReuse, setShowReuse] = useState(false)
	const [reuseVideos, setReuseVideos] = useState()
	const [cueVideoSettings, setCueVideoSettings] = useLocalStorage('cueVideoSettings', { showTagged:true, showUntagged:true, showLabels:true, showCueTypes:false, showList:false, speedSelected:1 })
	const alertObjectRef = useRef(null)
	const selectedToolRef = useRef(cueVideoSettings.selectedTool)
	const showTaggedRef = useRef(cueVideoSettings.showTagged)
	const showUntaggedRef = useRef(cueVideoSettings.showUntagged)
	const showLabelsRef = useRef(cueVideoSettings.showLabels)
	const showCueTypesRef = useRef(cueVideoSettings.showCueTypes)
	const speedSelectedRef = useRef(cueVideoSettings.speedSelected)
	const maxWidthRef = useRef(maxWidth)
	const canvasRef = useRef()
	const ctxRef = useRef()
	const reqAnimIdRef = useRef()
	const isPlayingRef = useRef(false)
	const selectedSegmentRef = useRef(selectedObject.segment)
	const frameRef = useRef(selectedObject.frameNo)
	const plusPressedRef = useRef(false)
	const isConfirmingRef = useRef(false)
	const isDrawingRef = useRef(false)
	const drawBeginRef = useRef()
	const drawEndRef = useRef()
	const tagBoxVisibleRef = useRef()
	const canvasWidthRef = useRef()
	const canvasHeightRef = useRef()
	const scaleRef = useRef()
	const videoRef = useRef()
	const labelArrayRef = useRef()
	const optionsVisibleRef = useRef(false)
	const tweenObjectsRef = useRef([])
	const displayFrameNoRef = useRef(false)
	const showingReuseRef = useRef(false)
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]
	const cueTypes = useCueTypes()

	// calculate video canvas- & css sizes
	const processedWidth = video.width * video.processing_scale
	const processedHeight = video.height * video.processing_scale * (1/video.streamInfo.pixelAspectRatio)
	let canvasWidth = 640 * window.devicePixelRatio
	let canvasHeight = 360 * window.devicePixelRatio
	let canvasWidthCss = maxWidth
	let canvasHeightCss = maxWidth/640*360
	if (maxWidth < 640) { // small + list
		canvasWidth = processedWidth * window.devicePixelRatio
		canvasHeight = processedHeight * window.devicePixelRatio
		canvasWidthCss = Math.ceil(processedWidth < maxWidth ? processedWidth : maxWidth)
		canvasHeightCss = Math.ceil(canvasHeight/canvasWidth * canvasWidthCss)
	} else { // large & no list
		if (processedHeight/processedWidth > 360/640) {
			canvasWidthCss = Math.ceil(processedWidth/processedHeight * canvasHeightCss)
		}
		canvasWidth = canvasWidthCss * window.devicePixelRatio
		canvasHeight = canvasHeightCss * window.devicePixelRatio
	}

	const w = chunks[0].width/5
	const h = chunks[0].height/6
	const interval = 1000/video.frameRate
	const labelMaxChars = 28

	// set refs (for js functions that don’t get state)
	selectedToolRef.current = selectedTool
	showTaggedRef.current = cueVideoSettings.showTagged
	showUntaggedRef.current = cueVideoSettings.showUntagged
	showLabelsRef.current = cueVideoSettings.showLabels
	showCueTypesRef.current = cueVideoSettings.showCueTypes
	speedSelectedRef.current = cueVideoSettings.speedSelected
	maxWidthRef.current = maxWidth
	canvasWidthRef.current = canvasWidth
	canvasHeightRef.current = canvasHeight
	scaleRef.current = canvasWidth/canvasWidthCss
	videoRef.current = video

	// tag box visibility
	const isTagged = video.segments.findIndex(seg => seg.id === selectedObject.segment && seg.cueType != null) > -1
	tagBoxVisibleRef.current = selectedObject.segment !== undefined && ((showTaggedRef.current && isTagged) || (showUntaggedRef.current && !isTagged))

	// set frame ref on render
	const isInScope = video.objects.filter(obj => obj.segment === selectedObject.segment).find(obj => obj.frameNo === frameRef.current) !== undefined // does current frame contain an object with same segment as the selected object? then no need to change frame
	frameRef.current = (
		selectedObject.hide || isInScope || selectedObject.segment === undefined ? frameRef.current :
		selectedObject.segment !== selectedSegmentRef.current ? selectedObject.frameNo :
		frameRef.current
	)

	// dynamic styles
	const draggerStyle = {
		width: '30px',
		height: '30px',
		backgroundImage: 'url(' + videoPlay + ')',
		cursor: 'pointer',
		position: 'relative',
		top: '-23px',
		left: '0',
		transition: 'background-image .3s'
	}

	const canvasStyle = {
		width: canvasWidthCss,
		height: canvasHeightCss,
		display: 'block',
		margin: '0 auto',
		boxShadow: globalState.userData && globalState.userData.settings && globalState.userData.settings.theme === 1 ? 'none' : '0 0 0 1px rgba(45,53,68,.1)'
	}

	const loaderBackStyle = {
		position: 'relative',
		width: canvasWidthCss,
		height: canvasHeightCss,
		marginTop: -canvasHeightCss,
	}

	const lineStyle = {
		width: '100%',
		height: '1px',
		backgroundColor: theme.backgroundColorVideoLine,
		marginRight: '12px'
	}

	const timeStyle = {
		width: '90px',
		position: 'relative',
		top: '-18px',
		left: '-30px',
		textAlign: 'center',
		cursor: 'pointer',
		color: theme.textColor
	}

	const gearStyle = {
		cursor: 'pointer',
		opacity: globalState.userData && globalState.userData.settings && globalState.userData.settings.theme !== 0 ? '1' : '.5'
	}

	let then = Date.now()
	let mouseDownX = 0
	let lastMouseX = 0
	let lastMouseY = 0
	let draggerX = 0
	let spacePressed = false

	// context- & canvas refs & other inits on mount
	useEffect(() => {
		// canvas setup
		ctxRef.current = canvasRef.current.getContext('2d')
		ctxRef.current.imageSmoothingEnabled = true
		// space bar video control
		window.addEventListener('keydown', keyPressed)
		window.addEventListener('keyup', keyReleased)
		// check if canvas font is loaded
		document.fonts && document.fonts.addEventListener('loadingdone', fontsDidLoad) // not IE/Edge
		// cleanup on unmount
		return () => {
			cancelAnimationFrame(reqAnimIdRef.current)
			window.removeEventListener('keydown', keyPressed)
			window.removeEventListener('keyup', keyReleased)
			document.fonts && document.fonts.removeEventListener('loadingdone', fontsDidLoad) // not IE/Edge
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// listen for object detection result
	useEffect(() => {
		context.io.socket.on('video-object-detect-' + video.guid, msg => {
			resetDraw()
			setDetectingObject(false) // hide loader
			// update analysis status
			video.frontendState = { ...video.frontendState, status:'work-in-progress' }
			// send analysis status
			context.io.socket.post('/api/v1/video/' + video.guid + '?dont_calculate', {frontendState: video.frontendState}, (data, res) => {
				if (res.statusCode === 200) {
					refreshVideo(msg) // refresh video w new segment & objects
				} else {
					// TODO: error handling
				}
			})
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[context.io.socket, video])

	// render video frame on all component updates
	useEffect(() => {
		selectedSegmentRef.current = selectedObject.segment
		renderFrame(frameRef.current)
 	})

	// toggle list visibility depending on user setting
	useEffect(() => {
		showObjectList(cueVideoSettings.showList) // parent call
		toggleSettings(null,false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[cueVideoSettings.showList])

	// get video list and filter on matching guids if we got scene match data
	/*useEffect(() => {
		video && video.perceptualHashMatchData !== null && Object.keys(video.perceptualHashMatchData.matches).length > 0 && context.io.socket.get('/api/v1/organization/video', (data, res) => {
			if (res.statusCode === 200) {
				const matchingVideos = data.filter(item => Object.keys(video.perceptualHashMatchData.matches).indexOf(item.guid) > -1 && item.frontendState && (item.frontendState.status === 'finished' || item.frontendState.status === 'work-in-progress')) // only matched analyses with 'finished' or 'work-in-progress' frontendState
				setReuseVideos(matchingVideos.sort((a, b) => video.perceptualHashMatchData.matches[b.guid] - video.perceptualHashMatchData.matches[a.guid] || b.createdAt - a.createdAt)) // sort by highest percentage + date as secondary sort
				if (matchingVideos.length > 0) {
					if ((!video.frontendState || !video.frontendState.reuseDialogueShown) && showReuse === false) {
						showingReuseRef.current = true
						setShowReuse(true)
						// save flag for shown reuse dialogue to the video’s frontendState object so the dialogue will only be shown once
						context.io.socket.post('/api/v1/video/' + video.guid + '?dont_calculate', {frontendState:{...video.frontendState, reuseDialogueShown:true}}, (data, res) => {
							if (!res.statusCode === 200) {
								// TODO: error handling
								console.log(res)
							}
						})
					}
				}
			} else {
				// TODO: error handling
				console.log(res)
			}
		})
	}, [context.io.socket, video, showReuse])*/

	// fonts loaded - update canvas
	function fontsDidLoad(e) {
		renderFrame(frameRef.current)
	}

	// select object on canvas (if visible)
	function selectObject(e) {
		const tolerance = .01
		const rect = canvasRef.current.getBoundingClientRect()
		const clickX = e.clientX - rect.left
		const clickY = e.clientY - rect.top
		const xVal = clickX/rect.width
		const yVal = clickY/rect.height
		const objectsInFrame = video.objects.filter(obj => obj.frameNo === frameRef.current)
		// is click on an object label?
		const clickedLabel = labelArrayRef.current.reverse().find(lbl => (clickX > lbl.x && clickX < lbl.x+lbl.w && clickY > lbl.y && clickY < lbl.y+lbl.h))
		let selObject = clickedLabel && clickedLabel.obj
		// if no label clicked, is click on an object border inside tolerance?
		if (!selObject) {
			selObject = objectsInFrame.find(obj =>
				(Math.abs(obj.x-xVal) < tolerance && obj.y-tolerance < yVal && obj.y+obj.h+tolerance > yVal) ||
				(Math.abs(obj.x+obj.w-xVal) < tolerance  && obj.y-tolerance < yVal && obj.y+obj.h+tolerance > yVal) ||
				(Math.abs(obj.y-yVal) < tolerance && obj.x-tolerance < xVal && obj.x+obj.w+tolerance > xVal) ||
				(Math.abs(obj.y+obj.h-yVal) < tolerance && obj.x-tolerance < xVal && obj.x+obj.w+tolerance > xVal)
			)
		}
		if (selObject) {
			const segment = video.segments.find(seg => seg.id === selObject.segment)
			const tagged = segment.cueType !== null
			const objectVisible = (tagged && showTaggedRef.current) || (!tagged && showUntaggedRef.current)
			objectVisible && goFrame(e, selObject)
		} else {
			goFrame(e, {frameNo:0}) // deselect
		}
	}

	// mouse down on player control - start drag (or click)
	function startDrag(e) {
		mouseDownX = e.clientX
		draggerX = parseFloat(document.getElementById('vcontrol').style.left, 10)
		window.addEventListener("mousemove", dragController)
		window.addEventListener("mouseup", stopDrag)
		isPlayingRef.current && pauseVideo(true)
	}

	// mouse up on player control - stop drag (or end click)
	function stopDrag(e) {
		cancelAnimationFrame(reqAnimIdRef.current)
		window.removeEventListener("mousemove", dragController)
		window.removeEventListener("mouseup", stopDrag)
		if (mouseDownX !== e.clientX) {
			isPlayingRef.current = true
		}
		toggleVideoPlay()
	}

	// drag controller
	function dragController(e) {
		lastMouseX = e.clientX
		reqAnimIdRef.current = requestAnimationFrame(updateController)
	}

	// update controller position
	function updateController() {
		let dist = lastMouseX - mouseDownX
		dist = dist < -draggerX ? -draggerX : dist > (maxWidth-81)-draggerX ? (maxWidth-81)-draggerX : dist
		const leftPos = draggerX + dist
		frameRef.current = Math.round(leftPos/(maxWidth-81) * (video.frameCount-3))
		selectedSegmentRef.current ? setCurrentFrame(frameRef.current) : renderFrame(frameRef.current) // force component update or just render video
	}

	// toggle video play/pause
	function toggleVideoPlay() {
		cancelAnimationFrame(reqAnimIdRef.current)
		isPlayingRef.current = !isPlayingRef.current
		if (isPlayingRef.current) {
			reqAnimIdRef.current = requestAnimationFrame(playFrame)
		}
		document.getElementById('vcontrol').style.backgroundImage = 'url(' + (isPlayingRef.current ? videoPause : videoPlay) + ')'
	}

	// video play frame loop
	function playFrame() {
		reqAnimIdRef.current = requestAnimationFrame(playFrame)
		const now = Date.now()
		const delta = now - then
		if (delta > interval / speedSelectedRef.current) { // check if enough time has elapsed to execute
			then = now - delta % interval / speedSelectedRef.current
			++frameRef.current === video.frameCount-3 && pauseVideo() // video end - skipped last frame since it hasn’t any objects
			frameRef.current = frameRef.current > video.frameCount-3 ? 0 : frameRef.current
			selectedSegmentRef.current ? setCurrentFrame(frameRef.current) : renderFrame(frameRef.current) // force component update or just render video
		}
	}

	// pause video
	function pauseVideo(keepPlayState=false) {
		cancelAnimationFrame(reqAnimIdRef.current)
		if (!keepPlayState) {
			isPlayingRef.current = false
		}
		const videoControl = document.getElementById('vcontrol')
		if (videoControl) {
			videoControl.style.backgroundImage = 'url(' + videoPlay + ')'
		}
	}

	// handle key pressed events
	function keyPressed(e) {
		if (spacePressed) {
			e.preventDefault()
		} else if (e.keyCode === 32) { // spacebar
			e.preventDefault()
			if (showingReuseRef.current === true) return // don’t play video if reuse overlay is active
			spacePressed = true
			toggleVideoPlay()
			toggleSettings(null,false)
		} else if (e.keyCode === 27 && !document.getElementById('overlay')) { // escape if alert overlay is not visible
			toggleSettings(null,false)
			selectedSegmentRef.current && goFrame(e, {frameNo:0}) // deselect object
		} else if (e.keyCode === 37) { // left arrow
			if (showingReuseRef.current === true) return // don’t use arrow keys in video if reuse overlay is active
			isPlayingRef.current === true && pauseVideo()
			selectedSegmentRef.current ? frameRef.current > 0 && setCurrentFrame(--frameRef.current) : frameRef.current > 0 && renderFrame(--frameRef.current) // force component update or just render video
		} else if (e.keyCode === 39) { // right arrow
			if (showingReuseRef.current === true) return // don’t use arrow keys in video if reuse overlay is active
			isPlayingRef.current === true && pauseVideo()
			selectedSegmentRef.current ? frameRef.current < videoRef.current.frameCount-3 && setCurrentFrame(++frameRef.current) : frameRef.current < videoRef.current.frameCount-3 && renderFrame(++frameRef.current) // force component update or just render video
		} else if (e.keyCode === 187) { // plus sign
			isDrawingRef.current = true
			plusPressedRef.current = true
			document.getElementById('canvas').style.cursor = 'crosshair'
		}
	}

	// handle key released events
	function keyReleased(e) {
		if (e.keyCode === 8) { // backspace
			deleteSegment()
		} else if (e.keyCode === 32) { // space
			spacePressed = false
		} else if (e.keyCode === 187) { // plus key
			plusPressedRef.current = false
			if (selectedToolRef.current !== 'detect' && selectedToolRef.current !== 'draw') {
				document.getElementById('canvas').style.cursor = 'default'
			}
			onUp(e)
		}
	}

	// delete selected segment or range of objects in segment
	function deleteSegment(dir) {
		selectedSegmentRef.current && deleteSelectedSegment(selectedSegmentRef.current, frameRef.current, dir)
	}

	// mouse down on canvas - start draw if plus sign is pressed or draw tool is selected
	function onDown(e) {
		pauseVideo()
		window.addEventListener('mouseup', onUp)
		window.addEventListener('mousemove', onMove)
		if (isDrawingRef.current) {
			const rect = canvasRef.current.getBoundingClientRect()
			drawBeginRef.current = { x:(e.clientX-rect.left)/rect.width, y:(e.clientY-rect.top)/rect.height }
		}
	}

	// mouse move - draw if plus sign is pressed or draw tool selected
	function onMove(e) {
		if (isConfirmingRef.current === true) return
		lastMouseX = e.clientX
		lastMouseY = e.clientY
		reqAnimIdRef.current = requestAnimationFrame(updateDraw)
	}

	// update drawing
	function updateDraw() {
		if (isDrawingRef.current && drawBeginRef.current) {
			const rect = canvasRef.current.getBoundingClientRect()
			const drawX = lastMouseX < rect.left ? rect.left : lastMouseX > rect.left+rect.width ? rect.left+rect.width : lastMouseX
			const drawY = lastMouseY < rect.top ? rect.top : lastMouseY > rect.top+rect.height ? rect.top+rect.height : lastMouseY
			drawEndRef.current = { x:(drawX-rect.left)/rect.width, y:(drawY-rect.top)/rect.height }
			renderFrame(frameRef.current)
		}
	}

	// mouse up: send coords to backend (auto-detect), draw object or click
	function onUp(e) {
		window.removeEventListener('mouseup', onUp)
		window.removeEventListener('mousemove', onMove)
		if (isConfirmingRef.current === true) return
		if (drawBeginRef.current && drawEndRef.current && Math.abs(drawBeginRef.current.x - drawEndRef.current.x) * canvasWidthCss > 5 && Math.abs(drawBeginRef.current.y - drawEndRef.current.y) * canvasHeightCss > 5 ) { // drawn box min size 5x5
			isConfirmingRef.current = true
			if (selectedToolRef.current === 'detect' || e.keyCode === 187 || plusPressedRef.current === true) {
				// auto-detect
				alertObjectRef.current = { type:'confirm', title:'Detect this object?', message:'It will appear in the ’Custom objects’ section and will automatically be saved to the video when processed.'}
				setAlertTask(()=>(action)=>{ // define alert action and display alert
					if (action) {
						setDetectingObject(true) // show loader
						context.io.socket.post('/api/v1/video/detect/' + video.guid, detectObject(), (data, res) => { // send detect coords to backend - return data is handled by 'video-object-detect-' socket event listener
							if (res.statusCode !== 200) {
								// TODO: error handling
							}
						})
					} else {
						resetDraw()
					}
					isConfirmingRef.current = false
					setAlertTask() // remove alert
				})
			} else if (selectedToolRef.current === 'draw') {
				// draw new custom object
				alertObjectRef.current = { type:'confirm', title:'Draw a custom object that spans the current and all subsequent video frames?'}
				setAlertTask(()=>(action)=>{ // define alert action and display alert
					if (action) {
						createSegment(detectObject())
					}
					resetDraw()
					isConfirmingRef.current = false
					setAlertTask() // remove alert
				})
			}
		} else { // click
			selectObject(e)
			resetDraw()
		}
	}

	// reset drawing values
	function resetDraw() {
		renderFrame(frameRef.current) // remove drawn
		if (selectedToolRef.current !== 'detect' && selectedToolRef.current !== 'draw') {
			isDrawingRef.current = false
			document.getElementById('canvas').style.cursor = 'default'
		}
		drawBeginRef.current = null
		drawEndRef.current = null
	}

	// create object for custom drawing and detection
	function detectObject() {
		const detectObj = {
			object: {
				x: Math.min(drawBeginRef.current.x, drawEndRef.current.x),
				y: Math.min(drawBeginRef.current.y, drawEndRef.current.y),
				w: Math.abs(drawBeginRef.current.x - drawEndRef.current.x),
				h: Math.abs(drawBeginRef.current.y - drawEndRef.current.y),
				frameNo: frameRef.current
			}
		}
		return detectObj
	}

	// set tool (received from toolbar)
	function setTool(tool) {
		if (tool === 'reuse') {
			showingReuseRef.current = true
			setShowReuse(true)
		} else {
			setSelectedTool(tool)
			toolSelected(tool) // parent
			if (tool === 'detect' || tool === 'draw') {
				isDrawingRef.current = true
				document.getElementById('canvas').style.cursor = 'crosshair'
			} else {
				isDrawingRef.current = false
				document.getElementById('canvas').style.cursor = 'default'
			}
		}
	}

	// cut frame image from chunk, paint it on canvas and draw detected object borders & labels
	function renderFrame(rframe) {
		const ctx = ctxRef.current
		const chunk = Math.floor(rframe/30)
		const col = rframe % 5
		const row = Math.floor(rframe % 30 / 5)
		ctx.drawImage(chunks[chunk], w*col, h*row, w, h, 0, 0, canvasWidthRef.current, canvasHeightRef.current)

		// draw tagged or untagged detected objects in current frame
		if (showTaggedRef.current || showUntaggedRef.current) {
			const frameObjects = videoRef.current.objects.filter(obj => obj.frameNo === rframe) // only objects in current frame
			const labelObjects = showLabelsRef.current || showCueTypesRef.current ? frameObjects : []
			labelArrayRef.current = []

			// untagged object borders
			const untaggedObjects = frameObjects.filter(o => videoRef.current.segments.findIndex(seg => seg.id === o.segment && seg.cueType === null) > -1)
			showUntaggedRef.current && untaggedObjects.map(obj => {
				if (obj.segment !== selectedSegmentRef.current) {
					ctx.beginPath()
					ctx.rect(Math.round(obj.x * canvasWidthRef.current) + .5 * scaleRef.current, Math.round(obj.y * canvasHeightRef.current) + .5 * scaleRef.current, Math.round(obj.w * canvasWidthRef.current) - 1 * scaleRef.current, Math.round(obj.h * canvasHeightRef.current) - 2 * scaleRef.current)
					ctx.lineWidth = 1 * scaleRef.current
					ctx.setLineDash([3 * scaleRef.current, 2 * scaleRef.current])
					ctx.lineDashOffset = .5 * scaleRef.current
					ctx.strokeStyle = '#fff'
					ctx.shadowColor = '#000'
					ctx.shadowBlur = 40
					ctx.stroke()
					ctx.shadowBlur = 0
				}
				return 0
			})
			// untagged labels
			ctxRef.current.font = (12 * scaleRef.current) + 'px Greycliff demibold'
			const untaggedLabelObjects = labelObjects.filter(o => videoRef.current.segments.findIndex(seg => seg.id === o.segment && seg.cueType === null) > -1)
			showUntaggedRef.current && showLabelsRef.current && untaggedLabelObjects.map(obj => {
				if (obj.segment !== selectedSegmentRef.current) {
					const segmentName = videoRef.current.segments.find(seg => seg.id === obj.segment).name.slice(0,labelMaxChars)
					const width = Math.ceil(ctx.measureText(segmentName).width + 14 * scaleRef.current)
					let backX = Math.round(obj.x * canvasWidthRef.current)
					let backY = Math.round(obj.y * canvasHeightRef.current) - 16 * scaleRef.current
					backX = backX + width > canvasWidthRef.current ? canvasWidthRef.current - width : backX
					backY = backY < 0 ? backY = 0 : backY
					ctx.fillStyle = 'rgba(255,255,255,.8)'
					ctx.fillRect(backX, backY, width, 17 * scaleRef.current)
					ctx.fillStyle = '#2D3544'
					ctx.fillText(segmentName, backX + 7 * scaleRef.current, backY + 12 * scaleRef.current)
					labelArrayRef.current.push({x:backX/scaleRef.current, y:backY/scaleRef.current, w:width/scaleRef.current, h:17, obj:obj})
				}
				return 0
			})
			// tagged object borders
			const taggedObjects = frameObjects.filter(o => videoRef.current.segments.findIndex(seg => seg.id === o.segment && seg.cueType !== null) > -1)
			showTaggedRef.current && taggedObjects.map(obj => {
				if (obj.segment !== selectedSegmentRef.current) {
					ctx.beginPath()
					ctx.rect(Math.round(obj.x * canvasWidthRef.current) + 1 * scaleRef.current, Math.round(obj.y * canvasHeightRef.current) + 1 * scaleRef.current, Math.round(obj.w * canvasWidthRef.current) - 2 * scaleRef.current, Math.round(obj.h * canvasHeightRef.current) - 3 * scaleRef.current)
					ctx.lineWidth = 2 * scaleRef.current
					ctx.setLineDash([3 * scaleRef.current, 2 * scaleRef.current])
					ctx.lineDashOffset = 1 * scaleRef.current
					ctx.strokeStyle = '#e5a01b'
					ctx.stroke()
				}
				return 0
			})
			// tagged labels
			const taggedLabelObjects = labelObjects.filter(o => videoRef.current.segments.findIndex(seg => seg.id === o.segment && seg.cueType !== null) > -1)
			showTaggedRef.current && (showLabelsRef.current || showCueTypesRef.current) && taggedLabelObjects.map(obj => {
				if (obj.segment !== selectedSegmentRef.current) {
					// title label
					const segmentName = videoRef.current.segments.find(seg => seg.id === obj.segment).name.slice(0,labelMaxChars)
					const cueId = videoRef.current.segments.find(seg => seg.id === obj.segment).cueType
					const cueType = cueTypes.find(cue => cue.type === cueId).label
					const labelText = (showLabelsRef.current ? segmentName : '') + (showLabelsRef.current && showCueTypesRef.current ? '  |  ' : '') + (showCueTypesRef.current ? cueType : '')
					let backX = Math.round(obj.x * canvasWidthRef.current)
					let backY = Math.round(obj.y * canvasHeightRef.current) - 15 * scaleRef.current
					let width = Math.ceil(ctx.measureText(labelText).width + 14 * scaleRef.current)
					backX = backX + width > canvasWidthRef.current ? canvasWidthRef.current - width : backX
					backY = backY < 0 ? backY = 0 : backY
					ctx.fillStyle = '#e5a01b'
					ctx.fillRect(backX, backY, width, 17 * scaleRef.current)
					ctx.fillStyle = '#fff'
					ctx.fillText(labelText, backX + 7 * scaleRef.current, backY + 12 * scaleRef.current)
					labelArrayRef.current.push({x:backX/scaleRef.current, y:backY/scaleRef.current, w:width/scaleRef.current, h:17, obj:obj})
				}
				return 0
			})
			// drawing custom object
			if (isDrawingRef.current &&  drawBeginRef.current && drawEndRef.current) {
				const drawObj = detectObject().object
				ctx.fillStyle = 'rgba(200,200,220,.6)'
				ctx.fillRect(drawObj.x * canvasWidthRef.current, drawObj.y * canvasHeightRef.current, drawObj.w * canvasWidthRef.current, drawObj.h * canvasHeightRef.current)
			}
		}

		// controls & time - showing minutes and hours as necessary
		const timeContainer = document.getElementById('vtime')
		const videoControl = document.getElementById('vcontrol')

		if (timeContainer && videoControl) {
			if (displayFrameNoRef.current) {
				timeContainer.innerHTML = 'frame ' + (frameRef.current + 1)
			} else {
				const secs = rframe/videoRef.current.frameRate
				const timestring = new Date(secs * 1000).toISOString()
				timeContainer.innerHTML = secs > 3600 ? timestring.substr(12, 10) : secs > 600 ? timestring.substr(14, 8) : timestring.substr(15, 7)
			}
			// position video player control knob and time container
			const left = (maxWidthRef.current-81)/(videoRef.current.frameCount-3) * frameRef.current
			videoControl.style.left = left + 'px'
			timeContainer.style.left = (left - 30) + 'px'
		}
	}

	// toggle object list visibility (large/small video)
	function toggleList() {
		setCueVideoSettings({...cueVideoSettings, showList:!cueVideoSettings.showList})
	}

	// jump to frame when clicked on timeline
	function jumpToFrame(e) {
		const clientX = e.clientX
		if (clientX - e.currentTarget.getBoundingClientRect().x - 15 > canvasWidthCss-15-51) return
		let gotoFrame = Math.round((clientX - e.currentTarget.getBoundingClientRect().x - 15)/(canvasWidthCss-15-15-51) * (video.frameCount-3))
		if (gotoFrame < 0) gotoFrame = 0
		if (gotoFrame > video.frameCount-3) gotoFrame =  video.frameCount-3
		frameRef.current = gotoFrame
		isPlayingRef.current === true && pauseVideo()
		renderFrame(frameRef.current)
	}

	// show/hide settings
	function toggleSettings(e, b=!settingsVisible) {
		setSettingsVisible(b)
	}

	// toggle time/frameNo display
	function toggleFrameNoDisplay() {
		displayFrameNoRef.current = !displayFrameNoRef.current
		renderFrame(frameRef.current)
	}

	// hide reuse dialogue
	function hideReuse() {
		showingReuseRef.current = false
		setShowReuse(null)
	}

	// cue marker below video
	const cueMarkers = (selectedObject.segment &&
		video.objects.filter(obj => obj.segment === selectedObject.segment).map(obj => {
			const frameWidth = (maxWidth-81)/(video.frameCount-3)
			const markerLeft = Math.ceil(frameWidth * obj.frameNo + 15 - frameWidth/2) + 'px'
			const markerWidth = Math.ceil(frameWidth) + 'px'
			const markerColor = video.segments.find(seg => seg.id === selectedObject.segment).cueType !== null ? '#e6a01c' : theme.cueMarkerColor
			const markerStyle = { ...cueMarkerStyle, width:markerWidth , marginLeft:markerLeft, backgroundColor:markerColor }
			return <div key={obj.id} style={markerStyle} />
		})
	)

	// loader placement (centered on of drawn area)
	const loaderTop = drawBeginRef.current && drawEndRef.current ? (Math.min(drawBeginRef.current.y, drawEndRef.current.y) + Math.abs(drawBeginRef.current.y - drawEndRef.current.y)/2) * canvasHeightCss - 10 : 0
	const loaderLeft = drawBeginRef.current && drawEndRef.current ? (Math.min(drawBeginRef.current.x, drawEndRef.current.x) + Math.abs(drawBeginRef.current.x - drawEndRef.current.x)/2) * canvasWidthCss - 25 + (maxWidth-canvasWidthCss)/2 : 0
	const loaderStyle = {
		position: 'absolute',
		top: loaderTop < 0 ? 0 : loaderTop + 20 > canvasHeightCss ? canvasHeightCss - 20 : loaderTop,
		left: loaderLeft < 0 + (maxWidth-canvasWidthCss)/2 ? 0 + (maxWidth-canvasWidthCss)/2 : loaderLeft + 50 > canvasWidthCss + (maxWidth-canvasWidthCss)/2 ? canvasWidthCss + (maxWidth-canvasWidthCss)/2 - 50 : loaderLeft,
		backgroundColor: '#2d3544',
		width: '50px',
		height: '20px',
		borderRadius: '9px',
		textAlign: 'center',
		paddingTop: '2px',
		boxSizing: 'border-box'
	}

	// detected object preloader
	const preLoader = (detectingObject &&
		<div style={loaderBackStyle}>
			<div style={loaderStyle}>
				<BeatLoader size={8} color={'#39C481'} />
			</div>
		</div>
	)

	// large/small video icon
	const videoSizeIcon = (maxWidth < 640 ?
		<img src={iconVideoLarge} style={{...gearStyle, marginRight:'8px'}} onMouseUp={toggleList} alt="Large video without object list" title="Large video without object list" /> :
		<img src={iconVideoSmall} style={{...gearStyle, marginRight:'8px'}} onMouseUp={toggleList} alt="Small video with object list" title="Small video with object list" />
	)

	// video settings
	const settings = (settingsVisible &&
		<AnalysisCueVideoSettings
			cueVideoSettings={cueVideoSettings}
			setCueVideoSettings={setCueVideoSettings}
			toggleSettings={toggleSettings}
		/>
	)

	const selectedObjsInFrame = video.objects.filter(obj => obj.segment === selectedObject.segment && obj.frameNo === frameRef.current)
	const selectedSegmentInFrame = video.segments.find(seg => seg.id === selectedObject.segment)
	const origSegmentInFrame = origSegments.find(orig => orig.id === selectedObject.segment)

	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />
	const alertReuse = showReuse === true && <AlertReuse video={video} reuseVideos={reuseVideos} match={video.perceptualHashMatchData} action={hideReuse} updateVideo={updateVideo} />

	// prevent body scroll if reuse dialogue is shown
	if (showReuse) {
		const scy = window.scrollY
		window.onscroll = () => window.scrollTo(0, scy)
	} else {
		window.onscroll = null
	}

 	// reset tween selection if another segment is selected
	if (selectedObject && selectedObject.segment !== undefined) {
		tweenObjectsRef.current = tweenObjectsRef.current.length > 0 && tweenObjectsRef.current[0].segment === selectedObject.segment ? tweenObjectsRef.current : []
	}

	const darkOverlayStyle = {
		width: canvasWidthCss,
		height: canvasHeightCss,
		backgroundColor: '#000',
		opacity: selectedObjsInFrame.length > 0 ? .15 : 0,
		margin: -canvasHeightCss + 'px auto 0',
		pointerEvents: 'none',
		transition: 'opacity .4s'
	}

	// edit object
	const editObject = (selectedObjsInFrame.length > 0 &&
		<AnalysisCueVideoObject
			selectedObject={selectedObject}
			objects={selectedObjsInFrame}
			segment={selectedSegmentInFrame}
			origSegment={origSegmentInFrame}
			width={canvasWidthCss}
			height={canvasHeightCss}
			forceUpdate={forceUpdate}
			tweenObjectsInSegment={tweenObjectsInSegment}
			deleteSegment={deleteSegment}
			objectsToUpdate={objectsToUpdate}
			optionsVisibleRef={optionsVisibleRef}
			tweenObjectsRef={tweenObjectsRef}
		/>
	)

	return (
		<div style={{position:'relative'}}>
			<AnalysisCueVideoToolbar
				toolSelect={setTool}
				canvasWidth={canvasWidthCss}
				maxWidth={maxWidth}
				reuse={reuseVideos && reuseVideos.length > 0}
			/>
			<canvas id="canvas" style={canvasStyle}
				ref={canvasRef}
				width={canvasWidth}
				height={canvasHeight}
				onMouseDown={onDown}
			/>
			<div style={darkOverlayStyle} />
			{editObject}
			{preLoader}
			<div style={cueMarkerContainerStyle}>
				{cueMarkers}
			</div>
			{settings}
			<div style={controlsStyle} onMouseDown={jumpToFrame}>
				<div style={lineStyle} />
				{videoSizeIcon}
				<img src={iconGear} style={gearStyle} onMouseUp={toggleSettings} alt="Video settings" title="Video settings" />
			</div>
			<div style={draggerStyle} onMouseDown={startDrag} id="vcontrol" />
			<div style={timeStyle} id="vtime" onClick={toggleFrameNoDisplay}></div>
			{alert}
			{alertReuse}
		</div>
	)
}
