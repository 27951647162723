import React, { useContext, useState, useEffect, useRef } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { APIContext } from '../services/api'
import { GlobalContext } from '../services/globalState'
import Gravatar from 'react-gravatar'
import UserSettingsMenu from './UserSettingsMenu'
import Alert from './Alert'
import { themes } from '../themes/themes'

// styles
const liStyle = {
	display: 'inline-block',
	fontSize: '18px',
	padding: '0',
	marginRight: '36px'
}

const linkActiveStyle = {
	opacity: '1',
	cursor: 'default'
}

// component function
export default function Menu(props) {

	const [globalState, setGlobalState] = useContext(GlobalContext)
	const context = useContext(APIContext)
	const history = useHistory()
	const [settingsVisible, setSettingsVisible] = useState(false)
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const profileFormChanged = useRef(false)
	const saveUserData = useRef()
	const theme = globalState.userData && globalState.userData.settings && globalState.userData.settings.theme ? themes[globalState.userData.settings.theme] : themes[0]

	// dynamic styles
	const linkStyle = {
		color: theme.textColor,
		opacity: '.5',
		textDecoration: 'none',
		cursor: 'pointer',
		outline: 'none',
		transition: 'opacity .2s'
	}

	const avatarStyle = {
		width: '46px',
		height: '46px',
		borderRadius: '50%',
	 	backgroundSize:'contain',
		backgroundRepeat:'no-repeat',
		backgroundPosition: '50% 50%',
	 	cursor: globalState.loggedIn && 'pointer'
	}

	const nameStyle = {
		width: '400px',
		height: '20px',
		margin: '0 -176px -17px -176px',
		color: theme.textColor,
		opacity: '0',
		textAlign: 'center',
		fontSize: '12px',
		fontFamily: 'Greycliff demibold',
		transition: 'opacity .2s',
		pointerEvents: 'none'
	}

	// init on mount
	useEffect(() => {
		//keyboard esc listener
		window.addEventListener("keydown", keyPressed)
		document.addEventListener('click', closeMenu)
		// cleanup on unmount
		return () => {
			window.removeEventListener("keydown", keyPressed)
			document.removeEventListener('click', closeMenu)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	// log out
	function logout() {
		context.io.socket.post('/api/v1/user/logout', (data, res) => {
			if (res.statusCode === 200) {
				setGlobalState({ loggedIn: false })
				goLogin()
			} else {
				// TODO: error handling
			}
		})
	}

	// go to log in
	function goLogin() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			history.push('/login')
		} else {
			goPublic()
		}
	}

	// go to public site
	function goPublic() {
		window.location.href = process.env.REACT_APP_PUBLIC_URL
	}

	// request demo
	function getDemo() {
		window.location.href = process.env.REACT_APP_PUBLIC_URL + '/contact'
	}

	// handle mouseEnter on menu link
	function handleEnter(e) {
		e.currentTarget.style.opacity = parseFloat(e.currentTarget.style.opacity) !== 1 ? .75 : 1
	}

	// handle mouseLeave on menu link
	function handleLeave(e) {
		e.currentTarget.style.opacity = parseFloat(e.currentTarget.style.opacity) !== 1 ? .5 : 1
	}

	// show email on avatar mouseEnter
	function showName() {
		document.getElementById('username').style.opacity = .4
	}

	// hidd email on avatar mouseLeave
	function hideName() {
		document.getElementById('username').style.opacity = 0
	}

	// close settings menu on on escape
	function keyPressed(e) {
		if (e.keyCode === 27) { // escape
			document.activeElement.blur() // remove input field focus
			checkAndCloseMenu()
		}
	}

	// toggle menu on avatar click
	function toggleMenu() {
		settingsVisible ? checkAndCloseMenu() : setSettingsVisible(true)
	}

	// close settings menu if clicked outside
	function closeMenu(e) {
		const menuContainer = document.getElementById('settingsMenu')
		const avatar = document.getElementById('avatar')
		if (avatar && !avatar.contains(e.target) && menuContainer && !menuContainer.contains(e.target)) {
			checkAndCloseMenu()
		}
	}

	// cancel changes to profile on menu close?
	function checkAndCloseMenu() {
		if (profileFormChanged.current) {
			alertObjectRef.current = { type:'confirm', title:'Save profile changes?' }
			setAlertTask(()=>(action)=>{ // define alert action and display alert
				if (action) {
					saveUserData.current(null, true) // save and close menu after succesful submit
				} else {
					profileFormChanged.current = false
					setSettingsVisible(false)
				}
				setAlertTask() // remove alert
			})
		} else {
			profileFormChanged.current = false
			setSettingsVisible(false)
		}
	}

	const dataRoot = globalState.userData ? globalState.userData : null

	const profileImage = (dataRoot && dataRoot.organization && dataRoot.organization.settings && dataRoot.organization.settings.avatar ? // do we have an organization avatar image?
		globalState.loggedIn ?
			<div style={{...avatarStyle, backgroundImage:'url('+dataRoot.organization.settings.avatar+')'}} onMouseEnter={showName} onMouseLeave={hideName} /> :
			<div style={{...avatarStyle, backgroundImage:'url('+dataRoot.organization.settings.avatar+')'}} /> :
		dataRoot && dataRoot.email && <Gravatar style={{...avatarStyle, marginBottom:'-7px'}} email={dataRoot.email} default="mp" onMouseEnter={showName} onMouseLeave={hideName} />
	)

	const settingsMenu = settingsVisible && <div id='settingsMenu'><UserSettingsMenu profileFormChanged={profileFormChanged} saveUserData={saveUserData} setSettingsVisible={setSettingsVisible} /></div>

	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	return (
		// not logged in
		globalState.loggedIn === undefined ?
			null
		:
		globalState.loggedIn === false ?
		<>
			<ul>
				<li style={liStyle}><p style={linkStyle} tabIndex="-1" onClick={goPublic} onMouseEnter={handleEnter} onMouseLeave={handleLeave}>About flowsam</p></li>
				<li style={liStyle}><p style={linkStyle} tabIndex="-1" onClick={getDemo} onMouseEnter={handleEnter} onMouseLeave={handleLeave}>Get a demo</p></li>
				<li style={liStyle}><p style={linkStyle} tabIndex="-1" onClick={goLogin} onMouseEnter={handleEnter} onMouseLeave={handleLeave}>Log in</p></li>
			</ul>
			{profileImage}
		</>
		:
		// logged in - show content
		<>
			<ul>
				<li style={liStyle}><NavLink exact={true} style={linkStyle} activeStyle={linkActiveStyle} tabIndex="-1" to="/" onMouseEnter={handleEnter} onMouseLeave={handleLeave}>Analyses</NavLink></li>
				<li style={liStyle}><NavLink exact={true} style={linkStyle} activeStyle={linkActiveStyle} tabIndex="-1" to="/userguide" onMouseEnter={handleEnter} onMouseLeave={handleLeave}>User guide</NavLink></li>
				<li style={liStyle}><p style={linkStyle} tabIndex="-1" onClick={logout} onMouseEnter={handleEnter} onMouseLeave={handleLeave}>Log out</p></li>
			</ul>
			<div id="avatar" onClick={toggleMenu}>
				{profileImage}
				<div id="username" style={nameStyle}>{dataRoot && dataRoot.name}</div>
			</div>
			{settingsMenu}
			{alert}
		</>
	)
}
